import { getItem, StorageItem, UpdateTypes } from '../..';
import { IfeatureItem, IOpsfeatureItem } from '../interface/IfeatureItem';
import { Topic } from './topic';
import { Update } from './update';

export class Comment implements IOpsfeatureItem {
  id: number;
  created_by_id: number;
  title: string;
  body: string;
  created_at: string;
  committee_id = 0;
  committee_title: string;
  image: string | ArrayBuffer;
  type: string;
  topic: Topic;
  is_manager: boolean;
  parent_comment_id: number;
  municipality_id: number;
  user_fullname: string;
  sub_comments = [];
  score: number;
  user_vote: number;
  updates: Update[];
  followers: number[];
  status: UpdateTypes;
  // this doesn't come with the request ... it's added on the front to merge two arrays
  UpdatesAndComments: any[];
}

export class CommentReq {
  id: number;
  created_by: number;
  title: string;
  body: string = '';
  created_at: string;
  committee_id: number;
  image: string | ArrayBuffer;
  type: string;
  topic: number;
  parent_comment_id: number;
  municipality_id: number;
}
