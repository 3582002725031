import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-infos-section',
  templateUrl: './infos-section.component.html',
  styleUrls: ['./infos-section.component.css', '../../general-home.component.css'],
})
export class InfosSectionComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
