import { Pipe, PipeTransform } from '@angular/core';
import { Service } from '../models/class/service';
import { Reservation } from '../models/class/agency';

@Pipe({
  name: 'availableServices',
})
export class AvailableServicesPipe implements PipeTransform {
  transform(services: Service[], reservations: Reservation[]): Service[] {
    // Filter services that that the user has made reservations for
    let uniqueServices = [];
    let serviceNames = [];
    const reservedServiceIds = reservations.map(reservation => reservation.service);
    for (let service of services) {
      if (!serviceNames.includes(service.name)) {
        uniqueServices.push(service);
        serviceNames.push(service.name);
      }
    }
    return uniqueServices.filter(service => {
      return !reservedServiceIds.includes(service.id);
    });
  }
}
