import { ErrorHandler, Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class ToastLuncherService implements ErrorHandler {
  userAgent = navigator.userAgent;
  constructor(private deviceService: DeviceDetectorService) {}
  success(info: ToastInfo) {
    return Swal.mixin({
      toast: true,
      position: 'bottom-left',
      showConfirmButton: info.showConfirmButton,
      timer: info.timer || 4000,
      customClass: { popup: 'popup', content: 'popup' },
    }).fire({
      background: '#e8ffe8',
      padding: '0.5em',
      html: `<div class="html-cont" style="cursor:default;2px solid #dcf7d1">
        <div class="toast-icon toast-success"><i class="fas fa-exclamation-circle  fa-lg icon"  ></i></div>
        <h5 class="toast-text">
        ${info.text}
        </h5>
       <div >
       <i class="far fa-times-circle fa-lg" style="cursor:pointer; color: #72a545;"></i>
       </div>`,
    });
  }

  alert(info: ToastInfo) {
    return Swal.mixin({
      toast: true,
      position: 'bottom-left',
      showConfirmButton: info.showConfirmButton,
      timer: info.timer || 4000,
      customClass: { popup: 'popup', content: 'popup' },
    }).fire({
      background: '#ffe8e8',
      padding: '0.5em',
      html: `<div class="html-cont" style="cursor:default;2px solid #f7d1d1">
        <div class="toast-icon toast-danger"><i class="fas fa-exclamation-circle  fa-lg icon"  ></i></div>
        <h5 class="toast-text">
        ${info.text}
        </h5>
       <div >
       <i class="far fa-times-circle fa-lg" style="cursor:pointer; color: red;"></i>
        </div>`,
    });
  }

  info(info: ToastInfo) {
    return Swal.mixin({
      toast: true,
      position: 'bottom-start',
      showConfirmButton: info.showConfirmButton,
      timer: info.timer || 4000,
      customClass: { popup: 'popup', content: 'popup' },
    }).fire({
      background: '#eff8fe',
      padding: '0.5em',
      html: `<div class="html-cont" style="cursor:default;2px solid #D1EBF7">
        <div class="toast-icon toast-info" style="background:#9dddf3"><i class="fas fa-exclamation-circle  fa-lg icon"  ></i></div>
        <h5 class="toast-text">
        ${info.text}
        </h5>
       <div >
       <i class="far fa-times-circle fa-lg" style="cursor:pointer; color: deepskyblue;"></i>
        </div>`,
    });
  }
  /**
   * shows mobile app link if user is on mobile or tablet
   */
  showMobileApp() {
    // const deviceInfo = this.deviceService.getDeviceInfo();
    const isAndroid = /Android/i.test(this.userAgent);
    const isHuawei = /Huawei/i.test(this.userAgent);
    const isIos = /iPhone|iPad|iPod/i.test(this.userAgent);
    // const isDesktopDevice = this.deviceService.isDesktop();
    if (isAndroid) {
      Swal.fire({
        title: 'مرحبا بك!',
        text: ' يمكنك استخدام التطبيقة الخاصة ب elBaladiya.tn على play store لتجربة افضل',
        imageUrl: '../../assets/images/play-store.png',
        imageWidth: 70,
        imageHeight: 70,
        width: '100%',
        position: 'bottom',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'تحميل التطبيقة',
        cancelButtonText: 'اغلاق',
      }).then(result => {
        if (result.value) {
          window.open('https://play.google.com/store/apps/details?id=com.touneslina.smartupgremda', '_blank');
          Swal.close();
        }
      });
    }
    if (isHuawei) {
      Swal.fire({
        title: 'مرحبا بك!',
        text: ' يمكنك استخدام التطبيقة الخاصة ب elBaladiya.tn على HUAWEI AppGallery لتجربة افضل',
        imageUrl: '../../assets/images/Huawei_AppGallery.svg',
        imageWidth: 70,
        imageHeight: 70,
        width: '100%',
        position: 'bottom',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'تحميل التطبيقة',
        cancelButtonText: 'اغلاق',
      }).then(result => {
        if (result.value) {
          window.open('https://appgallery.huawei.com/app/C104056653', '_blank');
          Swal.close();
        }
      });
    }
    if (isIos) {
      Swal.fire({
        title: 'مرحبا بك!',
        text: ' يمكنك استخدام التطبيقة الخاصة ب elBaladiya.tn على App Store لتجربة افضل',
        imageUrl: '../../assets/images/Appstore.png',
        imageWidth: 70,
        imageHeight: 70,
        width: '100%',
        position: 'bottom',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'تحميل التطبيقة',
        cancelButtonText: 'اغلاق',
      }).then(result => {
        if (result.value) {
          window.open('https://apps.apple.com/app/id1586162812', '_blank');
          Swal.close();
        }
      });
    }
  }
  custom(overide: SweetAlertOptions) {
    Swal.fire({
      ...overide,
      width: '100%',
      position: 'bottom',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'حسنا',
      cancelButtonText: 'اغلاق',
    });
  }

  handleError(error) {
    // all application errors will be catched here

    console.error(error);
    if (error.message?.includes('ا') || !environment.production)
      // a lazy way to check if it is arabic xDD
      this.alert({
        showConfirmButton: false,
        timer: 8000,
        text: error.message,
      });
  }
}

class ToastInfo {
  showConfirmButton?: boolean = false;
  timer?: number;
  text: string;
}
