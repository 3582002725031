import { Component, OnInit, Input } from '@angular/core';
import { AgencyInfo } from 'src/app/core/models/interface/ticket';

@Component({
  selector: 'app-agency-state-bar',
  templateUrl: './agency-state-bar.component.html',
  styleUrls: ['./agency-state-bar.component.css'],
})
export class AgencyStateBarComponent implements OnInit {
  constructor() {}

  @Input() agency: AgencyInfo;
  @Input() isOpen: boolean;
  time: string;
  days: string;
  ngOnInit(): void {
    const format = str => (str + ' ').replace(new RegExp(':00 ', 'g'), ' ');

    if (this.agency.saturday_first_start && this.agency.saturday_second_end) {
      this.time =
        'من' +
        this.agency.saturday_first_start +
        ' الى ' +
        this.agency.saturday_first_end +
        ' و  من ' +
        this.agency.saturday_second_start +
        ' الى ' +
        this.agency.saturday_second_end;
      this.days = 'يوم السبت :';
    } else {
      this.time =
        'من' +
        this.agency.weekday_first_start +
        ' الى ' +
        this.agency.weekday_first_end +
        ' و  من ' +
        this.agency.weekday_second_start +
        ' الى ' +
        this.agency.weekday_second_end;
      this.days = 'من الاثنين إلى الجمعة :';
    }
    this.time = format(this.time);
  }
}
