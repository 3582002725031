import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arabicDate',
})
export class ArabicDatePipe implements PipeTransform {
  transform(date: string): unknown {
    return new Date(Date.parse(date)).toLocaleString('ar-TN', {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }
}
