import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { MunisFacade, UiFacade, UserFacade } from 'src/app/core/store';
import { ComplaintsService } from '../../../services/complaints.service';
import Backend from 'src/app/core/backend';
import { defaultAnimation } from '../../../core';
import { Complaint } from 'src/app/core/models/class/complaint';
import { UpdateTypes } from 'src/app/core/models/types';
import { GenericSingleViewFeature } from 'src/app/core/models/class/GenericSingleViewFeature';

@Component({
  selector: 'app-plaintes-details',
  templateUrl: './plaintes-details.component.html',
  styleUrls: ['./plaintes-details.component.css'],
  ...defaultAnimation,
})
export class PlaintesDetailsComponent extends GenericSingleViewFeature<Complaint> implements OnInit {
  baseUrl = Backend.baseUrl;
  newComplaint = false;
  selectedLong: number = null;
  selectedLat: number = null;
  frontUrl = Backend.frontUrl;

  constructor(
    public _complaintsService: ComplaintsService,
    public _munisFacade: MunisFacade,
    public _uiFacade: UiFacade,
    public router: Router,
    public _userFacade: UserFacade,
    public route: ActivatedRoute,
    public _toastLuncher: ToastLuncherService,
  ) {
    super('id', _complaintsService, route, _munisFacade, _userFacade, _toastLuncher, router);
  }

  ngOnInit(): void {}
  normalizeAfterGetItem(item: Complaint) {
    item.status = item.updates.length ? item.updates[0].status : UpdateTypes.RECEIVED;
    if (item.longitude && item.latitude) {
      this.selectedLat = Math.max(item.latitude, item.longitude);
      this.selectedLong = Math.min(item.latitude, item.longitude);
    } else {
      this.selectedLat = null;
      this.selectedLong = null;
    }
    this._uiFacade.setFeatureLoaded();
    return item;
  }

  getIconName(category: string) {
    //get the proper icon depending on category name
    const categoryIcons = [
      ['النظافة ومقاومة الحشرات', 'fas fa-bug'],
      ['الصحّة والبيئة', 'fas fa-shield-virus'],
      ['التراتيب العمرانية', 'fas fa-house-damage'],
      ['المناطق الخضراء والبستنة', 'fas fa-leaf'],
      ['المخالفات الاقتصادية', 'fas fa-money-check-alt'],
      ['الطرقات', 'fas fa-road'],
      ['الإنارة العمومية', 'fas fa-lightbulb'],
    ];
    let i = 0;
    let found = false;

    while (!found && i < categoryIcons.length) {
      if (categoryIcons[i][0] === category) found = true;
      else i++;
    }

    if (found) return categoryIcons[i][1];
    return 'fas fa-exclamation-circle';
  }
}
