import { Pipe, PipeTransform } from '@angular/core';

import { UpdateTypes } from '../models/types';

@Pipe({
  name: 'complaintStatus',
})
export class ComplaintStatusPipe implements PipeTransform {
  transform(status: string): string {
    switch (status) {
      case UpdateTypes.NOT_CLEAR:
        return 'المشكل غير واضح';
      case UpdateTypes.INVALID:
        return 'المشكل خارج نطاق عمل البلدية';
      case UpdateTypes.PROCESSING:
        return 'المشكل بصدد المعالجة';
      case UpdateTypes.REJECTED:
        return 'تعذر على البلدية معالجة المشكل';
      case UpdateTypes.ACCEPTED:
        return 'تم حل المشكل';
      // defaults to RECEIVED
      default:
        return 'تم استقبال المشكل';
    }
  }
}
