import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { StaticTextsService } from '../../../services/static-texts.service';
import { defaultAnimation } from '../../../core';
import { StaticText } from 'src/app/core/models/class/static-text';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-registeration-conditions',
  templateUrl: './registration-conditions.component.html',
  styleUrls: ['./registration-conditions.component.css'],
  ...defaultAnimation,
})
export class RegisterConditionsComponent implements OnInit {
  $conditions: Observable<StaticText> = this.staticTextsService.get('Registration conditions');

  constructor(public route: Router, public staticTextsService: StaticTextsService) {}

  ngOnInit() {}
}
