import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { MunisFacade, UiFacade, UserFacade } from 'src/app/core/store';
import { SubjectAccessRequest } from '../../../core/models/class/subject-access-request';
import { SubjectAccessRequestService } from '../../../services/subject-access-request.service';
import { defaultAnimation, timeAgo } from '../../../core';
import Backend from 'src/app/core/backend';
import { UpdateTypes } from 'src/app/core/models/types';
import { GenericSingleViewFeature } from 'src/app/core/models/class/GenericSingleViewFeature';

@Component({
  selector: 'app-accessinfo-details',
  templateUrl: './accessinfo-details.component.html',
  styleUrls: ['./accessinfo-details.component.css'],
  ...defaultAnimation,
})
class AccessinfoDetailsComponent extends GenericSingleViewFeature<SubjectAccessRequest> implements OnInit {
  addNew = false;
  baseUrl = Backend.baseUrl;
  frontUrl = Backend.frontUrl;

  constructor(
    public _SAR: SubjectAccessRequestService,
    public _munisFacade: MunisFacade,
    public _toastLuncher: ToastLuncherService,
    public router: Router,
    public _userFacade: UserFacade,
    public _uiFacade: UiFacade,
    public route: ActivatedRoute,
  ) {
    super('id', _SAR, route, _munisFacade, _userFacade, _toastLuncher, router);
  }

  ngOnInit(): void {}

  normalizeAfterGetItem(item: SubjectAccessRequest) {
    item.status = item.updates.length ? item.updates[0].status : UpdateTypes.RECEIVED;
    this._uiFacade.setFeatureLoaded();
    return item;
  }
}

export default AccessinfoDetailsComponent;
