import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PollsService } from 'src/app/services/polls.service';
import { MunisFacade, UiFacade, UserFacade } from 'src/app/core/store';
import { defaultAnimation } from 'src/app/core';
import { Poll } from 'src/app/core/models/class/polls';
import { GenericSingleViewFeature } from 'src/app/core/models/class/GenericSingleViewFeature';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
@Component({
  selector: 'app-poll-details',
  templateUrl: './poll-details.component.html',
  styleUrls: ['./poll-details.component.css'],
  ...defaultAnimation,
})
export class PollItemComponent extends GenericSingleViewFeature<Poll> implements OnInit {
  constructor(
    private _pollsService: PollsService,
    public route: ActivatedRoute,
    public _munisFacade: MunisFacade,
    public _toastLuncher: ToastLuncherService,
    public _userFacade: UserFacade,
    public router: Router,
    public _uiFacade: UiFacade,
  ) {
    super('id', _pollsService, route, _munisFacade, _userFacade, _toastLuncher, router);
  }

  ngOnInit(): void {}

  normalizeAfterGetItem(item: Poll) {
    this._uiFacade.setFeatureLoaded();
    return item;
  }
}
