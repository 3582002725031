import { Component, OnInit } from '@angular/core';
import { StaticTextsService } from '../../../../services/static-texts.service';

@Component({
  selector: 'app-services-about',
  templateUrl: './services-about.component.html',
  styleUrls: ['./services-about.component.css', '../../general-home.component.css'],
})
export class ServicesAboutComponent implements OnInit {
  constructor(private staticText: StaticTextsService) {}

  videosUrl;
  bookUrl;

  ngOnInit() {
    this.getStaticText();
  }

  getStaticText() {
    this.staticText.get('Book URL').subscribe(data => {
      this.bookUrl = data.body;
    });
    this.staticText.get('Videos URL').subscribe(data => {
      this.videosUrl = data.body;
    });
  }

  toBookUrl() {
    window.open(this.bookUrl, '_blank');
  }
  toVideosUrl() {
    window.open(this.videosUrl, '_blank');
  }
}
