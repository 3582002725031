import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Building, Dossier } from '../core/models/class/dossier';
import { GenericCRUDService } from '../core/services/generic.service';

@Injectable({
  providedIn: 'root',
})
export class BuildingService extends GenericCRUDService<Building> {
  constructor(http: HttpClient) {
    super(http);
    this.prefix = '/buildings';
  }
}
