import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Report } from 'src/app/core/models/class/report';
import Backend from 'src/app/core/backend';
import { openLink } from '../../../core';

@Component({
  selector: 'app-report-detail-home',
  templateUrl: './report-detail-home.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./report-detail-home.component.css'],
})
export class ReportDetailHomeComponent implements OnInit {
  @Input()
  report = new Report();
  baseUrl = Backend.baseUrl;

  constructor() {}

  ngOnInit() {}

  openURL = link => openLink(link);
}
