import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as AOS from 'aos';
import 'jquery';

@Component({
  selector: 'app-general-home',
  templateUrl: './general-home.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./general-home.component.css'],
})
export class GeneralHomeComponent implements OnInit, AfterViewInit {
  loadAPI: Promise<any>;
  currRoute;

  constructor(public route: Router) {
    this.loadAPI = new Promise(resolve => {
      this.loadScript();
      resolve(true);
    });
  }

  public loadScript() {
    let isFound = false;
    const scripts = document.getElementsByTagName('script');
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes('loader')) {
        isFound = true;
      }
    }

    if (!isFound) {
      const dynamicScripts = [
        'assets/js/home/magnific-popup.min.js',
        'assets/js/home/imagesloaded.pkgd.min.js',
        'assets/js/home/isotope.pkgd.min.js',
        'assets/js/home/owl.carousel.min.js',
        'assets/js/home/aos.js',
        'assets/js/home/parallax.min.js',
        'assets/js/home/custom.js',
      ];

      for (var i = 0; i < dynamicScripts.length; i++) {
        const node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('body')[0].appendChild(node);
      }
    }
  }

  ngOnInit() {
    AOS.init({
      // disable: function() {
      //   var maxWidth = 769;
      //   return window.innerWidth < maxWidth;
      // }
      once: true,
      duration: 800,
    });
    this.currRoute = this.route.url;
  }

  ngAfterViewInit(): void {}
}
