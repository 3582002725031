export const firebaseConfig = {
  vapidKey: 'BBwV1H8t5Ua7khOfOxQtZ72cSJuBgixrK9R6bc2ESjeL1YedVG7vqt8VDWkRG2JQPIvq0SQfriDIA_cZy6QyIvo',
  apiKey: 'AIzaSyDF3UHP7tr7qqYZMkdBJsryt4E6DqB9EfM',
  authDomain: 'smartup-gremda.firebaseapp.com',
  databaseURL: 'https://smartup-gremda.firebaseio.com',
  projectId: 'smartup-gremda',
  storageBucket: 'smartup-gremda.appspot.com',
  messagingSenderId: '814528371795',
  appId: '1:814528371795:web:02f4f7588191263525faa6',
  measurementId: 'G-NWQGM80DQH',
};
