import { Pipe, PipeTransform } from '@angular/core';

import { UpdateTypes } from '../models/types';

@Pipe({
  name: 'statusIcon',
})
export class StatusIconPipe implements PipeTransform {
  transform(status: UpdateTypes): string {
    // @ts-ignore
    if (Object.values(UpdateTypes).includes(status)) return `../../assets/images/ico/svg/${status}.svg`;
    return `../../assets/images/ico/svg/${UpdateTypes.RECEIVED}.svg`;
  }
}
