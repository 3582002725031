import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preinscription',
  templateUrl: './preinscription.component.html',
  styleUrls: ['./preinscription.component.css'],
})
export class PreinscriptionComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
