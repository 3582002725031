import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import { UserFacade } from '../store';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(public router: Router, private _userFacade: UserFacade, public jwtHelper: JwtHelperService) {}

  canActivate(): boolean {
    if (this._userFacade.isAuthenticated() && !this.jwtHelper.isTokenExpired(UserFacade.getToken())) return true;
    this.router.navigate(['/login']);
    return false;
  }
}
