import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NxModule } from '@nrwl/nx';

import { MunisEffects } from './municipality/municpality.effects';
import { UserEffects } from './user/user.effects';

import { reducers } from '.';
import { environment } from '../../../../environments/environment';
@NgModule({
  imports: [
    CommonModule,
    NxModule.forRoot(),
    StoreModule.forRoot(reducers),
    environment.production ? [] : StoreDevtoolsModule.instrument({ maxAge: 25 }),
    EffectsModule.forRoot([MunisEffects, UserEffects]),
  ],
  declarations: [],
})
export class StateModule {}
