import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticText } from '../core/models/class/static-text';

import { BaseService } from '../core/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class StaticTextsService extends BaseService {
  constructor(private http: HttpClient) {
    super();
    this.prefix = '/static-texts';
  }

  getAll(): Observable<Array<StaticText>> {
    return this.http.get(BaseService.url + this.prefix, { headers: BaseService.getHeaders() }) as Observable<
      StaticText[]
    >;
  }

  get(key: string): Observable<StaticText> {
    return this.http.get(BaseService.url + this.prefix + '/' + key, {
      headers: BaseService.getHeaders(),
    }) as Observable<StaticText>;
  }
}
