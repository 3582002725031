import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { SubjectAccessRequest } from '../../../core/models/class/subject-access-request';
import { openLink } from '../../../core';
import Backend from 'src/app/core/backend';
import { Municipality } from 'src/app/core/models/class/municipality';

declare let $: any;

@Component({
  selector: 'app-access-detail-home',
  templateUrl: './access-detail-home.component.html',
  styleUrls: ['./access-detail-home.component.css'],
})
export class AccessDetailHomeComponent implements OnInit {
  @Input()
  access = new SubjectAccessRequest();
  @Input() municipality: Municipality;
  baseUrl = Backend.baseUrl;

  constructor() {}

  ngOnInit() {}

  openURL = link => openLink(link);

  hideModal() {
    $('#accessModal').modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }
}
