import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map, tap } from 'rxjs/operators';
import { monthsSince, setItem, StorageItem } from '../../../index';
import { LoginResponse, User } from '../../../models/class/user';
import { MunisFacade } from '../municipality/municipality.facade';
import * as $ from 'jquery';

import {
  LoginStart,
  UserActionTypes,
  LoginSuccess,
  LoginFailure,
  LoadUser,
  UserLoaded,
  RefreshTokenStart,
  RefreshTokenDone,
} from './user.action';
import { UserState } from './user.reducer';
import { UserService } from '../../../services/user.service';
import { ToastLuncherService } from '../../../services/toast-luncher.service';
import { IUserEffect } from '../../../models/interface/user.effect.interface';
import { UserFacade } from './user.facade';

@Injectable({ providedIn: 'root' })
export class UserEffects implements IUserEffect {
  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<UserState>,
    private _userService: UserService,
    public _munisFacade: MunisFacade,
    public _toastLuncher: ToastLuncherService,
    private _userFacade: UserFacade,
  ) {}

  @Effect()
  login$ = this.dataPersistence.fetch(UserActionTypes.LoginStart, {
    run: (action: LoginStart, state: UserState) => {
      return this._userService.loginOnlyPhone(action.payload).pipe(
        map((res: LoginResponse) => {
          setItem(StorageItem.token, res.access);
          setItem(StorageItem.refresh_token, res.refresh);
          setItem(StorageItem.connected_user_id, new JwtHelperService().decodeToken(res.access).user_id);
          setItem(StorageItem.first_login, `${res.first_login ? 'true' : 'false'}`);
          setItem(StorageItem.latest_residence_update_date, `${monthsSince(res.latest_residence_update_date)}`);
          this._munisFacade.setMuniFromId(res.preferred_municipality_id);
          return new LoginSuccess(res);
        }),
        tap(action => {
          this._userFacade.loadUser();
          $('#loginModal').trigger('click');
        }),
      );
    },

    onError: (action: LoginStart, error) => {
      this._userFacade.loginFailure();
      throw new Error('لم نتمكن من تسجيل الدخول بنجاح');
    },
  });

  @Effect()
  loadUser$ = this.dataPersistence.fetch(UserActionTypes.LoadUser, {
    run: (action: LoadUser, state: UserState) => {
      return this._userService.getUser().pipe(
        map((res: User) => {
          return new UserLoaded(res);
        }),
      );
    },
    onError: (action: LoadUser, error) => {
      this._userFacade.logout();
      throw new Error('لم يتم العثور على مستخدم');
    },
  });

  @Effect()
  refreshToken$ = this.dataPersistence.fetch(UserActionTypes.RefreshTokenStart, {
    run: (action: RefreshTokenStart, state: UserState) => {
      return this._userService.refreshToken().pipe(
        map((res: { access: string }) => {
          setItem(StorageItem.token, res.access);
          return new RefreshTokenDone(res.access);
        }),
      );
    },

    onError: (action: RefreshTokenStart, error) => {
      this._userFacade.logout();
      throw new Error('لا يمكن استخدام البيانات القديمة الرجاء تسجيل الدخول إلى حسابك');
    },
  });
}
