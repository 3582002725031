import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { formatMunicipalityName, openLink } from '../../lib/functions';
import { GenericCRUDService } from '../../services/generic.service';
import { MunisFacade, UserFacade } from '../../store';
import { IfeatureItem } from '../interface/IfeatureItem';
import { GenericSingleView } from './GenericSingleView';
import { Municipality } from './municipality';
import { NgxImageCompressService } from 'ngx-image-compress';

declare let $: any;
export class GenericSingleViewFeature<T extends IfeatureItem> extends GenericSingleView<T> {
  selectedMuni$: Observable<Municipality> = this._munisFacade.selectedMuni$;
  isAuthenticated: boolean = this._userFacade.isAuthenticated();
  municipalityRoute: string = '';

  constructor(
    public item_param_name: string,
    public _service: GenericCRUDService<T>,
    public activeRoute: ActivatedRoute,
    public _munisFacade: MunisFacade,
    public _userFacade: UserFacade,
    public _toastLuncher: ToastLuncherService,
    public router: Router,
  ) {
    super(item_param_name, _service, _userFacade, _toastLuncher, activeRoute);
    this.selectedMuni$.subscribe(m => {
      if (m) {
        this.municipalityRoute = m.route_name;
        this.getItem();
      }
    });
  }

  redirectTo(...args) {
    this.router.navigate([args]);
  }
}
