import { Component, OnInit } from '@angular/core';

import { StaticTextsService } from '../../../services/static-texts.service';

@Component({
  selector: 'app-services-section',
  templateUrl: './services-section.component.html',
  styleUrls: ['./services-section.component.css', '../general-home.component.css'],
})
export class ServicesSectionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
