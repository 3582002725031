import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Dossier, DossiersAttachment } from '../core/models/class/dossier';
import { GenericCRUDService } from '../core/services/generic.service';

@Injectable({
  providedIn: 'root',
})
export class DossiersAttachmentService extends GenericCRUDService<DossiersAttachment> {
  constructor(http: HttpClient) {
    super(http);
    this.prefix = '/dossiers-attachments';
  }
}
