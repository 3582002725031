import { Injectable } from '@angular/core';
import * as introJs from 'intro.js/intro.js';

@Injectable({
  providedIn: 'root',
})
export class IntroLuncherService {
  introJS;
  options = {
    showProgress: true,
    nextLabel: 'بعد',
    prevLabel: 'قبل',
    doneLabel: 'اتمام',
  };
  constructor() {
    this.introJS = introJs().setOptions(this.options);
  }
  /**
   *  this method should be used as a generic example
   *
   *  tour steps are written in the html file
   *
   *  example : https://introjs.com/docs/examples/basic/hello-world
   */
  startFeatureIntro() {
    this.introJS.start();
  }
}
