import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getItem, StorageItem } from 'src/app/core';
import { Ticket } from 'src/app/core/models/interface/ticket';
import { MunisFacade } from 'src/app/core/store';
import { TicketService } from 'src/app/services/ticket.service';
declare let $: any;

@Component({
  selector: 'app-user-ticket',
  templateUrl: './user-ticket.component.html',
  styleUrls: ['./user-ticket.component.css'],
})
export class UserTicketComponent implements OnInit {
  municipalityId = MunisFacade.getMunicipalityId();
  loadingTicketURL = false;
  @Input() ticket: Ticket;

  constructor(private route: ActivatedRoute, private ticketService: TicketService) {}

  ngOnInit(): void {}

  hideModal() {
    $('#ticketModal').modal('hide');
  }

  downloadTicket() {
    this.loadingTicketURL = true;
    this.ticketService.downloadPDF(getItem(StorageItem.agency_id), this.ticket.id_service).subscribe(
      (data: Blob) => {
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        // open pdf in new page and ask to download
        window.open(fileURL);
        this.loadingTicketURL = false;
      },
      error => {
        throw new Error('لم نتمكن من إنشاء التذكرة');
      },
    );
  }
}
