import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { defaultAnimation, getDeviceData, isValidPhoneNumber } from '../../core';
import { UserFacade } from '../../core/store';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { BasePushNotifications } from 'src/app/core/models/class/BasePushNotifications';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  ...defaultAnimation,
})
export class LoginComponent extends BasePushNotifications implements OnInit {
  fieldTextType = false;
  isLoading$ = this._userFacade.isLoading$;
  phone_number: number;
  password: string;

  constructor(public route: Router, public _userFacade: UserFacade, protected _toastLuncher: ToastLuncherService) {
    super(_toastLuncher);
  }

  ngOnInit() {
    this.requestPermission();
  }

  login(phone_number, password) {
    if (!phone_number || !password || !isValidPhoneNumber(phone_number))
      throw new Error('الرجاء ملء كل الفراغات والتثبت من كلمة السر ورقم الهاتف');

    const fcm_token = this.fcm_token;
    this._userFacade.startLogin({
      phone_number: this.phone_number,
      password: this.password,
      fcm_token,
      ...getDeviceData(),
    });
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}
