import { Component, OnInit } from '@angular/core';
import { defaultAnimation } from 'src/app/core';
import { BasePushNotifications } from 'src/app/core/models/class/BasePushNotifications';
import { UiFacade } from 'src/app/core/store';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
  ...defaultAnimation,
})
export class MainComponent extends BasePushNotifications implements OnInit {
  constructor(public _uiFacade: UiFacade, protected _toastLuncher: ToastLuncherService) {
    super(_toastLuncher);
  }
  ngOnInit() {
    this.listen();
  }
}
