import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';

import Backend from 'src/app/core/backend';
declare let $: any;

@Component({
  selector: 'app-images-carousel',
  templateUrl: './images-carousel.component.html',
  styleUrls: ['./images-carousel.component.css'],
})
export class ImagesCarouselComponent implements OnInit {
  @Input() images: any;
  private album: Array<any> = [];

  slideIndex = 0;
  constructor(private lightbox: Lightbox) {}

  ngOnInit(): void {
    if (!this.images.length) {
      this.images = [...this.images, '../../assets/images/dropzone/placeholder_elbaladiya.jpg'];
    } else {
      this.images = this.images.map(item => Backend.baseUrl + item);
    }
    this.images.forEach(item => {
      const image = {
        src: item,
        caption: '',
        thumb: '',
      };
      this.album.push(image);
    });
  }

  onPreviousClick() {
    const previous = this.slideIndex - 1;
    this.slideIndex = previous < 0 ? this.images.length - 1 : previous;
  }

  onNextClick() {
    const next = this.slideIndex + 1;
    this.slideIndex = next === this.images.length ? 0 : next;
  }
  open(e, index): void {
    // open lightbox
    $('.modal-backdrop').remove();

    this.lightbox.open(this.album, index, { showImageNumberLabel: true });
    e.stopPropagation();
  }
}
