import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Comment } from '../core/models/class/comment';
import { ReactionRequest } from '../core/models/class/reaction-request';
import { GenericCRUDServiceWithFollowItem } from '../core/services/generic.service';
import Backend from 'src/app/core/backend';
import { BaseService } from '../core/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class ForumService extends GenericCRUDServiceWithFollowItem<Comment> {
  private reactionUrl = '';

  constructor(http: HttpClient) {
    super(http);
    this.prefix = '/forum/comments';
    this.reactionUrl = Backend.url + '/reactions';
  }

  public like(id: number): Observable<any> {
    return this.http.post(this.reactionUrl, ReactionRequest.getCommentReactionRequest(id, 1), {
      headers: BaseService.getHeaders(),
    }) as Observable<any>;
  }

  public dislike(id: number): Observable<any> {
    return this.http.post(this.reactionUrl, ReactionRequest.getCommentReactionRequest(id, -1), {
      headers: BaseService.getHeaders(),
    }) as Observable<any>;
  }

  public removeLike(id: number): Observable<any> {
    return this.http.post(this.reactionUrl, ReactionRequest.getCommentReactionRequest(id, 0), {
      headers: BaseService.getHeaders(),
    }) as Observable<any>;
  }
}
