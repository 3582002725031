import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tuto-eticket-v2',
  templateUrl: './tuto-eticket-v2.component.html',
  styleUrls: ['./tuto-eticket-v2.component.css'],
})
export class TutoEticketV2Component implements OnInit {
  steps = [
    {
      text: 'قـم بـتـحـمـيـل تـطـبـيـقـة elbaladiya.tn و إنـشـاء حـسـاب عـلـى الـمـنـصـة',
      button: { content: 'تـحـمـيـل الـتـطـبـيـقـة', url: 'https://onelink.to/elbaladiya.tn' },
    },
    { text: 'اذهـب إلـى فـضـاء خـدمـة الـتـذكـرة الإلـكـتـرونـيـة فـي الـبـلـديـة الـمـعـنـيـة' },
    { text: 'قـم بـمـسـح ال QR-Code مـجددا عـبـر خـاصـيـة “تـحـمـيـل تـذكـرة” داخـل هـذا الـفـضـاء' },
  ];

  constructor() {}

  ngOnInit(): void {}
}
