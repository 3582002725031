import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Event } from '../core/models/class/event';

import { BaseService } from '../core/services/base.service';
import { GenericCRUDService } from '../core/services/generic.service';

@Injectable({
  providedIn: 'root',
})
export class EventsService extends GenericCRUDService<Event> {
  constructor(http: HttpClient) {
    super(http);
    this.prefix = '/events';
  }

  public setInterest(id): Observable<any> {
    return this.http.post(this.getUrl() + '/' + id + '/interest', {
      headers: BaseService.getHeaders(),
    }) as Observable<any>;
  }

  public removeInterest(id): Observable<any> {
    return this.http.post(this.getUrl() + '/' + id + '/disinterest', {
      headers: BaseService.getHeaders(),
    }) as Observable<any>;
  }

  public setParticipation(id): Observable<any> {
    return this.http.post(this.getUrl() + '/' + id + '/participate', {
      headers: BaseService.getHeaders(),
    }) as Observable<any>;
  }

  public removeParticipation(id): Observable<any> {
    return this.http.post(this.getUrl() + '/' + id + '/unparticipate', {
      headers: BaseService.getHeaders(),
    }) as Observable<any>;
  }
}
