import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat',
})
export class TimeFormatPipe implements PipeTransform {
  transform(value: string): string {
    if (!value || typeof value !== 'string' || !/^\d{2}:\d{2}:\d{2}$/.test(value)) {
      return value;
    }

    const [hours, minutes] = value.split(':').slice(0, 2);

    return `${hours}:${minutes}`;
  }
}
