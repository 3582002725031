import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getItem, StorageItem } from '../core';
import { Reservation } from '../core/models/class/agency';
import { BaseService } from '../core/services/base.service';

import { GenericReadOnlyService } from '../core/services/generic.service';
import { MunisFacade } from '../core/store';
@Injectable({
  providedIn: 'root',
})
export class ReservationService extends GenericReadOnlyService<Reservation> {
  constructor(protected http: HttpClient) {
    super(http);
    this.prefix = '/agencies/';
    this.suffix = '/';
  }

  protected getUrl() {
    return `${BaseService.url}/municipalities/${MunisFacade.getMunicipalityId()}${this.prefix}${getItem(
      StorageItem.agency_id,
    )}/reservations/`;
  }

  public download(id: string | number) {
    return this.http.get(`${this.getUrl()}${id}/pdf/`, { headers: BaseService.getHeaders(), responseType: 'blob' });
  }

  public cancel(reservations: Reservation) {
    return this.http.post(`${this.getUrl()}${reservations.id}/cancel/`, reservations, {
      headers: BaseService.getHeaders(),
      responseType: 'blob',
    });
  }
}
