export enum UpdateTypes {
  RECEIVED = 'RECEIVED',
  PROCESSING = 'PROCESSING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  NOT_CLEAR = 'NOT_CLEAR',
  INVALID = 'INVALID',
}
export enum DossierTypes {
  BUILDING = 'BUILDING',
  TEMPORARY_WORKS = 'TEMPORARY_WORKS',
  TAKSIM = 'TAKSIM',
  STREET = 'STREET',
  ONAS = 'ONAS',
  NETWORKS = 'NETWORKS',
  SONEDE = 'SONEDE',
  ELECTRICITY = 'ELECTRICITY',
  GAZ = 'GAZ',
  OTHER = 'OTHER',
}
export enum ArabicDossierTypes {
  BUILDING = 'رخصة بناء',
  TEMPORARY_WORKS = 'رخصة في الأشغال الوقتية',
  TAKSIM = 'رخصة المصادقة على تقسيم',
  STREET = 'رخصة الاستغلال الوقتي للطريق العام',
  ONAS = 'ربط بشبكة التطهير',
  NETWORKS = 'ربط بالشبكات العمومية',
  SONEDE = 'ربط بشبكة المياه',
  ELECTRICITY = 'ربط بشبكة الكهرباء',
  GAZ = 'ربط بشبكة الغاز',
  OTHER = 'أخرى',
}
export enum ProcedureTypes {
  ADMINISTRATIVE = 'خدمات إدارية',
  CIVIL_STATUS = 'خدمات الحالة المدنية',
  URBAN = 'خدمات عمرانية',
  REGULATORY = 'خدمات ترتيبية',
  FINANCIAL = 'خدمات مالية',
  OTHER = 'خدمات مختلفة',
}

export enum TopicStatesTypes {
  ACTIVATED = 'ACTIVATED',
  ARCHIVED = 'ARCHIVED',
  HIDDEN = 'HIDDEN',
}

export enum PollStatusTypes {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  ENDED = 'ENDED',
}

export enum PollKindTypes {
  MULTI_CHOICE = 'MULTI_CHOICE',
  SINGLE_CHOICE = 'SINGLE_CHOICE',
}

export enum CommentTypes {
  QUESTION = 'QUESTION',
  SUGGESTION = 'SUGGESTION',
  REMARK = 'REMARK',
}

export enum mode {
  MODE_COMPLAINT = 'complaints',
  MODE_DOSSIER = 'dossiers',
  MODE_SUBJECT_ACCESS_REQUEST = 'subject-access-requests',
  MODE_FORUM = 'forum/comments',
}

export enum NotificationType {
  COMPLAINT = 'complaint',
  DOSSIER = 'dossier',
  SUBJECT_ACCESS_REQUEST = 'subjectaccessrequest',
  FORUM = 'comment',
  EVENT = 'event',
}

export enum FeatureStatus {
  DEACTIVATED = 'DEACTIVATED',
  ACTIVATED = 'ACTIVATED',
}
export enum NotificationModelName {
  SUBJECT_ACCESS_REQUEST = 'subject access request',
  DOSSIER = 'dossier',
  COMPLAINT = 'complaint',
  RESERVATION = 'reservation',
  EVENT = 'event',
  COMMENT = 'comment',
}
