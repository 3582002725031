import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Municipality } from 'src/app/core/models/class/municipality';
import { Agency, AgencyInfo, AgencyServices, Ticket, TicketReq } from 'src/app/core/models/interface/ticket';
import { TicketService } from 'src/app/services/ticket.service';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { MunisFacade, UiFacade, UserFacade } from 'src/app/core/store';
import { defaultAnimation, FeatureStatus, getItem, setItem, StorageItem } from 'src/app/core';
import { MainFeature } from 'src/app/core/models/class/GenericListFeature';

declare let $: any;

@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.css'],
  ...defaultAnimation,
})
export class TicketListComponent extends MainFeature implements OnInit {
  state: ComponentState = {
    agenciesServices: [],
    tickets: [],
    isOpen: false,
    agency: null,
    agencyInfo: null,
    status: 'Closed',
    hasEtickets: true,
  };

  agencyId: string = getItem(StorageItem.agency_id);
  selectedTicket: Ticket;
  errorMessage = 'هذه البلدية ,لا تتمتع حاليا بخدمة التذكرة الالكترونية.';
  constructor(
    private ticketService: TicketService,
    private _munisFacade: MunisFacade,
    private route: ActivatedRoute,
    private router: Router,
    public _uiFacade: UiFacade,
    public _userFacade: UserFacade,
    public _toastLuncher: ToastLuncherService,
  ) {
    super(_userFacade, _toastLuncher);
    /*
    E-ticket is only available to denden
    */
  }

  ngOnInit(): void {
    this._munisFacade.selectedMuni$.subscribe((res: Municipality) => {
      if (res && res.service_eticket === FeatureStatus.ACTIVATED && !res?.agency?.length)
        this.router.navigate(['v2'], { relativeTo: this.route });
      if (res?.agency?.length && res?.agency[0]?.status) {
        this.state.status = res.agency[0]?.status;
        this.state.isOpen = res.agency[0]?.status === 'Open';
        this.agencyId = res.agency[0]?.id + '';
        setItem(StorageItem.agency_id, this.agencyId);
        this.state.agencyInfo = res.agency[0];
        this.getAgency();
      } else {
        this.state.hasEtickets = false;
        this._uiFacade.setFeatureLoaded();
      }
    });
  }

  getAgency() {
    this.ticketService.getAgencyById(this.agencyId).subscribe(
      res => {
        if (!res.detail) {
          this.state.hasEtickets = true;
          this.state.agency = res.agence;
          this.state.agenciesServices = res.agence.services || [];
          // get user tickets
          if (this.isAuthenticated && this.state.isOpen) {
            this.getUserTickets();
          }
          this._uiFacade.setFeatureLoaded();
        }
      },
      error => {
        this.errorMessage = 'هذي الخدمة غير متوفرة حالياً. الرجاء المحاولة لاحقاً.';
        this.state.hasEtickets = true;
        this._uiFacade.setFeatureLoaded();
      },
    );
  }

  getUserTickets() {
    this.ticketService.getUserTickets(this.agencyId).subscribe(res => {
      const alreadyReservedServices = res.tickets.map(service => service.id_service);
      this.state.tickets = res.tickets.filter(ticket => ticket.called !== 2); // remove tickets that has been called
      // remove services that has been reserved
      this.state.agenciesServices = this.state.agenciesServices.filter(
        service => !alreadyReservedServices.includes(service.id),
      );
    });
  }

  reserveTicket(index: number) {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const body: TicketReq = {
      service_id: index + 1,
    };
    this.ticketService.reserveTicket(body, this.agencyId).subscribe(
      (res: any) => {
        this._toastLuncher.success({
          text: ' أمامك ' + res.ticket_info_json.nbattente + ' أشخاص في صف خدمة ' + res.ticket_info_json.service,
          showConfirmButton: false,
        });
        this.state.tickets.push(res);
        this.getUserTickets();
      },
      error => {
        this._toastLuncher.alert({ text: 'حصل خطأ ... ', showConfirmButton: false });
        delay(2000).then(() => {
          window.location.reload();
        });
      },
    );
  }

  checkTicket(idx: number) {
    this.selectedTicket = this.state.tickets[idx];
    $('#ticketModal').modal('show');
  }
}
class ComponentState {
  agenciesServices: AgencyServices[];
  tickets: Ticket[];
  isOpen: boolean;
  agency: Agency;
  agencyInfo: AgencyInfo;
  status: string;
  hasEtickets: boolean;
}
