export class ReactionRequest {
  type: string;

  value: number;

  post_type: string;

  post_id: number;

  static getCommentReactionRequest(id: number, value: number): ReactionRequest {
    const toReturn = new ReactionRequest();
    toReturn.type = 'L';
    toReturn.value = value;
    toReturn.post_type = 'COMMENT';
    toReturn.post_id = id;
    return toReturn;
  }
}
