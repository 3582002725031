import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { IWizardStep } from 'src/app/core/models/interface/user-interface';

@Component({
  selector: 'app-form-wizard',
  templateUrl: './form-wizard.component.html',
  styleUrls: ['./form-wizard.component.css'],
})
export class FormWizardComponent implements OnInit {
  @Output() skip: EventEmitter<number> = new EventEmitter();
  @Input() steps: IWizardStep;
  @Input() selectedIndex: number;
  constructor() {}

  ngOnInit(): void {}
  trackById(index, item) {
    return item.id;
  }
  skipTo(idx: number): void {
    this.skip.emit(idx);
  }
}
