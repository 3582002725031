import { Component, OnInit } from '@angular/core';
import { data } from './frequently-asked.data';
@Component({
  selector: 'app-frequently-asked',
  templateUrl: './frequently-asked.component.html',
  styleUrls: ['./frequently-asked.component.css'],
})
export class FrequentlyAskedComponent implements OnInit {
  data = data;
  constructor() {}

  ngOnInit(): void {}
}
