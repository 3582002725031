import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { OurCommonModule } from '../../core/OurCommon.module';

import { AccessDetailHomeComponent } from './access-detail-home/access-detail-home.component';
import { DossierDetailHomeComponent } from './dossier-detail-home/dossier-detail-home.component';
import { EventDetailHomeComponent } from './event-detail-home/event-detail-home.component';
import { ForumDetailHomeComponent } from './forum-detail-home/forum-detail-home.component';
import { HomeComponent } from './home.component';
import { NewsDetailHomeComponent } from './news-detail-home/news-detail-home.component';
import { PlaintesDetailHomeComponent } from './plaintes-detail-home/plaintes-detail-home.component';
import { ReportDetailHomeComponent } from './report-detail-home/report-detail-home.component';

const routes: Routes = [{ path: '', component: HomeComponent }];
@NgModule({
  declarations: [
    HomeComponent,
    ReportDetailHomeComponent,
    EventDetailHomeComponent,
    NewsDetailHomeComponent,
    PlaintesDetailHomeComponent,
    ForumDetailHomeComponent,
    AccessDetailHomeComponent,
    DossierDetailHomeComponent,
  ],
  imports: [CommonModule, FormsModule, RouterModule, OurCommonModule, RouterModule.forChild(routes)],
  bootstrap: [HomeComponent],
})
export class HomeModule {}
