import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AngularOpenlayersModule } from 'ngx-openlayers';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AuthGuardService } from '../../core/auth/auth-guard.service';
import { OurCommonModule } from '../../core/OurCommon.module';

import { AdministrativeFeaturesComponent } from './administrative-features.component';
import { DossiersListComponent } from './dossiers-list/dossiers-list.component';
import { PlaintesDetailsComponent } from '../administrative-features/plaintes-details/plaintes-details.component';
import { PlaintesListComponent } from './plaintes-list/plaintes-list.component';
import { PlaintesNewComponent } from './plaintes-new/plaintes-new.component';
import { AccesinfoNewComponent } from './accesinfo-new/accesinfo-new.component';
import { AccesinfoComponent } from '../administrative-features/accesinfo/accesinfo.component';
import { InfoPointComponent } from 'src/app/modules/administrative-features/info-point/info-point.component';
import { ProceduresDetailsComponent } from '../administrative-features/procedures-details/procedures-details.component';
import { ProceduresListComponent } from './procedures-list/procedures-list.component';
import { TicketListComponent } from './ticket-list/ticket-list.component';
import { UserTicketComponent } from 'src/app/components/user-ticket/user-ticket.component';
import { AgencyStateBarComponent } from 'src/app/components/agency-state-bar/agency-state-bar.component';
import { CategoryService } from 'src/app/services/category.service';
import { DossierStatusComponent } from 'src/app/modules/administrative-features/dossier-status/dossier-status.component';
import { AddDossierComponent } from './add-dossier/add-dossier.component';
import { FormWizardComponent } from './form-wizard/form-wizard.component';
import AccessinfoDetailsComponent from './accessinfo-details/accessinfo-details.component';
import { DossiersDetailsComponent } from './dossiers-details/dossiers-details.component';
import { TicketListV2Component } from './ticket-list-v2/ticket-list.component';
import { BuildingService } from 'src/app/services/building.service';
import { DossiersAttachment } from 'src/app/core/models/class/dossier';
import { DigitizeReservationComponent } from 'src/app/components/digitize-reservation/digitize-reservation.component';
import { TimeFormatPipe } from 'src/app/core/pipes/time-nosecond.pipe';
import { WebsocketService } from 'src/app/services/socket.service';

const routes: Routes = [
  {
    path: '',
    component: AdministrativeFeaturesComponent,
    children: [
      {
        path: 'dossiers',
        component: DossiersListComponent,
      },
      {
        path: 'dossiers/:id',
        component: DossiersDetailsComponent,
      },
      {
        path: 'plaintes',
        component: PlaintesListComponent,
      },
      {
        path: 'plaintes/:id',
        component: PlaintesDetailsComponent,
      },
      {
        path: 'nouveau-plainte',
        component: PlaintesNewComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'nouveau-accesinfo',
        component: AccesinfoNewComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'accesinfo',
        component: AccesinfoComponent,
      },
      {
        path: 'accesinfo/:id',
        component: AccessinfoDetailsComponent,
      },
      {
        path: 'procedures',
        component: ProceduresListComponent,
      },
      {
        path: 'procedures/:id',
        component: ProceduresDetailsComponent,
      },
      {
        path: 'etickets',
        component: TicketListComponent,
      },
      {
        path: 'etickets/v2',
        component: TicketListV2Component,
      },
    ],
  },
];

@NgModule({
  declarations: [
    AdministrativeFeaturesComponent,
    DossiersListComponent,
    PlaintesNewComponent,
    PlaintesListComponent,
    PlaintesDetailsComponent,
    AccesinfoNewComponent,
    AccesinfoComponent,
    AccessinfoDetailsComponent,
    InfoPointComponent,
    ProceduresDetailsComponent,
    ProceduresListComponent,
    TicketListComponent,
    UserTicketComponent,
    AgencyStateBarComponent,
    DossierStatusComponent,
    AddDossierComponent,
    FormWizardComponent,
    DossiersDetailsComponent,
    TicketListV2Component,
    DigitizeReservationComponent,
    TimeFormatPipe,
  ],
  imports: [
    AngularOpenlayersModule,
    InfiniteScrollModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    RouterModule,
    OurCommonModule,
  ],
  bootstrap: [AdministrativeFeaturesComponent],
  providers: [AuthGuardService, CategoryService, DossiersAttachment, BuildingService, WebsocketService],
})
export class AdministrativeFeaturesModule {}
