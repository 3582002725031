import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-app',
  templateUrl: './mobile-app.component.html',
  styleUrls: ['./mobile-app.component.css', '../general-home.component.css'],
})
export class MobileAppComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
