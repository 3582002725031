import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import Backend from 'src/app/core/backend';
import { defaultAnimation } from '../../../core';
import { MunisFacade, UiFacade, UserFacade } from 'src/app/core/store';
import { Committee } from 'src/app/core/models/class/committee';
import { GenericSingleViewFeature } from 'src/app/core/models/class/GenericSingleViewFeature';
import { CommitteeService } from 'src/app/services/committee.service';
import { Report } from 'src/app/core/models/class/report';

@Component({
  selector: 'app-comite-report-details',
  templateUrl: './comite-report-details.component.html',
  styleUrls: ['./comite-report-details.component.css'],
  ...defaultAnimation,
})
export class ComiteReportDetailsComponent extends GenericSingleViewFeature<Committee> implements OnInit {
  frontUrl = Backend.frontUrl;
  baseUrl = Backend.baseUrl;
  report: Report;

  constructor(
    public _committeeService: CommitteeService,
    public route: ActivatedRoute,
    public _munisFacade: MunisFacade,
    public _toastLuncher: ToastLuncherService,
    public _userFacade: UserFacade,
    public router: Router,
    public _uiFacade: UiFacade,
  ) {
    super('id', _committeeService, route, _munisFacade, _userFacade, _toastLuncher, router);
  }

  ngOnInit(): void {}

  getItem(): void {
    this.route.params.subscribe(params => {
      this.item_id = params[this.item_param_name];
      this._service.getReports().subscribe(
        data => {
          this.report = this.normalizeAfterGetItem(data);
          this.isLoading = false;
        },
        error => {
          throw new Error('لم نتمكن من العثور على المعلومات المطلوبة');
        },
      );
    });
  }

  normalizeAfterGetItem(data) {
    data = data.filter(report => report.id === parseInt(this.item_id, 10))[0];
    this._uiFacade.setFeatureLoaded();
    return data;
  }
}
