import { IfeatureItem } from '../interface/IfeatureItem';
import { PollStatusTypes } from '../types';

export class Poll implements IfeatureItem {
  id: number;
  picture: string;

  total_participants: number;
  total_votes: string | number;
  total_local_votes: number;
  total_local_participants: number;

  already_voted: boolean;
  choices: Choice[] = [];
  title?: any;
  starts_in?: string;
  remaining_time?: string;
  text: string;
  created_at: string;
  starts_at: string;
  ends_at: string;
  kind: string;
  live_results: boolean = false;
  created_by: number;
  status: PollStatusTypes; // added in front
  municipality: number;
}

export class Choice {
  id: number;
  total_votes: string | number;
  total_local_votes: string | number;
  picture?: any;
  text: string;
  poll: number;
}
