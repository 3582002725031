import { Component, OnInit } from '@angular/core';
import { defaultAnimation } from 'src/app/core';
declare let $: any;
@Component({
  selector: 'app-top-section',
  templateUrl: './top-section.component.html',
  styleUrls: ['./top-section.component.css', '../general-home.component.css'],
  ...defaultAnimation,
})
export class TopSectionComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    /* AUTHOR LINK */
    $('.about-me-img').hover(
      () => {
        $('.authorWindowWrapper').stop().fadeIn('fast').find('p').addClass('trans');
      },
      () => {
        $('.authorWindowWrapper').stop().fadeOut('fast').find('p').removeClass('trans');
      },
    );
  }
}
