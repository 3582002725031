import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from '../core/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class StatsService extends BaseService {
  constructor(private http: HttpClient) {
    super();
    this.prefix = '/stats/all';
  }

  getStatistics(): Observable<Statistic> {
    return this.http.get(BaseService.url + this.prefix, { headers: BaseService.getHeaders() }) as Observable<Statistic>;
  }
}

export class Statistic {
  active_municipalities: number;
  signed_municipalities: number;
  registered_citizens: number;
  associations: number;
}
