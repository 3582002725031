import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Complaint } from '../core/models/class/complaint';

import { GenericCRUDServiceWithFollowItem } from '../core/services/generic.service';

@Injectable({
  providedIn: 'root',
})
export class ComplaintsService extends GenericCRUDServiceWithFollowItem<Complaint> {
  constructor(http: HttpClient) {
    super(http);
    this.prefix = '/complaints';
  }
}
