import { Component, OnInit } from '@angular/core';
import { defaultAnimation } from 'src/app/core';

import list from './info-point.data';
declare let $: any;

@Component({
  selector: 'app-info-point',
  templateUrl: './info-point.component.html',
  styleUrls: ['./info-point.component.css'],
  ...defaultAnimation,
})
export class InfoPointComponent implements OnInit {
  questions: any[] = list;
  constructor() {}

  ngOnInit(): void {}
  toggleModal() {
    $('#subject_info_modal').modal('toggle');
  }
  toggleQuestion(idx: number) {
    $('#collapse_' + idx).toggle();
    const icon = $('#icon_' + idx);
    // change icon (plus / minus) depending on state
    icon.hasClass('fa-plus-circle')
      ? icon.removeClass('fa-plus-circle').addClass('fa-minus-circle')
      : icon.removeClass('fa-minus-circle').addClass('fa-plus-circle');
  }
}
