import { Municipality } from '../../../models/class/municipality';
import { MunisActions, MunicipalityActionTypes } from './municipality.action';

export interface MunisState {
  selectedMuni: Municipality | null;
  munisList: Municipality[] | null;
  isLoading: boolean;
}

export const initialState: MunisState = {
  selectedMuni: null,
  munisList: null,
  isLoading: false,
};

export function munisReducer(state = initialState, action: MunisActions): MunisState {
  switch (action.type) {
    case MunicipalityActionTypes.LoadMuni: {
      return Object.assign({}, state, { ...state, isLoading: true });
    }

    case MunicipalityActionTypes.MuniLoaded: {
      return Object.assign({}, state, { ...state, isLoading: false, selectedMuni: action.payload });
    }

    case MunicipalityActionTypes.LoadMunis: {
      return Object.assign({}, state, { ...state, isLoading: true });
    }

    case MunicipalityActionTypes.MunisLoaded: {
      return Object.assign({}, state, { ...state, isLoading: false, munisList: action.payload });
    }

    default:
      return state;
  }
}

export const getSelectedMuni = (state: MunisState) => state.selectedMuni;
