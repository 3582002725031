import { AgencyInfo } from '../interface/ticket';
import { FeatureStatus } from '../types';
import { Association } from './association';

export class MunicipalitySummary {
  id: number;
  name: string;
  name_fr: string;
  city: string;
  is_active: boolean;
  is_signed: boolean;
  has_eticket: boolean;
  logo: string;
  longitude: number;
  latitude: number;
  route_name: string;
  activation_date?: Date | null;
}
export class Municipality extends MunicipalitySummary {
  public is_selected = false;
  public website: string;
  public facebook_url: string;
  public partner_associations = new Array<Association>();
  public total_followers: number;
  public agency: AgencyInfo[];
  public regions: Region[];
  public service_eticket: FeatureStatus;
  public service_dossiers: FeatureStatus;
  public service_complaints: FeatureStatus;
  public service_sar: FeatureStatus;
  public service_procedures: FeatureStatus;
  public service_news: FeatureStatus;
  public service_forum: FeatureStatus;
  public service_reports: FeatureStatus;
  public service_events: FeatureStatus;
}

export interface Region {
  id: number;
  name: string;
  municipality_id: number;
}

export class StateRow {
  stateName: string;
  munisCount: number;
  isEmpty: boolean;
  municipalities: MunicipalitySummary[];
}

export type MunicipalityGlobal = Municipality | MunicipalitySummary;
