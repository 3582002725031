import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MunisFacade } from 'src/app/core/store';

import Backend from 'src/app/core/backend';
import { Municipality } from 'src/app/core/models/class/municipality';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
})
export class AboutComponent implements OnInit {
  // imageObject: Array<object> = [{
  //   image: 'assets/images/logo/partners/roua.png',
  //   thumbImage: 'assets/images/logo/partners/roua.png',
  //   alt: 'alt of image',
  // }, {
  //   image: 'assets/images/logo/partners/adesm.png',
  //   thumbImage: 'assets/images/logo/partners/adesm.png',
  //   alt: 'alt of image',
  // }, {
  //   image: 'assets/images/logo/partners/welovebizerte.png',
  //   thumbImage: 'assets/images/logo/partners/welovebizerte.png',
  //   alt: 'alt of image',
  // }, {
  //   image: 'assets/images/logo/partners/apek.png',
  //   thumbImage: 'assets/images/logo/partners/apek.png',
  //   alt: 'alt of image',
  // }, {
  //   image: 'assets/images/logo/partners/gremda.png',
  //   thumbImage: 'assets/images/logo/partners/gremda.png',
  //   alt: 'alt of image',
  // },
  // ];

  municipalities = new Array<Municipality>();
  baseUrl = Backend.baseUrl;

  constructor(private _munisFacade: MunisFacade, public route: Router) {}

  ngOnInit() {
    this.getMunicipalities();
  }

  private getMunicipalities() {
    this._munisFacade.munisList$.subscribe(data => {
      this.municipalities = data;
    });
  }

  changeMunicipality(mun: Municipality) {
    this.route.navigateByUrl(`/${mun.route_name}`).then(() => {
      window.location.reload();
    });
  }
}
