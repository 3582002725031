import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MunisFacade, UiFacade, UserFacade } from 'src/app/core/store';

import { News } from '../../../core/models/class/news';
import { CommitteeService } from '../../../services/committee.service';
import { NewsService } from '../../../services/news.service';
import { defaultAnimation, getDistinctKeysFrom, getItem, StorageItem, timeAgo } from '../../../core';
import { Committee } from 'src/app/core/models/class/committee';
import { SelectedItemFeature } from 'src/app/core/models/class/GenericListFeature';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { Municipality } from 'src/app/core/models/class/municipality';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.css'],
  ...defaultAnimation,
})
export class NewsListComponent extends SelectedItemFeature<News> implements OnInit {
  committees: Array<Committee> = new Array<Committee>();
  municipalityName;

  // dropdown list params
  committeeIdList = [];
  categoryList = [];
  titleList = [];
  // filters list
  committeeFilter = [];
  categoryFilter = [];
  titleFilter = [];

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'item',
    selectAllText: 'اختر الكل',
    unSelectAllText: 'إلغاء الكل',
    itemsShowLimit: 0,
    enableCheckAll: false,
    maxHeight: 900,
    allowSearchFilter: false,
  };

  constructor(
    public _munisFacade: MunisFacade,
    public newsService: NewsService,
    public _uiFacade: UiFacade,
    public _userFacade: UserFacade,
    public committeeService: CommitteeService,
    public _toastLuncher: ToastLuncherService,
  ) {
    super(newsService, _userFacade, _munisFacade, _toastLuncher);
  }

  ngOnInit() {}

  onMunicipalityChange(municipality: Municipality) {
    this.municipalityName = municipality.name_fr;
    this.items = [];
    this.filteredItems = [];

    this.committeeIdList = [];
    this.categoryList = [];
    this.titleList = [];
    this.getCommittees();
    this.getPages();
  }

  getCommittees() {
    this.committeeService?.get().subscribe(data => {
      this.committees = data;
      this.committeeIdList = getDistinctKeysFrom(data, 'title');
    });
  }

  postSettingData() {
    this.categoryList = getDistinctKeysFrom(this.items, 'category');
    this.titleList = getDistinctKeysFrom(this.items, 'title');
    this.filteredItems = this.items;
    this._uiFacade.setFeatureLoaded();
  }

  preSettingData(data: News[]): News[] {
    return data.sort((item1, item2) => (item1.published_at > item2.published_at ? -1 : 1));
  }

  getCommitteeById(id) {
    return this.committees?.find(item => item.id === id)?.title;
  }

  applyFilters() {
    const committeeFilter = this.committeeFilter.map(item => item.item);
    const categoryFilter = this.categoryFilter.map(item => item.item);
    const titleFilter = this.titleFilter.map(item => item.item);

    // apply on if one filter is selected
    if (titleFilter.length || categoryFilter.length || committeeFilter.length)
      this.filteredItems = this.items.filter(post => {
        let arr = [];
        // each post should correspond to each selected filter
        if (titleFilter?.length) arr.push(titleFilter.includes(post.title));
        if (categoryFilter?.length) arr.push(categoryFilter.includes(post.category));
        if (committeeFilter?.length) arr.push(committeeFilter.includes(post.title));
        return arr.every(element => element === true);
      });
  }

  /**
   * @description a workaround ng-multiselect to fake user click on checkbox.
   */
  removeFilter(filter: any, type: 'title' | 'category' | 'committee') {
    let idx = 0;
    let i = -1;
    let found = false;
    let search = [];

    // these (if) statements should correspond to dropdowns order in HTML.
    if (type === 'committee') {
      // no need skip anything.
      search = this.committeeIdList;
    }
    if (type === 'category') {
      // skip the number of committees ids.
      idx = this.committeeIdList.length;
      search = this.categoryList;
    }
    if (type === 'title') {
      // skip committes and categories.
      idx = this.committeeIdList.length + this.categoryList.length;
      search = this.titleList;
    }

    // look for item
    while (!found && search.length > i) {
      i++;
      if (search[i].item === filter.item) found = true;
    }

    const els = document.querySelectorAll<HTMLElement>('li.multiselect-item-checkbox');
    if (found) {
      els[idx + i].click();
    }
  }
}
