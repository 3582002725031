import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { WEBSOCKET_URL } from '../app.module';

@Injectable()
export class WebsocketService {
  private websocketSubject: WebSocketSubject<any> | null = null;

  constructor(@Inject(WEBSOCKET_URL) private websocketUrl: string) {
    this.initializeWebSocket();
  }

  private initializeWebSocket(): void {
    if (!this.websocketUrl) {
      console.error('WebSocket URL is required.');
      return;
    }

    this.websocketSubject = webSocket<any>(this.websocketUrl);

    this.websocketSubject.subscribe({
      next: message => this.handleMessage(message),
      error: error => {
        console.error('WebSocket error:', error);
        this.reconnectWebSocket();
      },
      complete: () => {
        console.log('WebSocket connection closed');
        this.reconnectWebSocket();
      },
    });
  }

  private handleMessage(message: any): void {
    console.log('WebSocket Message Received:');
  }

  private reconnectWebSocket(): void {
    console.log('Attempting to reconnect...');
    setTimeout(() => this.initializeWebSocket(), 5000);
  }

  sendEmptyMessage(): void {
    this.websocketSubject?.next({});
  }

  getWebSocketMessages(): Observable<any> | null {
    return this.websocketSubject ? this.websocketSubject.asObservable() : null;
  }
}
