import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pollCount',
})
export class PollCountPipe implements PipeTransform {
  transform(votes_count: number, total_votes_count: number): number {
    return this.calculate(votes_count, total_votes_count);
  }

  calculate(v: number, t: number) {
    if (t === 0) return 0;
    const num = (v / t) * 100;
    const roundedString = num.toFixed(2);
    return Number(roundedString);
  }
}
