import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-google-form',
  templateUrl: './google-form.component.html',
  styleUrls: ['./google-form.component.css'],
})
export class GoogleFormComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
