import { IfeatureItem } from '../interface/IfeatureItem';

export class News implements IfeatureItem {
  id: number;
  municipality_id: number;
  title: string;
  text: string;
  excerpt: string;
  images: string[] = [];
  image: string;
  published_at: string;
  committee_id: string = '';
  committee: string;
  category: string;
  score: number;
  ago: string;
  body: string;
  attachment: string;
  tags: string[] = [];
}

export class NewsTags {
  id: number;
  name: string;
}
