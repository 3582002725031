import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { defaultAnimation } from 'src/app/core';
import { Reservation } from 'src/app/core/models/class/agency';
import { AgenciesService } from 'src/app/services/agencies.service';
declare let $: any;

@Component({
  selector: 'app-digitize-reservation',
  templateUrl: './digitize-reservation.component.html',
  styleUrls: ['./digitize-reservation.component.css'],
  ...defaultAnimation,
})
export class DigitizeReservationComponent implements OnInit {
  @Output() onReservationSuccess: EventEmitter<Reservation> = new EventEmitter();

  signature: string = '';
  isLoading = false;
  constructor(protected _agenciesService: AgenciesService) {}

  ngOnInit(): void {}

  submit() {
    this.isLoading = true;
    this._agenciesService.getPhysicalToDigital(this.signature).subscribe(
      res => {
        this.signature = '';
        this.isLoading = false;
        $('#digitizeModal').modal('toggle');
        this.onReservationSuccess.next(res);
      },
      err => {
        this.isLoading = false;
        throw new Error('لم نتمكن من تحميل التذكرة، الرجاء التثبت من المعطيات ');
      },
    );
  }
}
