import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as UiActions from './ui.action';
import { UiState } from './ui.reducer';
import { selectedfeatureIsLoading } from '..';

@Injectable({
  providedIn: 'root',
})
export class UiFacade {
  featureIsLoading$ = this.store.pipe(select(selectedfeatureIsLoading));

  constructor(private store: Store<UiState>) {}

  /**
   * set store isLoading = true
   */
  setFeatureLoading() {
    this.store.dispatch(new UiActions.featureIsLoading());
  }
  /**
   * set store isLoading = false
   */
  setFeatureLoaded() {
    this.store.dispatch(new UiActions.featureIsLoaded());
  }
}
