import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FullCalendarComponent, CalendarOptions } from '@fullcalendar/angular';
import { ClipboardService } from 'ngx-clipboard';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { MunisFacade, UiFacade, UserFacade } from 'src/app/core/store';

import { EventsService } from '../../../services/events.service';
import { defaultAnimation, formatMunicipalityName } from '../../../core';
import { Event } from 'src/app/core/models/class/event';
import { SelectedItemFeature } from 'src/app/core/models/class/GenericListFeature';
import { Municipality } from 'src/app/core/models/class/municipality';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
  ...defaultAnimation,
  host: { class: 'calendar-component' },
})
export class CalendarComponent extends SelectedItemFeature<Event> implements OnInit {
  response: Event[] = [];
  eventsSorted = [];
  selectedEventIndex = -1;
  municipalityName;
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    //@ts-ignore
    events: this.items,
    eventClick: this.onEventClicked.bind(this),
    firstDay: 1,
    buttonText: { today: 'اليوم' },
    locale: 'ar-tn',
    eventDisplay: 'block',
  };
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  constructor(
    public _munisFacade: MunisFacade,
    public eventsService: EventsService,
    public route: ActivatedRoute,
    public _toastLuncher: ToastLuncherService,
    public _userFacade: UserFacade,
    public _uiFacade: UiFacade,
  ) {
    super(eventsService, _userFacade, _munisFacade, _toastLuncher);
  }

  ngOnInit() {
    this.getPages();
  }

  onMunicipalityChange(municipality: Municipality) {
    this.municipalityName = municipality.name_fr;
    this.items = [];
    this.response = [];
    this.selectItem = null;
    this.eventsSorted = [];
    this.getPages();
  }

  getPages() {
    const base = this;
    base._service?.get().subscribe(data => {
      this.response = data;
      this.eventsSorted = this.response.map(item => {
        const event = {
          ...item,
          starting_date: new Date(item.starting_date),
          ending_date: new Date(item.ending_date),
        };
        return event;
      });
      this.eventsSorted.sort((a, b) => a.starting_date.getTime() - b.starting_date.getTime());
      this.selectedItem = this.eventsSorted[this.eventsSorted.length - 1];
      this.selectedEventIndex = this.selectedItem ? this.eventsSorted.indexOf(this.selectedItem) : -1;
      base.items = Event.toCalendarEvents(data);
      this.calendarOptions.events = this.eventsSorted.map(event => ({
        id: event.id,
        title: event.title,
        start: event.starting_date.toISOString(),
        end: event.ending_date.toISOString(),
      }));
      const selectedId = this.route.snapshot.paramMap.get('id');
      if (selectedId !== null) {
        this.selectedItem = this.eventsSorted.find(item => item.id.toString() === selectedId);
        this.selectedEventIndex = this.eventsSorted.indexOf(this.selectedItem);
      }
      this.cursorElements();
      this._uiFacade.setFeatureLoaded();
    });
  }

  cursorElements() {
    const eve = document.getElementsByClassName('fc-content');
  }

  onEventClicked(event: any) {
    this.selectedItem = this.eventsSorted.find(item => item.id.toString() === event.event.id);
    this.selectedEventIndex = this.eventsSorted.indexOf(this.selectedItem);
    // $('#modal').modal('show');
  }

  nextEvent() {
    if (this.selectedEventIndex !== this.eventsSorted.length - 1) {
      this.selectedEventIndex++;
      this.selectedItem = this.eventsSorted[this.selectedEventIndex];
      this.gotoDate(this.selectedItem.starting_date);
    }
  }

  previousEvent() {
    if (this.selectedEventIndex !== 0) {
      this.selectedEventIndex--;
      this.selectedItem = this.eventsSorted[this.selectedEventIndex];
      this.gotoDate(this.selectedItem.starting_date);
    }
  }

  gotoDate(date: string) {
    const calendarApi = this.calendarComponent.getApi();
    calendarApi.gotoDate(date);
  }

  participateEvent() {
    this.eventsService.setParticipation(this.selectedItem.id).subscribe(() => {
      this._toastLuncher.success({
        showConfirmButton: false,
        text: 'تمت العملية بنجاح!',
      });
      this.selectedItem.user_participating = true;
      this.selectedItem.number_of_participants++;
    });
  }

  unparticipateEvent() {
    this.eventsService.removeParticipation(this.selectedItem.id).subscribe(() => {
      this._toastLuncher.success({
        showConfirmButton: false,
        text: 'تمت العملية بنجاح!',
      });
      this.selectedItem.user_participating = false;
      this.selectedItem.number_of_participants--;
    });
  }

  interestEvent() {
    this.eventsService.setInterest(this.selectedItem.id).subscribe(() => {
      this._toastLuncher.success({
        showConfirmButton: false,
        text: 'تمت العملية بنجاح!',
      });
      this.selectedItem.user_interested = true;
      this.selectedItem.number_of_interested_citizen++;
    });
  }

  disinterestEvent() {
    this.eventsService.removeInterest(this.selectedItem.id).subscribe(() => {
      this._toastLuncher.success({
        showConfirmButton: false,
        text: 'تمت العملية بنجاح!',
      });
      this.selectedItem.user_interested = false;
      this.selectedItem.number_of_interested_citizen--;
    });
  }
}
