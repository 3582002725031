const list = [
  {
    title: 'ما هو حق النفاذ  إلى المعلومة؟',
    text: 'حق دستوري يشمل كل معلومة مكتوبة أو مسجلة أو مضمنة أو مرسومة على أي محمل أو وعاء (ورقي أو إلكتروني ) مهما كان نوعه  بقطع النظر عن تاريخ إنشائها أو الحصول عليها.',
  },
  {
    title: 'ما هي  الهياكل الخاضعة لهذا الحق؟ ',
    text: 'رئاسة الحكومة ، مجلس نواب الشعب،  الوزارات، البنك المركزي، المؤسسات والمنشآت العمومية ، الهياكل العمومية المحلية و الجهوية، الجماعات المحلية، الهيئات القضائية ,المجلس الأعلى للقضاء , المحكمة الدستورية ,محكمة المحاسبات ، الهيئات الدستورية، الهيئات العمومية المستقلة، الهيئات التعديلية، أشخاص القانون التي تسير مرفقا عاما،',
  },
  {
    title: 'كيفيّة تقديم مطلب النفاذ إلى المعلومة ؟',
    text: 'بإمكان كل شخص طبيعي أو معنوي أن يقدم مطلبا كتابيا أو رقميا  في النفاذ إلى المعلومة (نموذج للمطلب:  https://bit.ly/3kzjY3W ) و يتضمن : اسم الشخص وعنوانه.المعلومة المطلوبة  و الهيكل المعني بتقديمها. كيفية النفاذ إلى المعلومة: نسخة ورقية ،  نسخة إلكترونيّة ، مقتطفات منها أو الإطلاع على عين المكان.',
  },
  {
    title: 'ما هي تكلفة النفاذ إلى المعلومة ؟',
    text: 'المبدأ : المجانية.الإستثناء: بمقابل، لا يتجاوز  المصاريف التي دفعها الهيكل المعني لتوفير المعلومة لطالبها في الصيغة المطلوبة مثل النسخ و الطباعة و غير المتعلقة  بمصاريف تسيير الهيكل.',
  },
  {
    title: 'ما هي آجال البت في مطلب النفاذ إلى المعلومة ؟ ',
    text: '20 يوما من تاريخ تقديم المطلب أو من تاريخ تصحيحه في الحالة العادية.10 أيّام إذا تعلق الأمر بالإطلاع على المعلومة على عين المكان دون الحصول على نسخة.30 يوم إذا تعلق الأمر بطلب نفاذ إلى عدّة معلومات موجودة لدى نفس الهيكل.48 ساعة إذا المعلومة لها تأثير على حياة شخص أو على حريتهّ. ',
  },
  {
    title: 'في حال عدم الحصول على المعلومة، كيف يمكن لصاحب الطلب التظلم؟ ',
    text: 'في صورة عدم الإجابة ، الرفض أو الحصول على إجابة غير مقنعة  يمكن الطعن عبر : مطلب تظلم لدى رئيس هيكل (نموذج للمطلب: https://bit.ly/3jmPToR )التوجه إلى هيئة النفاذ للمعلومة إثارة دعوى لدى المحكمة الإدارية ',
  },
  {
    title: 'ماهي الحالات التي يسمح فيها للهياكل العمومية المشمولة بالقانون برفض مطلب نفاذ المعلومة ؟',
    text: 'عندما ينجر عن إتاحة المعلومة المطلوبة ضرر جسيم للأمن العام، الدفاع الوطني، العلاقات الدوليّة ، أو حقوق الغير في حماية حياته الخاصة، معطياته الشخصيّة و ملكيته الفكرية.',
  },
  {
    title: 'هل يمكن للقاصر أو الأجنبي المقيم بتونس تقديم مطلب نفاذ إلى المعلومة؟ ',
    text: 'نعم. لست مطالبا بتقديم نسخة من بطاقة التعريف أو جواز السفر أو رقم بطاقة التعريف للحصول على المعلومة.',
  },
];
export default list;
