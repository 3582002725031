import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/nx';
import { map } from 'rxjs/operators';
import { Municipality } from '../../../models/class/municipality';
import { MunicipalityService } from '../../../services/municipality.service';

import { MunicipalityActionTypes, LoadMunis, MunisLoaded, LoadMuni, MuniLoaded } from './municipality.action';
import { MunisState } from './municipality.reducer';

@Injectable({ providedIn: 'root' })
export class MunisEffects {
  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<MunisState>,
    private municipalityService: MunicipalityService,
  ) {}

  @Effect()
  loadMunis$ = this.dataPersistence.fetch(MunicipalityActionTypes.LoadMunis, {
    run: (action: LoadMunis, state: MunisState) => {
      return this.municipalityService.getMunicipalities().pipe(map((res: Municipality[]) => new MunisLoaded(res)));
    },

    onError: (action: LoadMunis, error) => {
      console.error('Error', error);
    },
  });

  @Effect()
  loadMuni$ = this.dataPersistence.fetch(MunicipalityActionTypes.LoadMuni, {
    run: (action: LoadMuni, state: MunisState) => {
      return this.municipalityService
        .getById(action.payload.toString())
        .pipe(map((res: Municipality) => new MuniLoaded(res)));
    },

    onError: (action: LoadMuni, error) => {
      console.error('Error', error);
    },
  });
}
