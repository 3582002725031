import { Action } from '@ngrx/store';
import { Municipality } from '../../../models/class/municipality';

export enum MunicipalityActionTypes {
  LoadMunis = '[Municipalities] Load Municipalities Data',
  MunisLoaded = '[Municipalities] Municipalities Data Loaded',
  LoadMuni = '[Municipalities] Load Municipality Data',
  MuniLoaded = '[Municipalities] Municipality Data Loaded',
}

export class LoadMunis implements Action {
  readonly type = MunicipalityActionTypes.LoadMunis;
  constructor() {}
}
export class MunisLoaded implements Action {
  readonly type = MunicipalityActionTypes.MunisLoaded;
  constructor(public payload: Municipality[]) {}
}
export class LoadMuni implements Action {
  readonly type = MunicipalityActionTypes.LoadMuni;
  constructor(public payload: number) {}
}
export class MuniLoaded implements Action {
  readonly type = MunicipalityActionTypes.MuniLoaded;
  constructor(public payload: Municipality) {}
}

export type MunisActions = LoadMuni | MuniLoaded | LoadMunis | MunisLoaded;
