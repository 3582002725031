import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import * as ol from 'openlayers';
import { IWizardStep } from 'src/app/core/models/interface/user-interface';
import { stepsData } from './add-dossier.component.data';
import { DossiersService } from 'src/app/services/dossiers.service';
import { BuildingService } from 'src/app/services/building.service';
import { Building, Dossier, DossiersAttachment } from 'src/app/core/models/class/dossier';
import { DossierTypes } from 'src/app/core/models/types/index';
import { defaultAnimation, normalizedPDF } from 'src/app/core';
import { DossiersAttachmentService } from 'src/app/services/dossiers-attachment.service';
import { MunisFacade } from 'src/app/core/store';

@Component({
  selector: 'app-add-dossier',
  templateUrl: './add-dossier.component.html',
  styleUrls: ['./add-dossier.component.css'],
  ...defaultAnimation,
})
export class AddDossierComponent implements OnInit {
  @Output() onSuccess = new EventEmitter<{ unique_id: string; cin: number }>();

  dossierTypes = DossierTypes;
  currentStep: number = 0;
  steps: IWizardStep[] = stepsData;

  dossier: Dossier = new Dossier();
  didDossier = false;

  attachment: DossiersAttachment[] = [new DossiersAttachment(), new DossiersAttachment()];
  didAttachment = false;

  building: Building = new Building();
  didBuilding = false;

  isLoading = false;
  municipality$ = this._munisFacade.selectedMuni$;
  longitude: number | null = null;
  latitude: number | null = null;
  constructor(
    private _munisFacade: MunisFacade,
    private _dossiersService: DossiersService,
    private _buildingService: BuildingService,
    private _dossierAttachmentService: DossiersAttachmentService,
    private _toastLuncher: ToastLuncherService,
  ) {}

  ngOnInit(): void {
    this.municipality$.subscribe(muni => {
      if (muni?.latitude && muni?.longitude) {
        const { latitude, longitude } = muni;
        this.latitude = parseFloat(Number(latitude).toFixed(6));
        this.longitude = parseFloat(Number(longitude).toFixed(6));
      }
    });
  }

  import(id: string) {
    document.getElementById(id).click();
  }

  onChangeImagefile({ target: { files } }, key: string, index?: number) {
    const file: File = files[0];
    const extension = file.name.substring(file.name.lastIndexOf('.'));
    // upload valid images
    const validFileType = '.jpg , .png , .jpeg , .pdf';

    if (validFileType.toLowerCase().indexOf(extension) > -1) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        switch (key) {
          case 'building.image':
            return (this.building.image = reader.result);
          case 'attachment.attachment': {
            this.attachment[index].attachment = normalizedPDF(reader.result);
            this.attachment[index].file_name = file.name;
            return;
          }
        }
      };
    } else {
      throw new Error('حصل خطأ في تحميل الصورة...');
    }
  }

  onLocationSelect(event) {
    this.building.latitude = ol.proj.transform(event.coordinate, 'EPSG:3857', 'EPSG:4326')[0];
    this.building.longitude = ol.proj.transform(event.coordinate, 'EPSG:3857', 'EPSG:4326')[1];
  }

  setToStep(idx: number) {
    if (idx < this.steps.length) this.currentStep = idx;
  }

  submit() {
    this.isLoading = true;
    if (this.currentStep === 1 && !this.didDossier) {
      // WORKAROUND: REQUIRED FIELDS FROM BACKEND
      this.dossier.deposit_date = new Date().toLocaleDateString('sv');
      //
      this._dossiersService.post(this.dossier).subscribe(
        data => {
          this.nextStep();
          this.dossier = data;
          this.didDossier = true;
          this._toastLuncher.success({
            text: 'تم تحميل المعلومات الشخصية',
          });
          this.isLoading = false;
        },
        err => this.handleError(err),
      );
    }
    if (this.currentStep === 2 && !this.didBuilding) {
      // WORKAROUND:
      while (this.building?.latitude?.toString().length > 9) {
        this.building.latitude = parseFloat(this.building.latitude.toString().slice(0, -1));
      }
      while (this.building?.longitude?.toString().length > 9) {
        this.building.longitude = parseFloat(this.building.longitude.toString().slice(0, -5));
      }
      this.building.dossier = this.dossier.id;
      //
      this._buildingService.post(this.building).subscribe(
        data => {
          this.nextStep();
          this.building = data;
          this.didBuilding = true;
          this._toastLuncher.success({
            text: 'تم تحميل معطيات المطلب',
          });
          this.isLoading = false;
        },
        err => this.handleError(err),
      );
    }
    if (this.currentStep === 3 && !this.didAttachment) {
      this.attachment.forEach((a, index) => {
        const payload = {
          ...a,
          dossier: this.dossier.id,
          name: a.file_name,
        };
        this._dossierAttachmentService.post(payload).subscribe(
          data => {
            this.nextStep();
            this.attachment[index] = data;
            this.didAttachment = true;
            this._toastLuncher.success({
              text: 'تم تحميل الوثائق المطلوبة',
            });
            this.isLoading = false;
            this.success();
          },
          err => this.handleError(err),
        );
      });
    }
  }

  success() {
    if (this.didDossier && this.didBuilding && this.didAttachment) {
      this._toastLuncher.custom({
        title: ` ${this.dossier.unique_identifier} رقم المعرف الوحيد `,
        text: `تم تحميل ملفك بنجاح ، يرجى حفظ المعرف الوحيد الخاص بك`,
        width: '50%',
        position: 'center',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'حسنا',
        cancelButtonText: 'اغلاق',
      });
      this.onSuccess.next({ unique_id: this.dossier.unique_identifier, cin: this.dossier.cin_number });
    }
  }

  nextStep() {
    if (this.currentStep !== this.steps.length - 1) this.currentStep++;
  }

  handleError(err) {
    this.isLoading = false;
    if (err.status === 400) throw new Error('خطأ في المعلومات المدرجة، يرجى التثبت');
    if (err.status === 500) throw new Error('لا يمكن الاتصال بالمنصة، الرجاء المحاولة لاحقاً');
    throw new Error('حصل خطأ في التحميل');
  }

  previousStep() {
    if (this.currentStep !== 0) this.currentStep--;
  }
}
