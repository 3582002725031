import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as ol from 'openlayers';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { MunisFacade, UiFacade, UserFacade } from 'src/app/core/store';
import Swal from 'sweetalert2';

import { Category } from '../../../core/models/class/category';
import { CategoryService } from '../../../services/category.service';
import { ComplaintsService } from '../../../services/complaints.service';
import { complaintTypes, defaultAnimation, getItem, StorageItem } from '../../../core';
import { Complaint } from 'src/app/core/models/class/complaint';
import { NgxImageCompressService } from 'ngx-image-compress';
import { GenericCreateFeature } from 'src/app/core/models/class/GenericSingleView';
import { Municipality } from 'src/app/core/models/class/municipality';

@Component({
  selector: 'app-plaintes-new',
  templateUrl: './plaintes-new.component.html',
  styleUrls: ['./plaintes-new.component.css'],
  ...defaultAnimation,
})
export class PlaintesNewComponent extends GenericCreateFeature<Complaint> implements OnInit {
  item = new Complaint();
  constructor(
    public _munisFacade: MunisFacade,
    public complaintsService: ComplaintsService,
    public categoryService: CategoryService,
    public imageCompress: NgxImageCompressService,
    public router: Router,
    public _uiFacade: UiFacade,
    public _userFacade: UserFacade,
    public _toastLauncher: ToastLuncherService,
  ) {
    super('plaintes', complaintsService, _userFacade, _toastLauncher, imageCompress, router);
    this._munisFacade.selectedMuni$.subscribe(muni => {
      if (muni) this.onMunicipalityChange(muni);
    });
  }

  municipalityName = getItem(StorageItem.municipality_ar);
  types = complaintTypes;
  categories: Array<Category> = [];
  img: any;
  url;

  ngOnInit() {}

  onMunicipalityChange(m: Municipality): void {
    this.item.latitude = m.latitude;
    this.item.longitude = m.longitude;
    this.url = this.router.url.split('/');
    this.url = this.url[this.url.length - 1];
    this.getCategories();
    this._uiFacade.setFeatureLoaded();
  }

  getCurrentPos() {
    if (navigator) {
      navigator.geolocation.getCurrentPosition(
        pos => {
          this.item.latitude = +pos.coords.latitude;
          this.item.longitude = +pos.coords.longitude;
        },
        () => {},
      );
    }
  }

  getCategories() {
    this.categoryService.get().subscribe(data => {
      this.categories = data;
      if (this.categories?.length) {
        this.item.category = this.categories[0];
        if (this.item.category.sub_categories?.length) {
          this.item.sub_category = this.item.category.sub_categories[0].name;
        }
      }
    });
  }

  onImageLoad($event, compressedImage, ...args) {
    this.item.image = compressedImage;
  }

  submit() {
    this.item.title = new Date().getTime().toString();
    if (!this.item.address) {
      delete this.item.address;
    }
    if (!this.item.address) {
      throw new Error('الرجاء إختيار مكان المشكل ( كتابة العنوان )');
    }
    if (this.item.latitude && this.item.longitude) {
      this.item.latitude = parseFloat(this.item.latitude.toFixed(6));
      this.item.longitude = parseFloat(this.item.longitude.toFixed(6));
    }
    // @ts-ignore
    this.item.category = this.item.category.name;
    if (this.item.sub_category === null) {
      delete this.item.sub_category;
    }
    if (this.containsCorruptionWords()) {
      Swal.fire({
        title: 'تنويه هام',
        text:
          '\n' +
          '  لاحظنا استعمالك لمفردات تفيد محاولة للتبليغ عن الفساد و في هذا الاطار نريد التنويه إلى أن  التبليغ عن الفساد عبر منظومة البلدية الرقمية لا يكسب المبلغ الحماية المنصوص عليها بالقانون  الأساسي عدد10 لسنة 2017 المؤرخ في 7 مارس 2017 والمتعلق بالإبلاغ عن الفساد وحماية المبلغين. \n' +
          '\n' +
          'يمكن التبليغ عن الفساد عن طريق الرقم الأخضر للهيئة الوطنية لمكافحة الفساد   80102222',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'لست أبلغ عن  فساد / أريد ارسال التشكي على كل حال',
        cancelButtonText: 'الغاء التشكي',
      }).then(res => {
        if (res.value) {
          Swal.close();
          this.complaintsService.post(this.item).subscribe(
            data => {
              this._toastLuncher.success({ text: 'تم إضافة التشكي بنجاح', showConfirmButton: false });
              this.router.navigateByUrl(`/${getItem(StorageItem.municipality_route_name)}/plaintes`);
            },
            error => {
              throw new Error('لم نتمكن من اظافة التشكي... ');
            },
          );
        } else {
          Swal.close();
          return;
        }
      });
    } else {
      this.complaintsService.post(this.item).subscribe(
        data => {
          this._toastLuncher.success({
            showConfirmButton: false,
            text: 'تم إضافة التشكي بنجاح',
          });
          this.router.navigateByUrl(`/${getItem(StorageItem.municipality_route_name)}/plaintes/${data.id}`);
        },
        error => {
          throw new Error('لم نتمكن من اظافة التشكي... ');
        },
      );
    }
  }

  onLocationSelect(event) {
    this.item.latitude = ol.proj.transform(event.coordinate, 'EPSG:3857', 'EPSG:4326')[1];
    this.item.longitude = ol.proj.transform(event.coordinate, 'EPSG:3857', 'EPSG:4326')[0];
  }

  onCategoryChanged() {
    this.item.sub_category = this.item.category.sub_categories?.length
      ? this.item.category.sub_categories[0].name
      : null;
  }

  containsCorruptionWords() {
    let i = 0;
    let containsCorruption = false;
    const words = ['تضارب مصالح', 'رشوة', 'محسوبية', 'تحيل', 'فساد', 'corruption'];

    while (!containsCorruption && i < words.length)
      this.item.title.includes(words[i]) ||
      this.item?.solution?.includes(words[i]) ||
      this.item?.problem?.includes(words[i])
        ? (containsCorruption = true)
        : i++;

    return containsCorruption;
  }
}
