import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Complaint } from 'src/app/core/models/class/complaint';
import { SubFeature } from 'src/app/core/models/class/GenericListFeature';
import { Municipality } from 'src/app/core/models/class/municipality';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';

declare let $: any;

@Component({
  selector: 'app-plaintes-detail-home',
  templateUrl: './plaintes-detail-home.component.html',
  styleUrls: ['./plaintes-detail-home.component.css'],
})
export class PlaintesDetailHomeComponent extends SubFeature implements OnInit {
  @Input() shareFacebook: (url: string) => void;

  @Input()
  complaint = new Complaint();

  selectedLong: number = null;
  selectedLat: number = null;
  @Input() municipality: Municipality;

  constructor(public _toastLuncher: ToastLuncherService) {
    super(_toastLuncher);
  }

  ngOnInit() {
    if (this.complaint.longitude && this.complaint.latitude) {
      this.selectedLat = Math.max(this.complaint.latitude, this.complaint.longitude);
      this.selectedLong = Math.min(this.complaint.latitude, this.complaint.longitude);
    } else {
      this.selectedLat = null;
      this.selectedLong = null;
    }
  }
}
