import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

import { AgencyInfo, Ticket, TicketReq, UserTickets } from '../core/models/interface/ticket';
import { BaseService } from '../core/services/base.service';
@Injectable({
  providedIn: 'root',
})
export class TicketService extends BaseService {
  constructor(private http: HttpClient) {
    super();
    this.prefix = '/etickets';
  }

  public getAllAgencies(): Observable<Array<AgencyInfo>> {
    return this.http.get(BaseService.url + this.prefix, { headers: BaseService.getHeaders() }) as Observable<
      AgencyInfo[]
    >;
  }

  public getAgencyById(agencyId: string): Observable<AgencyInfo> {
    return this.http
      .get(BaseService.url + this.prefix + '/' + agencyId, { headers: BaseService.getHeaders() })
      .pipe(timeout(20000)) as Observable<AgencyInfo>;
  }

  public getUserTickets(agencyId: string): Observable<UserTickets> {
    const endPoint = this.prefix + '/' + 'agencies/' + agencyId + '/current_reservation';
    return this.http.get(BaseService.url + endPoint, { headers: BaseService.getHeaders() }) as Observable<UserTickets>;
  }

  public reserveTicket(ticketRequest: TicketReq, agencyId: string): Observable<Ticket> {
    const endPoint = this.prefix + '/' + 'agencies/' + agencyId + '/reservations';
    return this.http.post(BaseService.url + endPoint, ticketRequest, {
      headers: BaseService.getHeaders(),
    }) as Observable<Ticket>;
  }
  public downloadPDF(agencyId: string, id_service: number): any {
    const endPoint = this.prefix + '/' + 'agencies/' + agencyId + '/ticket/' + id_service;
    return this.http.get(BaseService.url + endPoint, { headers: BaseService.getHeaders(), responseType: 'blob' });
  }
}
