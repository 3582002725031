import { Component, Input, OnInit } from '@angular/core';
import { Dossier } from 'src/app/core/models/class//dossier';

@Component({
  selector: 'app-dossier-status',
  templateUrl: './dossier-status.component.html',
  styleUrls: ['./dossier-status.component.css'],
})
export class DossierStatusComponent implements OnInit {
  @Input() item: Dossier;

  constructor() {}

  ngOnInit(): void {}
}
