import { Injectable } from '@angular/core';
import { SwPush, SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { fromEvent, interval, merge, of, Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import Backend from 'src/app/core/backend';
@Injectable({
  providedIn: 'root',
})
export class PwaService {
  networkStatus = false;

  networkStatus$: Subscription = Subscription.EMPTY;

  constructor(private swUpdate: SwUpdate, private swPush: SwPush) {
    this.checkNetworkStatus();
  }

  /**
   *
   * @param hours  check for updates every N hours (default = 1)
   */
  activateUpdate(hours: number = 1) {
    interval(1000 * 60 * 60 * hours).subscribe(() => {
      this.swUpdate.activateUpdate();
    });
  }

  subscribeToNotifications = async () => {
    try {
      const sub = this.swPush.requestSubscription({
        serverPublicKey: Backend.VAPID_PUBLIC_KEY,
      });
    } catch (err) {
      console.error('Could not subscribe to notifications', err);
    }
  };

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(of(null), fromEvent(window, 'online'), fromEvent(window, 'offline'))
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        this.networkStatus = status;
      });
  }

  canActivate(): boolean {
    if (this.networkStatus) return true;
    throw new Error('you are offline!');
  }

  forceUpdate() {
    this.swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(evt => {
        // Reload the page to update to the latest version.
        document.location.reload();
      });
  }
}
