import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { defaultAnimation, dossierTypes } from 'src/app/core';
import { MunisFacade, UiFacade, UserFacade } from 'src/app/core/store';
import { GenericSingleViewFeature } from 'src/app/core/models/class/GenericSingleViewFeature';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { Dossier } from 'src/app/core/models/class/dossier';
import { DossiersService } from 'src/app/services/dossiers.service';
import { UpdateTypes } from 'src/app/core/models/types';

@Component({
  selector: 'app-dossiers-details',
  templateUrl: './dossiers-details.component.html',
  styleUrls: ['./dossiers-details.component.css'],
  ...defaultAnimation,
})
export class DossiersDetailsComponent extends GenericSingleViewFeature<Dossier> implements OnInit {
  constructor(
    public route: ActivatedRoute,
    public _munisFacade: MunisFacade,
    public _dossiersService: DossiersService,
    public _toastLuncher: ToastLuncherService,
    public _userFacade: UserFacade,
    public router: Router,
    public _uiFacade: UiFacade,
  ) {
    super('id', _dossiersService, route, _munisFacade, _userFacade, _toastLuncher, router);
  }

  ngOnInit(): void {}

  normalizeAfterGetItem(item: Dossier) {
    item.status = item.updates.length ? item.updates[0].status : UpdateTypes.RECEIVED;
    item.type = dossierTypes.filter(i => item.name === item.type || i.name === 'OTHER')[0].displayName;
    this._uiFacade.setFeatureLoaded();
    return item;
  }
}
