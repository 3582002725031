import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StaticText } from 'src/app/core/models/class/static-text';

import { StaticTextsService } from '../../../services/static-texts.service';

@Component({
  selector: 'app-footer-home',
  templateUrl: './footer-home.component.html',
  styleUrls: ['./footer-home.component.css', '../general-home.component.css'],
})
export class FooterHomeComponent implements OnInit {
  currRoute;
  conditions: StaticText = new StaticText();

  constructor(public route: Router, private staticTextsService: StaticTextsService) {}

  ngOnInit() {
    this.currRoute = this.route.url;
    this.staticTextsService.get('Registration conditions').subscribe(data => (this.conditions = data));
  }
}
