import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { getItem, StorageItem } from 'src/app/core';
import { News } from '../../../core/models/class/news';
import { SubFeature } from 'src/app/core/models/class/GenericListFeature';

@Component({
  selector: 'app-news-item',
  templateUrl: './news-item.component.html',
  styleUrls: ['./news-item.component.css'],
})
export class NewsItemComponent extends SubFeature implements OnInit {
  @Input() item: News;
  @Input() getCommitteeById: (id) => void;
  active = false;
  municipalityName = getItem(StorageItem.municipality_route_name);

  constructor(public _toastLuncher: ToastLuncherService) {
    super(_toastLuncher);
  }

  ngOnInit(): void {}
}
