import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hourMinute',
})
export class HourMinutePipe implements PipeTransform {
  transform(value: string): string {
    const [hours, minutes] = value.split(':');
    const hour = parseInt(hours, 10).toString().padStart(2, '0');
    const minute = parseInt(minutes, 10).toString().padStart(2, '0');

    return `${hour}:${minute}`;
  }
}
