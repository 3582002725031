import { Pipe, PipeTransform } from '@angular/core';

import { PollStatusTypes, UpdateTypes } from '../models/types';

@Pipe({
  name: 'pollIcon',
})
export class PollIconPipe implements PipeTransform {
  transform(status: PollStatusTypes): string {
    // @ts-ignore
    if (Object.values(PollStatusTypes).includes(status)) return `../../assets/images/ico/svg/${status}.svg`;
    return `../../assets/images/ico/svg/${PollStatusTypes.IN_PROGRESS}.svg`;
  }
}
