import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Category } from '../core/models/class/category';
import { GenericCRUDService } from '../core/services/generic.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryService extends GenericCRUDService<Category> {
  constructor(http: HttpClient) {
    super(http);
    this.prefix = '/categories';
  }
}
