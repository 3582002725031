import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Committee } from '../core/models/class/committee';
import { GenericCRUDServiceWithReports } from '../core/services/generic.service';

@Injectable({
  providedIn: 'root',
})
export class CommitteeService extends GenericCRUDServiceWithReports<Committee> {
  constructor(http: HttpClient) {
    super(http);
    this.prefix = '/committees';
  }
}
