import { Pipe, PipeTransform } from '@angular/core';
import { Service } from '../models/class/service';

@Pipe({
  name: 'servicesInAgency',
})
export class ServicesInAgencyPipe implements PipeTransform {
  transform(services: Service[], agencyId: string): Service[] {
    return services.filter(s => s.agency === agencyId) || [];
  }
}
