import { Component, OnInit, Input, ChangeDetectionStrategy, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { PollsService } from 'src/app/services/polls.service';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { getItem, StorageItem } from 'src/app/core';
import Backend from 'src/app/core/backend';
import { UserFacade } from 'src/app/core/store';
import { Poll } from 'src/app/core/models/class/polls';
import { PollKindTypes, PollStatusTypes } from 'src/app/core/models/types';
declare let $: any;

@Component({
  selector: 'app-single-poll',
  templateUrl: './single-poll.component.html',
  styleUrls: ['./single-poll.component.css'],
})
export class SinglePollComponent implements OnInit {
  @Input() poll: Poll;
  @Output() onSubmit = new EventEmitter();
  munNameFr = getItem(StorageItem.municipality_route_name);
  isAuthenticated = this._userFacade.isAuthenticated();
  selectedChoices: number[] = [];
  allResults = false;
  frontUrl = Backend.baseUrl;
  votedPolls: number[] = [];

  PollStatusTypes = PollStatusTypes;
  PollKindTypes = PollKindTypes;

  constructor(
    private _toastLuncherService: ToastLuncherService,
    private _userFacade: UserFacade,
    private _pollsService: PollsService,
  ) {}

  ngOnInit(): void {
    // show total_votes if poll is in progress.
    this.allResults = this.poll.status === PollStatusTypes.IN_PROGRESS;
  }

  selectOne(id: number) {
    this.selectedChoices = [id];
  }
  toggleResults() {
    this.allResults = !this.allResults;
  }
  selectMulti(id: number) {
    if (this.selectedChoices.includes(id)) {
      const index = this.selectedChoices.indexOf(id);
      this.selectedChoices.splice(index, 1);
      (<HTMLInputElement>document.getElementById(this.poll.kind + id)).checked = false;
    } else {
      this.selectedChoices.push(id);
      (<HTMLInputElement>document.getElementById(this.poll.kind + id)).checked = true;
    }
  }
  submit() {
    if (!this.isAuthenticated) return this.openLoginModal();

    this.poll.already_voted = true;
    if (this.poll.kind === PollKindTypes.MULTI_CHOICE)
      this._pollsService.voteMulti(this.poll.id, this.selectedChoices).subscribe(
        res => this.toastSucc(),
        err => {
          throw new Error('حدث خطأ أثناء تقديم التصويت');
        },
      );
    else
      this._pollsService.voteSingle(this.poll.id, this.selectedChoices[0]).subscribe(
        res => this.toastSucc(),
        err => {
          throw new Error('حدث خطأ أثناء تقديم التصويت');
        },
      );

    this.votedPolls.push(this.poll.id);
    this.selectedChoices = [];
    this.onSubmit.emit('complete');
  }
  toastSucc() {
    this._toastLuncherService.success({
      showConfirmButton: false,
      text: 'تم التصويت بنجاح !',
    });
  }
  hideModal() {
    $('#copyModal' + this.poll.id).modal('hide');
    $('body').removeClass('modal-open');
  }
  setCount(arg) {
    return typeof arg === 'string' ? ' غير متاح ' : arg !== 1 ? arg + ' مشاركين ' : 'مشارك واحد';
  }
  allowVoteOn(id: number) {
    const index = this.votedPolls.indexOf(id);
    this.votedPolls.splice(index, 1);
  }
  reset() {
    this.selectedChoices = [];
  }
  share(e) {
    $('#copyModal' + this.poll.id).modal('toggle');
    this.hideModal();
    e.stopPropagation();
  }
  openLoginModal() {
    this.hideModal();
    $('#loginModal').modal('show');
  }
}
