import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Procedure } from '../core/models/class/procedure';
import { GenericCRUDService } from '../core/services/generic.service';

@Injectable({
  providedIn: 'root',
})
export class ProceduresService extends GenericCRUDService<Procedure> {
  constructor(http: HttpClient) {
    super(http);
    this.prefix = '/procedures';
  }
}
