import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutHomeComponent } from './about-home/about-home.component';
import { HomePageGeneralComponent } from './home-page-general/home-page-general.component';
import { GeneralHomeComponent } from './general-home.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { GoogleFormComponent } from './google-form/google-form.component';
import { PreinscriptionComponent } from './preinscription/preinscription.component';
import { RegisterComponent } from './register/register.component';
import { VerificationComponent } from './verification/verification.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MunicipalitiesComponent } from './municipalities/municipalities.component';
import { LoginComponent } from '../../components/login/login.component';
import { RegisterConditionsComponent } from './registration-conditions/registration-conditions.component';
import { FrequentlyAskedComponent } from './frequently-asked/frequently-asked.component';
import { RedirectHomeService } from 'src/app/core/auth/redirect-home.service';
import { TutoEticketV2Component } from './tuto-eticket-v2/tuto-eticket-v2.component';

const routes: Routes = [
  {
    path: '',
    component: GeneralHomeComponent,
    children: [
      {
        path: '',
        canActivate: [RedirectHomeService],
        pathMatch: 'full',
        component: HomePageGeneralComponent,
      },
      {
        path: 'home',
        component: HomePageGeneralComponent,
      },

      {
        path: 'municipalities',
        component: MunicipalitiesComponent,
      },
      {
        path: 'join-us',
        component: GoogleFormComponent,
      },
      {
        path: 'preinscription',
        component: PreinscriptionComponent,
      },
      {
        path: 'register',
        component: RegisterComponent,
      },
      {
        path: 'registration-conditions',
        component: RegisterConditionsComponent,
      },
      {
        path: 'verification',
        component: VerificationComponent,
      },
      {
        path: 'reset',
        component: ResetPasswordComponent,
      },
      {
        path: 'faq',
        component: FrequentlyAskedComponent,
      },
      {
        path: 'about',
        component: AboutHomeComponent,
      },
      {
        path: 'tuto-eticket-v2',
        component: TutoEticketV2Component,
      },
      { path: '404', component: NotFoundPageComponent },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GeneralHomeRoutingModule {}
