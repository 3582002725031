import { Component, OnInit } from '@angular/core';
import { ProceduresService } from '../../../services/procedures.service';
import { Procedure } from '../../../core/models/class/procedure';
import Backend from 'src/app/core/backend';
import { defaultAnimation } from 'src/app/core';
import { SelectedItemFeature } from 'src/app/core/models/class/GenericListFeature';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { MunisFacade, UiFacade, UserFacade } from 'src/app/core/store';
import { Municipality } from 'src/app/core/models/class/municipality';
declare var $: any;

@Component({
  selector: 'app-procedures-list',
  templateUrl: './procedures-list.component.html',
  styleUrls: ['./procedures-list.component.css'],
  ...defaultAnimation,
})
export class ProceduresListComponent extends SelectedItemFeature<Procedure> implements OnInit {
  selectedType: string;
  filteredItems: Procedure[];
  services: Set<string> = new Set<string>([]);
  frontUrl = Backend.frontUrl;
  municipalityName;

  constructor(
    public _munisFacade: MunisFacade,
    public proceduresService: ProceduresService,
    public _toastLuncher: ToastLuncherService,
    public _userFacade: UserFacade,
    public _uiFacade: UiFacade,
  ) {
    super(proceduresService, _userFacade, _munisFacade, _toastLuncher);
  }

  ngOnInit() {}

  onMunicipalityChange(municipality: Municipality) {
    this.municipalityName = municipality.name_fr;
    this.selectedItem = null;
    this.filteredItems = [];
    this.getPages();
  }

  selectItem(item: Procedure) {
    this.selectedItem = item;
    $('#procedureModal').modal('show');
  }

  selectType(type) {
    if (this.services.has(type)) {
      return (this.filteredItems = this.items.filter(item => item.type === type));
    }
    this.filteredItems = this.items;
  }

  postSettingData() {
    this.services = new Set([...this.items.map(i => i.type)]);
    this.filteredItems = this.items;
    this._uiFacade.setFeatureLoaded();
  }
}
