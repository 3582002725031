import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { MunisFacade, UiFacade, UserFacade } from 'src/app/core/store';

import { CommitteeService } from '../../../services/committee.service';
import { defaultAnimation } from '../../../core';
import { Committee } from 'src/app/core/models/class/committee';
import { Report } from 'src/app/core/models/class/report';
import { SelectedItemFeature } from 'src/app/core/models/class/GenericListFeature';
import { Municipality } from 'src/app/core/models/class/municipality';

declare let $: any;

@Component({
  selector: 'app-comites',
  templateUrl: './comites.component.html',
  styleUrls: ['./comites.component.css'],
  ...defaultAnimation,
})
export class ComitesComponent extends SelectedItemFeature<Committee> implements OnInit {
  reports: Array<Report> = new Array<Report>();
  selectedReport: Report;
  municipalityName;
  toggler: 'reports' | 'committee' = 'committee';

  constructor(
    public _munisFacade: MunisFacade,
    public committeeService: CommitteeService,
    public route: ActivatedRoute,
    public toast: ToastLuncherService,
    public _userFacade: UserFacade,
    public _toastLuncher: ToastLuncherService,
    public _uiFacade: UiFacade,
  ) {
    super(committeeService, _userFacade, _munisFacade, _toastLuncher);
  }

  ngOnInit() {}

  onMunicipalityChange(municipality: Municipality) {
    this.municipalityName = municipality.name_fr;
    this.items = [];
    this.reports = [];
    this.getPages();
  }

  postSettingData() {
    this.getReports();
    this._uiFacade.setFeatureLoaded();
  }

  getReports() {
    this.committeeService.getReports().subscribe(reports => {
      this.reports = reports;
      this.items.forEach(com => {
        com.reports = reports.filter(rep => rep.committee_id === com.id);
      });
      this.reports.forEach(report => {
        report.committee = this.items.find(com => com.id === report.committee_id)?.title;
      });

      // if not existing, add a default committee
      if (!this.items.find(item => item.title === 'المجلس البلدي')) {
        const com = new Committee();
        com.title = 'المجلس البلدي';
        com.body =
          'يتصرف المجلس البلدي في الشؤون البلدية، ويساهم في نطاق المخطط الوطني للتنمية في النهوض بالمنطقة اقتصاديا واجتماعيا وثقافيا، ويدرس ميزانية البلدية ويوافق عليها، ويضبط في حدود المداخيل البلدية والإمكانيات الموضوعة تحت تصرفها برنامج تجهيز البلدية، ويضبط مختلف الأعمال التي يتعين القيام بها للمساعدة على تنمية المنطقة وفقا للمخطط الوطني للتنمية. من جهة أخرى يدلي المجلس البلدي برأيه في جميع المسائل ذات الصبغة المحلية خصوصا المتعلق منها بالميادين الإقتصادية والإجتماعية والثقافية وفي كل الحالات التي تستوجبها القوانين والتراتيب أو كلما طلبت سلطة الإشراف ذلك، ويستشار مسبقا في كل مشروع يزمع إنجازه في منطقة بلدية من طرف الدولة أو أي جماعة أخرى أو مؤسسة عمومية.';
        com.id = -1;
        // attach to it all reports with a NULL commitee_id
        com.reports = reports.filter(rep => rep.committee_id === null);
        this.items.unshift(com);
      }
    });
  }

  selectItem(item: Committee) {
    this.selectedItem = item;
  }

  selectFromModal(item) {
    this.hideModal();
    this.toggler = 'reports';
    this.selectedReport = item;
  }

  selectReportItem(item) {
    this.selectedReport = item;
  }

  toggleCommittees(s: 'reports' | 'committee') {
    this.toggler = s;
  }
}
