import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-intro',
  templateUrl: './video-intro.component.html',
  styleUrls: ['./video-intro.component.css', '../general-home.component.css'],
})
export class VideoIntroComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
