import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dossier } from '../core/models/class/dossier';
import { GenericCRUDServiceWithFollowItem } from '../core/services/generic.service';

@Injectable({
  providedIn: 'root',
})
export class DossiersService extends GenericCRUDServiceWithFollowItem<Dossier> {
  constructor(http: HttpClient) {
    super(http);
    this.prefix = '/dossiers';
  }
}
