import { ActivatedRoute, Router } from '@angular/router';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { areValidImages } from '../../lib/functions';
import { GenericCRUDService } from '../../services/generic.service';
import { UserFacade } from '../../store';
import { IonMunicipalityChange } from '../interface/IonMunicipalityChange';
import { MainFeature } from './GenericListFeature';
import { Municipality } from './municipality';

export class GenericSingleView<T> extends MainFeature {
  isLoading: boolean = true;
  item: T;
  item_id = '';

  constructor(
    public item_param_name: string,
    public _service: GenericCRUDService<T>,
    _userFacade: UserFacade,
    _toastLauncher: ToastLuncherService,
    public route: ActivatedRoute,
  ) {
    super(_userFacade, _toastLauncher);
  }

  public normalizeAfterGetItem(item: T): T {
    return item;
  }

  getItem(): void {
    this.route.params.subscribe(params => {
      this.item_id = params[this.item_param_name];
      this._service.getById(this.item_id.toString()).subscribe(
        data => {
          this.item = this.normalizeAfterGetItem(data);
          this.isLoading = false;
        },
        error => {
          throw new Error('لم نتمكن من العثور على المعلومات المطلوبة');
        },
      );
    });
  }
}

export class GenericCreateFeature<T> extends MainFeature implements IonMunicipalityChange {
  isLoading: boolean = false;
  item: T = null;

  hasImage = false;
  isFileSizePassed = true;

  constructor(
    public featureName: string,
    public _service: GenericCRUDService<T>,
    public _userFacade: UserFacade,
    public _toastLauncher: ToastLuncherService,
    public imageCompress: NgxImageCompressService,
    public router: Router,
  ) {
    super(_userFacade, _toastLauncher);
  }

  onMunicipalityChange(m: Municipality) {
    undefined;
  }

  protected formIsValid(): boolean {
    return true;
  }

  protected normalizeBeforeSubmit(item: T): T {
    return item;
  }

  submit() {
    if (!this.formIsValid()) return;
    this.isLoading = true;
    this._service.post(this.normalizeBeforeSubmit(this.item)).subscribe(
      data => {
        this.isLoading = false;
        this._toastLuncher.success({ text: 'تمت الإضافة بنجاح !' });
        this.router.navigate([`/pages${this.featureName}/liste`]);
      },
      error => {
        this.isLoading = false;
        if (error.status === 400) throw new Error('الرجاء التثبت من المعطيات المدرجة');
        if (error.status === 409) throw new Error('يوجد ملف اخر يحمل نفس المعطيات');
        if (error.status === 500) throw new Error('لم نتمكن من الاتصال بالمنصة، ارجاء المحاولة لاحقاً.');
        else throw new Error('لم نتمكن من الإضافة');
      },
    );
  }

  onChangeImagefile(fileInput, ...args) {
    const fileError = areValidImages(fileInput.target.files);
    if (fileError.error) throw new Error(fileError.errorMessage);
    this.hasImage = false;
    const reader2 = new FileReader();
    let src;
    reader2.onload = e => {
      src = reader2.result;
      this.imageCompress
        .compressFile(src, 75, 75) // 75% ratio, 75% quality
        .then(compressedImage => {
          this.onImageLoad(e, compressedImage, ...args);
        });
    };
    if (fileInput.target.files[0].size < 5300000 * 2) {
      this.isFileSizePassed = true;
      reader2.readAsDataURL(fileInput.target.files[0]);
    } else {
      this.isFileSizePassed = false;
      throw new Error('الرجاء ادخال صورة لا تتجاوز 10MB');
    }
  }

  import() {
    document.getElementById('file_to_upload').click();
  }

  onImageLoad($event, compressedImage, ...args) {
    undefined;
  }
}
