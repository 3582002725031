import { IfeatureItem } from '../interface/IfeatureItem';

export class Report implements IfeatureItem {
  id: number;
  attachment: string;
  committee_id: number;
  committee?: string; // added on front
  created_at: Date;
  date: string;
  hits: number;
  municipality_id: number;
  title: string;
}
