import { Component, Input, OnInit } from '@angular/core';

import { Comment } from '../../../core/models/class/comment';

@Component({
  selector: 'app-forum-detail-home',
  templateUrl: './forum-detail-home.component.html',
  styleUrls: ['./forum-detail-home.component.css'],
})
export class ForumDetailHomeComponent implements OnInit {
  @Input() comment = new Comment();
  constructor() {}
  ngOnInit() {}
}
