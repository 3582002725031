import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { formatMunicipalityName } from 'src/app/core';
declare let $: any;

@Component({
  selector: 'app-copy-comment',
  templateUrl: './copy-comment.component.html',
  styleUrls: ['./copy-comment.component.css'],
})
export class CopyCommentComponent implements OnInit {
  @Input() url: string;
  @Input() id: string | number = '';

  copied = false;
  constructor() {}

  ngOnInit(): void {}

  share() {
    window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + formatMunicipalityName(this.url),
      '_blank',
      'location=yes,height=570,width=520,scrollbars=yes,status=yes',
    );
  }
  copy() {
    navigator.clipboard.writeText(this.url);
    this.copied = true;
  }
  toggleModal() {
    $('#copyModal' + this.id).modal('toggle');
  }
}
