import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sponsors-section',
  templateUrl: './sponsors-section.component.html',
  styleUrls: ['./sponsors-section.component.css'],
})
export class SponsorsSectionComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
