import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { getItem, StorageItem } from 'src/app/core';
import { UiFacade, UserFacade } from 'src/app/core/store';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { SubjectAccessRequest } from '../../../core/models/class/subject-access-request';
import { SubjectAccessRequestService } from '../../../services/subject-access-request.service';
import { User } from 'src/app/core/models/class/user';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-accesinfo-new',
  templateUrl: './accesinfo-new.component.html',
  styleUrls: ['./accesinfo-new.component.css'],
})
export class AccesinfoNewComponent implements OnInit {
  constructor(
    private _toastLuncher: ToastLuncherService,
    private subjectAccessRequestService: SubjectAccessRequestService,
    private _uiFacade: UiFacade,
    private _userFacade: UserFacade,
    private userService: UserService,
    private router: Router,
  ) {}

  isPrivate = true;
  subjectAccessRequest: SubjectAccessRequest = new SubjectAccessRequest();
  municipalityName = getItem(StorageItem.municipality_ar);
  url;
  step = 1;
  user = new User();

  ngOnInit() {
    this.subjectAccessRequest.electronic_document = true;
    this.url = this.router.url.split('/');
    this.url = this.url[this.url.length - 1];
    this._uiFacade.setFeatureLoaded();
    this._userFacade.loggedInUser$.subscribe(user => {
      if (user) {
        this.user = user;
        this.user.profile_picture = '';
        this.subjectAccessRequest.user_address = this.user.address;
        this.subjectAccessRequest.user_email = this.user.email;
      }
    });
  }

  nextStep() {
    this.step = 2;
  }
  valid(): boolean {
    if (!this.user.address) {
      throw new Error('الرجاء ملء كل الفراغات الاجبارية');
    }

    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.user.email && !re.test(String(this.user.email).toLowerCase())) {
      throw new Error('الرجاء التثبت من أن البريد الالكتروني صحيح');
    }

    return true;
  }
  submit() {
    this.subjectAccessRequest.is_public = !this.isPrivate;
    if (!this.subjectAccessRequest.user_email) throw new Error('الرجاء ادخال البريد الالكتروني');
    if (!this.subjectAccessRequest.user_address) throw new Error('الرجاء ادخال عنوان السكن');

    if (!this.subjectAccessRequest.document) {
      throw new Error('الرجاء ادخال الوثيقة المراد النفاذ اليها');
    } else if (!this.subjectAccessRequest.structure) {
      throw new Error('الرجاء ادخال الهيكل الاداري المعني');
    } else {
      this.user.address = this.subjectAccessRequest.user_address;
      this.user.email = this.subjectAccessRequest.user_email;

      if (this.valid()) this._uiFacade.setFeatureLoading();
      this.userService.editUser(this.user).subscribe(
        data => {
          this.subjectAccessRequestService.post(this.subjectAccessRequest).subscribe(
            data => {
              this._toastLuncher.success({
                showConfirmButton: false,
                text: 'تم إضافة المطلب بنجاح',
              });
              this.router.navigateByUrl(`/${getItem(StorageItem.municipality_route_name)}/accesinfo/${data.id}`);
            },
            error => {
              throw new Error('الرجاء التثبت من المعطيات المطلوبة');
            },
          );
          this._uiFacade.setFeatureLoaded();
        },
        error => {
          this._uiFacade.setFeatureLoaded();
          throw new Error('لا يمكن الاتصال بالمنصة، الرجاء المحاولة لاحقاً');
        },
      );
    }
  }
}
