import { Component, OnInit } from '@angular/core';
import { getItem, getStates, isValidPhoneNumber, mapMunicipalities, StorageItem } from 'src/app/core';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { MunisFacade, UiFacade, UserFacade } from 'src/app/core/store';

import { User } from '../../../core/models/class/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';
import { Municipality, MunicipalitySummary } from 'src/app/core/models/class/municipality';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css'],
})
export class MyProfileComponent implements OnInit {
  fieldTextType: boolean;
  states: Set<string> = null;
  form;
  mappedMunicipalities: Map<string, Array<Municipality>> = null;
  constructor(
    private _toastLuncher: ToastLuncherService,
    private userService: UserService,
    private _userFacade: UserFacade,
    private _uiFacade: UiFacade,
    public _munisFacade: MunisFacade,
    private _formBuilder: UntypedFormBuilder,
  ) {
    this.form = this._formBuilder.group({
      first_name: [null, [Validators.required, Validators.maxLength(25)]],
      last_name: [null, [Validators.required, Validators.maxLength(25)]],
      birth_date: ['1999-01-01', [Validators.required]],
      phone_number: [
        null,
        [Validators.required, Validators.maxLength(8), Validators.minLength(8), Validators.pattern('^[0-9]+$')],
      ],
      email: [null, [Validators.email]],
      municipality_id: [null, [Validators.required, Validators.pattern('^[0-9]+$')]],
      password: [null, [Validators.required, Validators.maxLength(25), Validators.minLength(5)]],
      password_verification: [null, [Validators.required, Validators.maxLength(25)]],
      consent: [false, Validators.required],

      city_name: [null, Validators.required],
    });
  }

  user = new User();
  oldPass: string;
  newPass: string;
  newPassConfirm: string;
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  profile: any;
  activeMunis = new Array<Municipality>();
  selectedMuni: Municipality;

  ngOnInit() {
    this.getProfile();
    this.fieldTextType = false;
    this._munisFacade.munisList$.subscribe(munis => {
      if (munis) {
        this.activeMunis = munis.filter(muni => muni.is_active);
        this.states = getStates(munis);
        this.mappedMunicipalities = mapMunicipalities(munis);
      }
    });
  }
  isValidFormKey(key: string) {
    const formKey = this.form.controls[key];
    return formKey.valid && (formKey?.dirty || formKey?.touched);
  }
  getProfile() {
    this._userFacade.loggedInUser$.subscribe(user => {
      if (user) {
        this.user = user;
        this.user.profile_picture = '';
        this._uiFacade.setFeatureLoaded();
      }
    });
  }

  submit() {
    if (this.form.value.municipality_id) this.user.registration_municipality_id = this.form.value.municipality_id;

    if (this.valid()) this._uiFacade.setFeatureLoading();
    this.userService.editUser(this.user).subscribe(
      data => {
        this._toastLuncher.success({
          showConfirmButton: false,
          text: 'تمت العملية بنجاح!',
        });
        this._uiFacade.setFeatureLoaded();
      },
      error => {
        this._uiFacade.setFeatureLoaded();
        throw new Error('لا يمكن الاتصال بالمنصة، الرجاء المحاولة لاحقاً');
      },
    );
  }

  changePass() {
    if (!this.newPass || this.newPass !== this.newPassConfirm) {
      throw new Error('الرجاء التثبت من مطابقة كلمة السر الجديدة');
    }
    this.isLoading$.next(true);
    this.userService.changePassword(this.newPass, this.oldPass).subscribe(
      data => {
        this.isLoading$.next(false);
        this._toastLuncher.success({
          showConfirmButton: false,
          text: 'تم تغيير كلمة السر بنجاح',
        });
      },
      error => {
        this.isLoading$.next(false);
        if (error.status === 401) throw new Error('خطأ في كلمة السر الحالية');
        else throw new Error('لا يمكن الاتصال بالمنصة، الرجاء المحاولة لاحقاً');
      },
    );
  }

  valid(): boolean {
    if (!this.user.birth_date) this.user.birth_date = null;
    if (!this.user.first_name || !this.user.last_name || !this.user.phone_number) {
      throw new Error('الرجاء ملء كل الفراغات الاجبارية');
    }
    if (!this.user.phone_number || !isValidPhoneNumber(this.user.phone_number)) {
      throw new Error('الرجاء التثبت من أن رقم الهاتف متكون من 8 أرقام');
    }
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.user.email && !re.test(String(this.user.email).toLowerCase())) {
      throw new Error('الرجاء التثبت من أن البريد الالكتروني صحيح');
    }
    if (
      this.user.facebook_account &&
      !this.user.facebook_account.includes('fb.com/') &&
      !this.user.facebook_account.includes('facebook.com/')
    ) {
      throw new Error('الرجاء التثبت من أن رابط الفسبوك صحيح');
    }
    return true;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}
