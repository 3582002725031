import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AngularOpenlayersModule } from 'ngx-openlayers';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// import { PdfViewerModule } from 'ng2-pdf-viewer';

import { AuthGuardService } from '../../core/auth/auth-guard.service';
import { OurCommonModule } from '../../core/OurCommon.module';

import { ImagesCarouselComponent } from 'src/app/components/images-carousel/images-carousel.component';
import { PollCardComponent } from 'src/app/components/poll-card/poll-card.component';
import { SinglePollComponent } from 'src/app/components/single-poll/single-poll.component';
import { SharedFeaturesComponent } from './shared-features.component';
import { AboutComponent } from '../shared-features/about/about.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ComitesComponent } from './comites/comites.component';
import { ForumDetailsComponent } from './forum-details/forum-details.component';
import { ForumNewComponent } from './forum-new/forum-new.component';
import { ForumComponent } from './forum/forum.component';
import { MenuMobileComponent } from './menu-mobile/menu-mobile.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { NewsDetailsComponent } from './news-details/news-details.component';
import { NewsListComponent } from './news-list/news-list.component';
import { PollItemComponent } from './poll-details/poll-details.component';
import { PollsListComponent } from './polls-list/polls.component';
import { ComiteReportDetailsComponent } from './comite-report-details/comite-report-details.component';
import { NewsItemComponent } from './news-item/news-item.component';
import { CalendarDetailsComponent } from './calendar-details/calendar-details.component';
import { HourMinutePipe } from 'src/app/core/pipes/HourMinute.pipe';
import { PollIconPipe } from 'src/app/core/pipes/poll-icon.pipe';
const routes: Routes = [
  {
    path: '',
    component: SharedFeaturesComponent,
    children: [
      {
        path: 'menu',
        component: MenuMobileComponent,
      },
      {
        path: 'nouveau-post',
        component: ForumNewComponent,
      },
      {
        path: 'about',
        component: AboutComponent,
      },
      {
        path: 'calendrier',
        component: CalendarComponent,
      },
      {
        path: 'calendrier/:id',
        component: CalendarDetailsComponent,
      },
      {
        path: 'profile',
        canActivate: [AuthGuardService],
        component: MyProfileComponent,
      },
      {
        path: 'committees',
        component: ComitesComponent,
      },
      {
        path: 'committees/:id',
        component: ComiteReportDetailsComponent,
      },
      {
        path: 'news',
        component: NewsListComponent,
      },
      {
        path: 'news/:id',
        component: NewsDetailsComponent,
      },
      {
        path: 'forum',
        component: ForumComponent,
      },
      {
        path: 'forum/:id',
        component: ForumDetailsComponent,
      },
      {
        path: 'polls',
        component: PollsListComponent,
      },
      {
        path: 'polls/:id',
        component: PollItemComponent,
        // canActivate: [AuthGuard]
      },
    ],
  },
];

@NgModule({
  declarations: [
    SharedFeaturesComponent,
    MenuMobileComponent,
    ForumNewComponent,
    AboutComponent,
    CalendarComponent,
    MyProfileComponent,
    ComitesComponent,
    HourMinutePipe,
    NewsListComponent,
    NewsDetailsComponent,
    ForumComponent,
    ForumDetailsComponent,
    PollsListComponent,
    PollItemComponent,
    ImagesCarouselComponent,
    PollCardComponent,
    SinglePollComponent,
    ComiteReportDetailsComponent,
    NewsItemComponent,
    CalendarDetailsComponent,
    PollIconPipe,
  ],
  imports: [
    AngularOpenlayersModule,
    InfiniteScrollModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    RouterModule,
    OurCommonModule,
  ],
  bootstrap: [SharedFeaturesComponent],
  providers: [AuthGuardService],
})
export class SharedFeaturesModule {}
