import { IfeatureItem } from '../interface/IfeatureItem';
import { Report } from './report';

export class Committee implements IfeatureItem {
  municipality_id: number;
  id: number;
  title: string;
  body: string;
  reports: Report[] = [];
}
