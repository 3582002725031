import { Pipe, PipeTransform } from '@angular/core';

import { CommentTypes, UpdateTypes } from '../models/types';

@Pipe({
  name: 'commentStatus',
})
export class CommentStatusPipe implements PipeTransform {
  transform(value: string, type: string): string {
    switch (type) {
      // defaults to type REMARK
      // defaults to type RECEIVED
      case CommentTypes.QUESTION: {
        switch (value) {
          case UpdateTypes.NOT_CLEAR:
            return 'السؤال غير واضح';
          case UpdateTypes.INVALID:
            return 'السؤال خارج نطاق عمل البلدية';
          case UpdateTypes.PROCESSING:
            return 'تم التسجيل للعرض على المجلس البلدي';
          case UpdateTypes.REJECTED:
            return 'تعذر الإجابة';
          case UpdateTypes.ACCEPTED:
            return 'تم الإجابة على السؤال';
          default:
            return 'السؤال مفتوح للإجابة';
        }
      }
      case CommentTypes.SUGGESTION: {
        switch (value) {
          case UpdateTypes.NOT_CLEAR:
            return 'مقترح غير واضح';
          case UpdateTypes.INVALID:
            return 'المقترح خارج نطاق عمل البلدية';
          case UpdateTypes.PROCESSING:
            return 'تم التسجيل للعرض على المجلس البلدي';
          case UpdateTypes.REJECTED:
            return 'تعذر على البلدية تبني المقترح';
          case UpdateTypes.ACCEPTED:
            return 'تم تبني المقترح';
          default:
            return 'مقترح مفتوح للنقاش';
        }
      }
      default: {
        switch (value) {
          case UpdateTypes.NOT_CLEAR:
            return 'الملاحظة غير واضحة';
          case UpdateTypes.INVALID:
            return 'الملاحظة خارج نطاق عمل البلدية';
          case UpdateTypes.PROCESSING:
            return 'تم التسجيل للعرض على المجلس البلدي';
          case UpdateTypes.REJECTED:
            return 'تم نفي الملاحظة';
          case UpdateTypes.ACCEPTED:
            return 'تم التداول في الملاحظة';
          default:
            return 'الملاحظة مفتوحة للنقاش';
        }
      }
    }
  }
}
