import { Component, Input, OnInit } from '@angular/core';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';

import { User } from '../../../core/models/class/user';
import { EventsService } from '../../../services/events.service';
import { Event } from 'src/app/core/models/class/event';
import { Municipality } from 'src/app/core/models/class/municipality';
import { SubFeature } from 'src/app/core/models/class/GenericListFeature';

@Component({
  selector: 'app-event-detail-home',
  templateUrl: './event-detail-home.component.html',
  styleUrls: ['./event-detail-home.component.css'],
})
export class EventDetailHomeComponent extends SubFeature implements OnInit {
  @Input() event: Event;
  @Input() user: User;
  @Input() municipality: Municipality;

  constructor(public eventsService: EventsService, public _toastLuncher: ToastLuncherService) {
    super(_toastLuncher);
  }

  ngOnInit() {}

  participateEvent() {
    this.eventsService.setParticipation(this.event.id).subscribe(() => {
      this._toastLuncher.success({
        showConfirmButton: false,
        text: 'أنت الآن أحد المشاركين في هذا الحدث',
      });
      this.event.user_participating = true;
      this.event.number_of_participants++;
    });
  }

  unparticipateEvent() {
    this.eventsService.removeParticipation(this.event.id).subscribe(() => {
      this._toastLuncher.success({
        showConfirmButton: false,
        text: 'تم حذف المشاركة',
      });
      this.event.user_participating = false;
      this.event.number_of_participants--;
    });
  }

  interestEvent() {
    this.eventsService.setInterest(this.event.id).subscribe(() => {
      this._toastLuncher.success({
        showConfirmButton: false,
        text: 'ستتلقى الآن إشعارات حول هذا الحدث',
      });
      this.event.user_interested = true;
      this.event.number_of_interested_citizen++;
    });
  }

  disinterestEvent() {
    this.eventsService.removeInterest(this.event.id).subscribe(() => {
      this._toastLuncher.success({
        showConfirmButton: false,
        text: 'تم حذف اهتمامك بهذا الحدث',
      });
      this.event.user_interested = false;
      this.event.number_of_interested_citizen--;
    });
  }
}
