import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { getItem, setItem, StorageItem } from 'src/app/core';
import { UserService } from 'src/app/core/services/user.service';
import { MunisFacade } from 'src/app/core/store';

import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { MunicipalityService } from '../../../services/municipality.service';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css'],
})
export class VerificationComponent implements OnInit {
  constructor(
    private toast: ToastLuncherService,
    public route: Router,
    private userService: UserService,
    private jwtHelper: JwtHelperService,
    private utilsService: MunicipalityService,
  ) {}

  seconds = 15;
  code: string;
  phone: string;
  loading = false;
  mun: string;

  ngOnInit() {
    this.phone = getItem(StorageItem.phone_number);
    this.mun = MunisFacade.getMunicipalityId();
  }

  getMunicipality() {
    this.utilsService.getById(this.mun).subscribe(data => {
      const route = data.route_name;
      window.location.href = '/' + route;
    });
  }

  verify() {
    const code = this.code;
    if (!code || code.length !== 6) {
      this.toast.info({ text: 'الرجاء التثبت من رمز التأكيد', showConfirmButton: false });
      return;
    }
    this.loading = true;
    const base = this;
    const b = setInterval(() => {
      base.seconds--;
      if (base.seconds === 0) {
        clearInterval(b);
      }
    }, 1000);
    let success = false;

    const interval = setInterval(() => {
      if (base.seconds <= 0) {
        this.loading = false;
        this.toast.info({ text: 'الرجاء التثبت من رمز التأكيد', showConfirmButton: false });
        base.seconds = 15;
        clearInterval(interval);
      } else {
        this.userService.verifyOTP(this.phone, code ? code : '999999').subscribe(
          data => {
            setItem(StorageItem.token, data.access);
            setItem(StorageItem.refresh_token, data.refresh);
            setItem(StorageItem.connected_user_id, this.jwtHelper.decodeToken(data.access).user_id);
            setItem(StorageItem.first_login, 'true');
            this.loading = false;
            // this.route.navigate(['/']);
            success = true;
            clearInterval(interval);
            this.loading = false;
            this.getMunicipality();
          },
          error => {
            throw new Error(error);
          },
        );
      }
    }, 3000);
  }
}
