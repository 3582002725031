import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { MunisFacade, UiFacade, UserFacade } from 'src/app/core/store';
import { ComplaintsService } from '../../../services/complaints.service';
import { defaultAnimation, getItem, StorageItem } from '../../../core';
import { Complaint } from 'src/app/core/models/class/complaint';
import { UpdateTypes } from 'src/app/core/models/types';
import { OpsUpdateFeature } from 'src/app/core/models/class/GenericListFeature';
import { Municipality } from 'src/app/core/models/class/municipality';

declare let $: any;
@Component({
  selector: 'app-plaintes-list',
  templateUrl: './plaintes-list.component.html',
  styleUrls: ['./plaintes-list.component.css'],
  ...defaultAnimation,
})
export class PlaintesListComponent extends OpsUpdateFeature<Complaint> implements OnInit {
  imgWidth = 150;
  selectedLong: number = null;
  selectedLat: number = null;
  municipalityName = getItem(StorageItem.municipality_route_name);
  newComplaint = false;

  constructor(
    public _munisFacade: MunisFacade,
    public _complaintsService: ComplaintsService,
    public route: ActivatedRoute,
    public toast: ToastLuncherService,
    public _uiFacade: UiFacade,
    public _userFacade: UserFacade,
    public _toastLuncher: ToastLuncherService,
  ) {
    super(_complaintsService, _userFacade, _munisFacade, _toastLuncher);
    this._uiFacade.setFeatureLoading();
  }

  ngOnInit() {}

  onMunicipalityChange(municipality: Municipality) {
    this.municipalityName = municipality.route_name;
    this.filteredItems = [];
    this.items = [];
    this.getPages();
  }

  @HostListener('window:load', ['$event'])
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 740) this.imgWidth = window.innerWidth / 1.5;
    if (window.innerWidth >= 740) this.imgWidth = 150;
  }

  selectStatus(status) {
    if (status === null) {
      this.filteredItems = this.items;
    } else {
      this.filteredItems = this.items.filter(item => item.status === status);
    }
  }

  postSettingData() {
    if (this.items?.length && this.paging === 1) {
      this.selectItem(this.items[0]);
    }
    this.filteredItems = this.items;
    this.setFilters();
    this._uiFacade.setFeatureLoaded();
  }

  preSettingData(data: Complaint[]): Complaint[] {
    return data
      .sort((item1, item2) => (item1.created_at > item2.created_at ? -1 : 1))
      .map(item => {
        item.created_at;
        item.status = item.updates.length ? item.updates[0].status : UpdateTypes.RECEIVED;
        return item;
      });
  }

  selectItem(item) {
    if (item === this.selectedItem) {
      this.selectedItem = null;
    } else {
      this.selectedItem = item;
      if (this.selectedItem.longitude && this.selectedItem.latitude) {
        this.selectedLat = Math.max(this.selectedItem.latitude, this.selectedItem.longitude);
        this.selectedLong = Math.min(this.selectedItem.latitude, this.selectedItem.longitude);
      } else {
        this.selectedLat = null;
        this.selectedLong = null;
      }
    }
  }

  /**
   *
   * @param category name
   * @returns proper icon name for the provided category
   */
  getIconName(category: string) {
    const categoryIcons = [
      ['النظافة ومقاومة الحشرات', 'fas fa-bug'],
      ['الصحّة والبيئة', 'fas fa-shield-virus'],
      ['التراتيب العمرانية', 'fas fa-house-damage'],
      ['المناطق الخضراء والبستنة', 'fas fa-leaf'],
      ['المخالفات الاقتصادية', 'fas fa-money-check-alt'],
      ['الطرقات', 'fas fa-road'],
      ['الإنارة العمومية', 'fas fa-lightbulb'],
    ];
    let i = 0;
    let found = false;

    while (!found && i < categoryIcons.length) {
      if (categoryIcons[i][0] === category) found = true;
      else i++;
    }

    if (found) return categoryIcons[i][1];
    return 'fas fa-exclamation-circle';
  }
}
