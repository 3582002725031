import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';

import { Comment, CommentReq } from '../../core/models/class/comment';
import { ForumService } from '../../services/forum.service';
import { getItem, StorageItem, commentTypeToArabic, getCommentTypeIcon } from '../../core';
import { UserFacade } from 'src/app/core/store';
import Backend from 'src/app/core/backend';
import { IOpsfeatureItem } from 'src/app/core/models/interface/IfeatureItem';

declare let $: any;
@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css'],
})
export class CommentComponent implements OnInit {
  @Input() comment: Comment;
  @Input() index = 100;

  constructor(
    private _userFacade: UserFacade,
    private forumService: ForumService,
    private toast: ToastLuncherService,
  ) {}

  frontUrl = Backend.frontUrl;
  isAuthenticated = this._userFacade.isAuthenticated();
  munNameFr = getItem(StorageItem.municipality_route_name);

  filtredForum = new Array<Comment>();
  commentToAdd = new CommentReq();
  selected = -1;
  isLoading = false;
  newComment = false;
  subCommentsIsOpen = false;
  screenWidth: number = window.innerWidth;
  //  commentsAndUpdates: any[] = [];

  ngOnInit(): void {
    if (this.comment?.sub_comments?.length) this.comment.sub_comments.reverse();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
  }

  addComment(e: Event, item) {
    if (!this.commentToAdd.body?.length) {
      return;
    }
    this.commentToAdd.parent_comment_id = item.id;
    this.commentToAdd.title = 'subcomment';
    this.forumService.post(this.commentToAdd as unknown as Comment).subscribe(data => {
      this.comment.sub_comments.push(data);
      this.commentToAdd.body = '';
      this.toast.success({ text: 'تم إضافة التعليق بنجاح', showConfirmButton: false });
    });
    e.stopPropagation();
  }

  like(e: Event, i: number) {
    if (this.comment.user_vote !== 1) {
      this.forumService.like(this.comment.id).subscribe(() => {
        this.comment.score++;
        this.comment.user_vote = 1;
      });
    } else {
      this.forumService.removeLike(this.comment.id).subscribe(() => {
        this.comment.score--;
        this.comment.user_vote = 0;
      });
    }
    e.stopPropagation();
  }

  upVote(e: Event, i: number, j: number) {
    if (this.comment.sub_comments[j].user_vote !== 1) {
      this.forumService.like(this.comment.sub_comments[j].id).subscribe(() => {
        this.comment.sub_comments[j].score++;
        this.comment.sub_comments[j].user_vote = 1;
      });
    } else {
      this.forumService.removeLike(this.comment.sub_comments[j].id).subscribe(() => {
        this.comment.sub_comments[j].score--;
        this.comment.sub_comments[j].user_vote = 0;
      });
    }
    e.stopPropagation();
  }

  dislike(e: Event, i: number) {
    if (this.comment.user_vote !== -1) {
      this.forumService.dislike(this.comment.id).subscribe(() => {
        this.comment.score--;
        this.comment.user_vote = -1;
      });
    } else {
      this.forumService.removeLike(this.comment.id).subscribe(() => {
        this.comment.score++;
        this.comment.user_vote = 0;
      });
    }
    e.stopPropagation();
  }

  downVote(e: Event, i: number, j: number) {
    if (this.comment.sub_comments[j].user_vote !== -1) {
      this.forumService.dislike(this.comment.sub_comments[j].id).subscribe(() => {
        this.comment.sub_comments[j].score--;
        this.comment.sub_comments[j].user_vote = -1;
      });
    } else {
      this.forumService.removeLike(this.comment.sub_comments[j].id).subscribe(() => {
        this.comment.sub_comments[j].score++;
        this.comment.sub_comments[j].user_vote = 0;
      });
    }
    e.stopPropagation();
  }

  selectComment(e: Event, i) {
    this.subCommentsIsOpen = !this.subCommentsIsOpen;
    document.getElementById(`forum-${i}`).toggleAttribute('hidden');
    e.stopPropagation();
  }

  hideModal() {
    $('#forumModal').modal('hide');
    $('body').removeClass('modal-open');
  }

  copySuccess(e: Event) {
    this.toast.success({ text: 'تم نسخ الرابط بنجاح', showConfirmButton: false });
    e.stopPropagation();
    this.hideModal();
  }

  share(e) {
    $('#copyModal' + this.comment.id).modal('toggle');
    this.hideModal();
    e.stopPropagation();
  }

  openLoginModal(e: Event) {
    $('#loginModal').modal('toggle');
    this.hideModal();
    e.stopPropagation();
  }

  getCommentTypeIcon = getCommentTypeIcon;

  commentTypeToArabic = commentTypeToArabic;

  followItem(id: number, index: number = undefined) {
    this.forumService
      .followItem({
        id,
      })
      .subscribe(
        data => {
          this.toast.success({ text: 'أنت الآن مشترك وتتابع التحديثات' });
          if (typeof index === 'number') this.filtredForum[index] = data;
        },
        error => {
          throw new Error('لم نتمكن من المتابعة ، يرجى المحاولة مرة أخرى لاحقًا');
        },
      );
  }

  isFollowed(item: IOpsfeatureItem) {
    const connected_user_id: number = parseInt(getItem(StorageItem.connected_user_id));
    if (typeof connected_user_id === 'number' && item.followers?.length && item?.created_by_id)
      return item.followers?.includes(connected_user_id) || connected_user_id === item?.created_by_id;
    return false;
  }
}
