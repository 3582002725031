import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.css'],
})
export class MenuMobileComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
