export enum StorageItem {
  // user related
  connected_user_id = 'connected_user_id',
  token = 'token',
  refresh_token = 'refresh_token',
  first_login = 'first_login',
  phone_number = 'phone_number',
  latest_residence_update_date = 'latest_residence_update_date',
  unique_device_id = 'unique_device_id',

  // app related
  municipality = 'municipality',
  municipality_ar = 'municipality_ar',
  municipality_name = 'municipality_name',
  municipality_logo = 'municipality_logo',
  municipality_route_name = 'municipality_route_name',
  muni_website = 'muni_website',
  agency_id = 'agency_id',
}

export const getItem = (itemName: StorageItem): string | null => {
  const item = localStorage.getItem(itemName);
  return item ? item : null;
};

export const setItem = (itemName: StorageItem, value: string): void => {
  localStorage.setItem(itemName, value);
};

export const removeItem = (itemName: StorageItem): void => {
  localStorage.removeItem(itemName);
};

export const clearItems = (): void => {
  // should keep the device id
  const unique_device_id = getItem(StorageItem.unique_device_id);
  localStorage.clear();
  if (unique_device_id != null) setItem(StorageItem.unique_device_id, unique_device_id);
};
