import { Pipe, PipeTransform } from '@angular/core';

import { UpdateTypes } from '../models/types';

@Pipe({
  name: 'accessRequest',
})
export class AccessRequestPipe implements PipeTransform {
  transform(status: string): string {
    switch (status) {
      case UpdateTypes.NOT_CLEAR:
        return 'المطلب غير واضح';
      case UpdateTypes.INVALID:
        return 'المطلب خارج مشمولات البلدية';
      case UpdateTypes.PROCESSING:
        return 'المطلب بصدد المعالجة';
      case UpdateTypes.REJECTED:
        return 'تعذر على البلدية الموافقة على المطلب';
      case UpdateTypes.ACCEPTED:
        return 'تم الموافقة على المطلب';
      // defaults to RECEIVED
      default:
        return 'تم استقبال المطلب';
    }
  }
}
