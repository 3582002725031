import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sub-comment',
  templateUrl: './sub-comment.component.html',
  styleUrls: ['./sub-comment.component.css'],
})
export class SubCommentComponent implements OnInit {
  @Input() screenWidth: number;
  @Input() user_fullname: string;
  @Input() body: string;

  constructor() {}

  ngOnInit(): void {}
}
