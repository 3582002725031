import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularOpenlayersModule } from 'ngx-openlayers';
import { LoginComponent } from 'src/app/components/login/login.component';
import { AssociationsService } from 'src/app/services/associations.service';
import { StaticTextsService } from 'src/app/services/static-texts.service';

import { SignedMunisComponent } from '../../components/signed-munis/signed-munis.component';
import { OurCommonModule } from '../../core/OurCommon.module';

import { AboutHomeComponent } from './about-home/about-home.component';
import { InfosSectionComponent } from './about-home/infos-section/infos-section.component';
import { JoinUsComponent } from './about-home/join-us/join-us.component';
import { ServicesAboutComponent } from './about-home/services-about/services-about.component';
import { SponsorsSectionComponent } from './about-home/sponsors-section/sponsors-section.component';
import { TopSectionAboutComponent } from './about-home/top-section-about/top-section-about.component';
import { CounterComponent } from './counter/counter.component';
import { FooterHomeComponent } from './footer-home/footer-home.component';
import { FrequentlyAskedComponent } from './frequently-asked/frequently-asked.component';
import { GeneralHeaderComponent } from './general-header/general-header.component';
import { GeneralHomeRoutingModule } from './general-home-routing.module';
import { GeneralHomeComponent } from './general-home.component';
import { GoogleFormComponent } from './google-form/google-form.component';
import { HomePageGeneralComponent } from './home-page-general/home-page-general.component';
import { MobileAppComponent } from './mobile-app/mobile-app.component';
import { MunicipalitiesComponent } from './municipalities/municipalities.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { PartnersComponent } from './partners/partners.component';
import { PreinscriptionComponent } from './preinscription/preinscription.component';
import { RegisterComponent } from './register/register.component';
import { RegisterConditionsComponent } from './registration-conditions/registration-conditions.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ServicesSectionComponent } from './services-section/services-section.component';
import { TopSectionComponent } from './top-section/top-section.component';
import { TunisiaMapComponent } from './tunisia-map/tunisia-map.component';
import { VerificationComponent } from './verification/verification.component';
import { VideoIntroComponent } from './video-intro/video-intro.component';
import { TutoEticketV2Component } from './tuto-eticket-v2/tuto-eticket-v2.component';

@NgModule({
  declarations: [
    GeneralHomeComponent,
    GeneralHeaderComponent,
    TopSectionComponent,
    ServicesSectionComponent,
    CounterComponent,
    MobileAppComponent,
    FooterHomeComponent,
    PartnersComponent,
    VideoIntroComponent,
    TunisiaMapComponent,
    AboutHomeComponent,
    TopSectionAboutComponent,
    SponsorsSectionComponent,
    InfosSectionComponent,
    ServicesAboutComponent,
    HomePageGeneralComponent,
    JoinUsComponent,
    NotFoundPageComponent,
    GoogleFormComponent,
    PreinscriptionComponent,
    RegisterComponent,
    VerificationComponent,
    SignedMunisComponent,
    ResetPasswordComponent,
    LoginComponent,
    MunicipalitiesComponent,
    FrequentlyAskedComponent,
    RegisterConditionsComponent,
    TutoEticketV2Component,
  ],
  providers: [AssociationsService, StaticTextsService],
  imports: [
    CommonModule,
    GeneralHomeRoutingModule,
    FormsModule,
    OurCommonModule,
    AngularOpenlayersModule,
    ReactiveFormsModule,
  ],
})
export class GeneralHomeModule {}
