import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MunisFacade, UiFacade, UserFacade } from 'src/app/core/store';
import Backend from 'src/app/core/backend';
import { defaultAnimation } from '../../../core';
import { GenericSingleViewFeature } from 'src/app/core/models/class/GenericSingleViewFeature';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { EventsService } from 'src/app/services/events.service';
import { Event } from 'src/app/core/models/class/event';

@Component({
  selector: 'app-calendar-details',
  templateUrl: './calendar-details.component.html',
  styleUrls: ['./calendar-details.component.css'],
  ...defaultAnimation,
})
export class CalendarDetailsComponent extends GenericSingleViewFeature<Event> implements OnInit {
  frontUrl = Backend.frontUrl;

  constructor(
    public _eventsService: EventsService,
    public route: ActivatedRoute,
    public _munisFacade: MunisFacade,
    public _toastLuncher: ToastLuncherService,
    public _userFacade: UserFacade,
    public router: Router,
    public _uiFacade: UiFacade,
  ) {
    super('id', _eventsService, route, _munisFacade, _userFacade, _toastLuncher, router);
  }

  ngOnInit(): void {}
  normalizeAfterGetItem(item: Event) {
    this._uiFacade.setFeatureLoaded();
    return item;
  }
  participateEvent() {
    this._eventsService.setParticipation(this.item.id).subscribe(() => {
      this.item.user_participating = true;
      this.item.number_of_participants++;
    });
  }

  unparticipateEvent() {
    this._eventsService.removeParticipation(this.item.id).subscribe(() => {
      this.item.user_participating = false;
      this.item.number_of_participants--;
    });
  }

  interestEvent() {
    this._eventsService.setInterest(this.item.id).subscribe(() => {
      this.item.user_interested = true;
      this.item.number_of_interested_citizen++;
    });
  }

  disinterestEvent() {
    this._eventsService.removeInterest(this.item.id).subscribe(() => {
      this.item.user_interested = false;
      this.item.number_of_interested_citizen--;
    });
  }
}
