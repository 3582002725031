import { Pipe, PipeTransform } from '@angular/core';

import { UpdateTypes } from '../models/types';

@Pipe({
  name: 'commentStatusIcon',
})
export class CommentStatusIconPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case UpdateTypes.ACCEPTED:
        return '../../assets/images/ico/svg/statusLogo1.svg';
      case UpdateTypes.REJECTED:
      case UpdateTypes.NOT_CLEAR:
      case UpdateTypes.INVALID:
        return '../../assets/images/ico/svg/statusLogo3.svg';
      // RECEIVED, PROCESSING,
      default:
        return '../../assets/images/ico/svg/statusLogo2.svg';
    }
  }
}
