import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SubFeature } from 'src/app/core/models/class/GenericListFeature';
import { Municipality } from 'src/app/core/models/class/municipality';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';

import { News } from '../../../core/models/class/news';

declare let $: any;

@Component({
  selector: 'app-news-detail-home',
  templateUrl: './news-detail-home.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./news-detail-home.component.css'],
})
export class NewsDetailHomeComponent extends SubFeature implements OnInit {
  @Input() shareFacebook: (url: string) => void;
  @Input()
  news = new News();
  @Input()
  @Input()
  municipality: Municipality;

  constructor(public _toastLuncher: ToastLuncherService) {
    super(_toastLuncher);
  }

  ngOnInit() {}
}
