import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { getItem, StorageItem } from 'src/app/core';
import { Municipality, MunicipalitySummary, StateRow } from 'src/app/core/models/class/municipality';
import { UserFacade } from 'src/app/core/store';

import { MunisFacade } from '../../../core/store/src/municipality/municipality.facade';

@Component({
  selector: 'app-general-header',
  templateUrl: './general-header.component.html',
  styleUrls: ['./general-header.component.css', '../general-home.component.css'],
})
export class GeneralHeaderComponent implements OnInit {
  token = '';
  municipalityName = '';
  currRoute = '';
  mappedMunicipalities: Map<string, Array<any>> = new Map<string, Array<any>>();
  munisList$: Observable<Municipality[]> = this.munisFacade.munisList$;
  isLoading$: Observable<boolean> = this.munisFacade.isLoading$;
  stateRows: StateRow[] = [];

  constructor(public route: Router, private munisFacade: MunisFacade, private _userFacade: UserFacade) {}

  ngOnInit() {
    this.token = getItem(StorageItem.token);
    this.municipalityName = this._userFacade.isAuthenticated() ? getItem(StorageItem.municipality_route_name) : null;
    this.currRoute = this.route.url;
    const base = this;
    this.munisList$.subscribe(munisList => {
      if (munisList != null) {
        const addedStates: string[] = [];
        munisList.forEach(muni => {
          const muniToAdd = {
            name: muni.name,
            name_fr: muni.name_fr,
            route_name: muni.route_name,
            is_active: muni.is_active,
            is_signed: muni.is_signed,
          } as MunicipalitySummary;
          if (!addedStates.includes(muni.city)) {
            addedStates.push(muni.city);
            base.stateRows.push({
              stateName: muni.city,
              munisCount: 0,
              isEmpty: true,
              municipalities: [muniToAdd],
            });
          } else {
            if (!muni.is_active && !muni.is_signed) return;
            let i = 0;
            let found = false;
            while (i <= base.stateRows.length && !found) {
              if (base.stateRows[i].stateName === muni.city) {
                base.stateRows[i].municipalities.push(muniToAdd);
                base.stateRows[i].munisCount++;
                base.stateRows[i].isEmpty = false;
                addedStates.push(muni.city);
                found = true;
              }
              i++;
            }
          }
        });
      }
    });
  }

  trackMunis(index, municipality) {
    return municipality.munisCount;
  }

  getNumMunPerState(state) {
    return this.mappedMunicipalities.get(state).filter(item => item.is_signed).length;
  }

  // navigateToMuns() {
  //   this.route.navigateByUrl('/', {fragment: 'map_section', skipLocationChange: true});
  // }
}
