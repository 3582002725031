import { getItem, StorageItem } from '../..';
import { IfeatureItem } from '../interface/IfeatureItem';
import { UpdateTypes } from '../types';
import { Update } from './update';

export class SubjectAccessRequest implements IfeatureItem {
  id: number;
  municipality_id: number;
  document: string;
  structure: string = ' بلدية' + getItem(StorageItem.municipality_ar);
  reference: string;
  on_spot_document: boolean;
  printed_document: boolean;
  electronic_document: boolean;
  parts_of_document: boolean;
  note: string;
  status: UpdateTypes = UpdateTypes.RECEIVED;
  attachment: string;
  created_at: string;
  user_fullname: string;
  followers: number[];
  updates = new Array<Update>();
  is_public = true;
  created_by_id: number;
  ago: string;
  format: string;
  user_address: string;
  user_phone: string;
  user_email: string;
  lastTrait: string;
}
