import { Component, OnInit } from '@angular/core';

import { Association } from '../../../core/models/class/association';
import { AssociationsService } from '../../../services/associations.service';
import Backend from 'src/app/core/backend';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css'],
})
export class PartnersComponent implements OnInit {
  constructor(private associationsService: AssociationsService) {}

  associations: Association[] | Association[][];
  baseUrl: string = Backend.baseUrl;
  loading = false;

  ngOnInit() {
    this.getAssociations();
    this.baseUrl = Backend.baseUrl;
  }

  getAssociations() {
    this.loading = true;
    this.associationsService.get().subscribe(data => {
      this.associations = data;
      this.configureLogos();
      this.loading = false;
    });
  }
  configureLogos() {
    const logosArray = [];
    // the length of each set in the gallery
    const setLength = 3;
    for (let i = 0; i < this.associations.length; i += setLength) {
      const miniArr = [];
      for (let j = 0; j < setLength; j++) {
        if (this.associations[i + j]) miniArr.push(this.associations[i + j]);
      }
      logosArray.push(miniArr);
    }
    this.associations = logosArray;
  }
  redirectTo(name) {
    window.open(name, '_blank');
  }
}
