import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-about-home',
  templateUrl: './about-home.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./about-home.component.css', '../general-home.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AboutHomeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
