import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-section-about',
  templateUrl: './top-section-about.component.html',
  styleUrls: ['./top-section-about.component.css'],
})
export class TopSectionAboutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
