import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { CalendarOptions } from '@fullcalendar/angular'; // useful for typechecking
import { Observable, Subscription } from 'rxjs';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { MunisFacade, UiFacade, UserFacade } from 'src/app/core/store';

import { Dossier } from '../../core/models/class/dossier';
import { News } from '../../core/models/class/news';
import { User } from '../../core/models/class/user';
import { CommitteeService } from '../../services/committee.service';
import { ComplaintsService } from '../../services/complaints.service';
import { DossiersService } from '../../services/dossiers.service';
import { EventsService } from '../../services/events.service';
import { ForumService } from '../../services/forum.service';
import { MunicipalityService } from '../../services/municipality.service';
import { NewsService } from '../../services/news.service';
import { SubjectAccessRequestService } from '../../services/subject-access-request.service';
import { dossierTypes, getItem, StorageItem, defaultAnimation } from '../../core';
import { Municipality } from 'src/app/core/models/class/municipality';
import { Complaint } from 'src/app/core/models/class/complaint';
import { Committee } from 'src/app/core/models/class/committee';
import { Event } from 'src/app/core/models/class/event';
import { Report } from 'src/app/core/models/class/report';
import { DossierTypes, UpdateTypes } from 'src/app/core/models/types';
import { Comment } from 'src/app/core/models/class/comment';
import { MainFeature } from 'src/app/core/models/class/GenericListFeature';
import { UserService } from 'src/app/core/services/user.service';

declare let $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  ...defaultAnimation,
})
export class HomeComponent extends MainFeature implements OnInit, OnDestroy {
  municipalityName;
  municipalityNameFr;
  selectedMunicipality: Municipality;
  innerWidth = window.innerWidth;
  selectedMuni$: Observable<Municipality> = this._munisFacade.selectedMuni$;
  loggedInUser$: Observable<User> = this._userFacade.loggedInUser$;

  forum: Comment[] = [];
  reports: Report[] = [];
  news: News[] = [];
  allComplaints: Complaint[] = [];
  committees: Committee[] = [];
  events: Event[] = [];
  nativeEvents: Event[] = [];
  dossiers: Dossier[] = [];

  subjectAccessRequests = [];
  selectedNewsModal = 0;
  selectedComplaintModal = 0;
  selectedAccessModal = 0;
  selectedDossierModal = 0;
  selectedEventModal = 0;
  selectedReportModal = 0;
  selectedForumModal = 0;

  loadPlaintes = true;
  loadNews = true;
  loadCalendar = true;
  loadAccess = true;
  loadForum = true;
  loadReports = true;
  loadDossiers = true;

  isLoading = false;
  calendarOptions: CalendarOptions;
  public subscriptions: Array<Subscription> = [];

  constructor(
    public _utilsService: MunicipalityService,
    public _complaintsService: ComplaintsService,
    public _munisFacade: MunisFacade,
    public _toastLuncher: ToastLuncherService,
    public _newsService: NewsService,
    public _subjectAccessRequestService: SubjectAccessRequestService,
    public _forumService: ForumService,
    public _committeeService: CommitteeService,
    public _eventsService: EventsService,
    public _dossiersService: DossiersService,
    public _userService: UserService,
    public _uiFacade: UiFacade,
    public _userFacade: UserFacade,
  ) {
    super(_userFacade, _toastLuncher);

    this.subscriptions.push(
      this._munisFacade.selectedMuni$.subscribe(muni => {
        if (muni) {
          this.municipalityName = muni.name;
          this.municipalityNameFr = muni.name_fr;
          this.selectedMunicipality = muni;
          this.getNews();
          this.getCommittees();
          this.getComplaints();
          this.getEvents();
          this.getSubjectAccessRequests();
          this._uiFacade.setFeatureLoaded();
          if (this.isAuthenticated) {
            this._userFacade.loadUser();
          }
        }
      }),
    );
  }

  ngOnInit() {
    //  this._uiFacade.setFeatureLoading();
    this.loggedInUser$.subscribe(user => {
      if (user) this.getFollowedItems();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      // bye bye memory leaks!
      subscription.unsubscribe();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(e) {
    this.innerWidth = window.innerWidth;
  }

  getNews() {
    this.loadNews = true;
    this.subscriptions.push(
      this._newsService.getPages(1, 6).subscribe(data => {
        this.news = data.sort((item1, item2) => (item1.published_at > item2.published_at ? -1 : 1));
        this.loadNews = false;
      }),
    );
  }

  getComplaints() {
    this.loadPlaintes = true;
    this.subscriptions.push(
      this._complaintsService.getPages(1, 5).subscribe(data => {
        this.allComplaints = data.sort((item1, item2) => (item1.created_at > item2.created_at ? -1 : 1));
        if (this.isAuthenticated) {
          this.allComplaints = this.allComplaints.filter(
            item => item.is_public || item.created_by_id.toString() === getItem(StorageItem.connected_user_id),
          );
        }
        this.allComplaints.forEach(item => {
          item.status = item.updates.length ? item.updates[0].status : UpdateTypes.RECEIVED;
        });
        this.loadPlaintes = false;
      }),
    );
  }

  getSubjectAccessRequests() {
    this.loadAccess = true;
    this.subscriptions.push(
      this._subjectAccessRequestService.getPages(1, 5).subscribe(data => {
        this.subjectAccessRequests = data.filter(
          item => item.created_by_id.toString() === getItem(StorageItem.connected_user_id) || item.is_public,
        );
        this.subjectAccessRequests = this.subjectAccessRequests.sort((item1, item2) =>
          item1.created_at > item2.created_at ? -1 : 1,
        );
        this.subjectAccessRequests.forEach(item => {
          item.created_at = new Date(Date.parse(item.created_at)).toLocaleDateString();
          item.status = item.updates?.length ? item.updates[0].status : UpdateTypes.RECEIVED;
        });
        this.subjectAccessRequests = this.subjectAccessRequests.slice(0, 3);
        this.loadAccess = false;
      }),
    );
  }

  getForum() {
    this.loadForum = true;
    this.subscriptions.push(
      this._forumService.getPages(1, 3).subscribe(data => {
        data = data.map(each => {
          each.committee_title = this.committees.find(c => c.id === each.committee_id)?.title;
          return each;
        });
        this.forum = data.sort((item1, item2) => (item1.created_at > item2.created_at ? -1 : 1));
        this.loadForum = false;
      }),
    );
  }

  public getCommittees() {
    this.subscriptions.push(
      this._committeeService.get().subscribe(
        data => {
          this.committees = data || [];
          this.getReports();
          this.getForum();
        },
        err => {
          throw new Error('لم نتمكن من الحصول على قائمة اللجان');
        },
      ),
    );
  }

  public getReports() {
    this.loadReports = true;
    this.subscriptions.push(
      this._committeeService.getReports().subscribe(
        reports => {
          this.reports = reports || [];
          this.committees.forEach(com => {
            com.reports = reports.filter(rep => rep.committee_id === com.id);
          });
          this.reports.forEach(report => {
            report.committee = this.committees.filter(com => com.id === report.committee_id)[0].title;
          });

          if (this.committees.filter(item => item.title === 'المجلس البلدي').length === 0) {
            const com = new Committee();
            com.title = 'المجلس البلدي';
            com.body =
              'يتصرف المجلس البلدي في الشؤون البلدية، ويساهم في نطاق المخطط الوطني للتنمية في النهوض بالمنطقة اقتصاديا واجتماعيا وثقافيا، ويدرس ميزانية البلدية ويوافق عليها، ويضبط في حدود المداخيل البلدية والإمكانيات الموضوعة تحت تصرفها برنامج تجهيز البلدية، ويضبط مختلف الأعمال التي يتعين القيام بها للمساعدة على تنمية المنطقة وفقا للمخطط الوطني للتنمية. من جهة أخرى يدلي المجلس البلدي برأيه في جميع المسائل ذات الصبغة المحلية خصوصا المتعلق منها بالميادين الإقتصادية والإجتماعية والثقافية وفي كل الحالات التي تستوجبها القوانين والتراتيب أو كلما طلبت سلطة الإشراف ذلك، ويستشار مسبقا في كل مشروع يزمع إنجازه في منطقة بلدية من طرف الدولة أو أي جماعة أخرى أو مؤسسة عمومية.';
            com.id = -1;
            com.reports = reports.filter(rep => rep.committee_id === null);
            this.committees.unshift(com);
          }
          this.reports = this.reports.slice(0, 3);
          this.loadReports = false;
        },
        err => {
          this.loadReports = false;
          throw new Error('لم نتمكن من الحصول على قائمة التقارير');
        },
      ),
    );
  }

  getEvents() {
    this.loadCalendar = true;
    this.subscriptions.push(
      this._eventsService.get().subscribe(data => {
        this.nativeEvents = data;
        this.events = Event.toCalendarEvents(data);
        this.calendarOptions = {
          height: 420,
          initialView: 'dayGridMonth',
          events: this.events as unknown,
          eventClick: this.selectEventModalItem.bind(this),
          firstDay: 1,
          buttonText: { today: 'اليوم' },
          locale: 'ar-tn',
        };
        this.loadCalendar = false;
      }),
    );
  }

  getFollowedItems() {
    this.loadDossiers = true;
    this.subscriptions.push(
      this._dossiersService.getFollowedItems().subscribe(data => {
        this.dossiers = data.sort((item1, item2) => (item1.created_at > item2.created_at ? -1 : 1));
        this.dossiers.forEach(dossier => {
          dossier.status = dossier.updates.length ? dossier.updates[0].status : UpdateTypes.RECEIVED;
          dossier.type = dossierTypes.filter(
            item => item.name === dossier.type || item.name === DossierTypes.OTHER,
          )[0].displayName;
        });
        this.dossiers = this.dossiers.slice(0, 2);
        this.loadDossiers = false;
      }),
    );
  }

  selectNewsModalItem(i) {
    this.selectedNewsModal = i;
  }

  selectComplaintModalItem(i) {
    this.selectedComplaintModal = i;
  }

  selectAccessModalItem(i) {
    this.selectedAccessModal = i;
  }

  selectDossierModalItem(i) {
    this.selectedDossierModal = i;
  }

  selectReportModalItem(i) {
    this.selectedReportModal = i;
  }

  selectForumModal(i) {
    this.selectedForumModal = i;
  }

  selectEventModalItem(event) {
    this.selectedEventModal = this.nativeEvents.indexOf(
      this.nativeEvents.filter(item => item.id.toString() === event.event.id)[0],
    );
    $('#eventModal').modal('toggle');
  }

  followMun() {
    this._utilsService
      .followMunicipality(this.selectedMunicipality.id.toString(), this.selectedMunicipality.is_selected)
      .subscribe(data => {
        this.selectedMunicipality.is_selected = !this.selectedMunicipality.is_selected;
        // const currentUrl = this.router.url;
        // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        // this.router.onSameUrlNavigation = 'reload';
        // this.router.navigate([currentUrl]);

        // this.router.navigateByUrl('/dossiers', { skipLocationChange: true }).then(() => {
        //   this.router.navigate(['/']);
        // });
      });
  }
}
