import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-animation',
  templateUrl: './loading-animation.component.html',
  styleUrls: ['./loading-animation.component.css'],
})
export class LoadingAnimationComponent implements OnInit {
  @Input() scale = '1';
  @Input() seconds = 0;
  @Input() countdown = false;
  @Input() hideAfterCountdown = true;
  exp = '';

  constructor() {}

  ngOnInit() {
    this.exp = 'scale(' + this.scale + ',' + this.scale + ')';
    if (this.countdown) {
      const base = this;
      const b = setInterval(() => {
        base.seconds--;
        if (base.seconds <= 0) {
          clearInterval(b);
        }
      }, 1000);
    }
  }
}
