import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-join-us',
  templateUrl: './join-us.component.html',
  styleUrls: ['./join-us.component.css', '../../general-home.component.css'],
})
export class JoinUsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
