import { IfeatureItem } from '../interface/IfeatureItem';

export class Event implements IfeatureItem {
  id: number;
  municipality_id: number;
  title: string;
  created_at: string;
  description: string;
  date: string;
  time: string;
  structure: string;
  location: string;
  image: string = null;
  committee_id: number;
  starting_date: string;
  user_interested: boolean;
  user_participating: boolean;
  number_of_interested_citizen: number;
  number_of_participants: number;
  starting_time: string;
  ending_date: string;
  ending_time: string;
  committees: any[];

  static toCalendarEvents(events: Array<Event>): any[] {
    return events.map(item => {
      return {
        id: item.id.toString(),
        title: item.title,
        date: item.starting_date,
        description: item.description,
      };
    });
  }
}
