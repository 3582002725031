import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arabicTimeLeft',
})
export class ArabicTimeLeftPipe implements PipeTransform {
  transform(date: string): string {
    const created_at = new Date(date);
    const today = new Date();
    const difference = Math.ceil((created_at.getTime() - today.getTime()) / (1000 * 3600 * 24));
    if (difference === 1) return ' يـوم واحـد ';
    if (difference === 2) return '  يـومـان ';
    if (difference > 2 && difference < 11) return difference + ' أيام ';
    return difference + ' يـوم ';
  }
}
