import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { getItem, StorageItem } from '../core';
import { Reservation } from '../core/models/class/agency';
import { Service } from '../core/models/class/service';
import { BaseService } from '../core/services/base.service';

import { GenericReadActiveService } from '../core/services/generic.service';
import { MunisFacade } from '../core/store';
@Injectable({
  providedIn: 'root',
})
export class ServicesService extends GenericReadActiveService<Service> {
  constructor(protected http: HttpClient) {
    super(http);
    this.prefix = '/agencies/';
    this.suffix = '/';
  }

  protected getUrl() {
    return (
      BaseService.url +
      '/municipalities/' +
      MunisFacade.getMunicipalityId() +
      this.prefix +
      getItem(StorageItem.agency_id) +
      '/services'
    );
  }

  public bookById(id: number): Observable<Reservation> {
    return (
      this.http.get(this.getUrl() + '/' + id + '/book/', {
        headers: BaseService.getHeaders(),
      }) as Observable<Reservation>
    ).pipe(first());
  }
}
