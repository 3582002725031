import { Component, OnInit, ViewChild } from '@angular/core';

import { SinglePollComponent } from 'src/app/components/single-poll/single-poll.component';
// import { IntroLuncherService } from "src/app/services/intro-luncher.service";
import { PollsService } from 'src/app/services/polls.service';
import { MunisFacade, UiFacade, UserFacade } from 'src/app/core/store';
import { defaultAnimation } from 'src/app/core';
import { IntroLuncherService } from 'src/app/services/intro-luncher.service';
import { Poll } from 'src/app/core/models/class/polls';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { SelectedItemFeature } from 'src/app/core/models/class/GenericListFeature';
import { Municipality } from 'src/app/core/models/class/municipality';

@Component({
  selector: 'app-polls',
  templateUrl: './polls.component.html',
  styleUrls: ['./polls.component.css'],
  ...defaultAnimation,
})
export class PollsListComponent extends SelectedItemFeature<Poll> implements OnInit {
  @ViewChild(SinglePollComponent) child: SinglePollComponent;

  constructor(
    public _munisFacade: MunisFacade,
    public _userFacade: UserFacade,
    public _introLuncher: IntroLuncherService,
    public _toastLuncher: ToastLuncherService,
    public _pollsService: PollsService,
    public _uiFacade: UiFacade,
  ) {
    super(_pollsService, _userFacade, _munisFacade, _toastLuncher);
  }

  ngOnInit(): void {}

  onMunicipalityChange(muni: Municipality) {
    this.items = [];
    this.getPages();
  }

  preSettingData(data: Poll[]): Poll[] {
    data = data.filter(poll => poll.choices.length > 1);
    return data;
  }

  postSettingData(): void {
    this.items = this.items.concat(this.items.sort((item1, item2) => (item1.created_at > item2.created_at ? -1 : 1)));
    this.selectedItem = this.items[0] || null;
    this._uiFacade.setFeatureLoaded();
  }

  startFeatureIntro() {
    this._introLuncher.startFeatureIntro();
  }

  selectItem(item) {
    if (item === this.selectedItem) {
      this.selectedItem = null;
      this.child.reset();
    } else {
      this.selectedItem = item;
    }
  }

  onSubmit() {
    const base = this;
    this.selectedItem.already_voted = true;
    setTimeout(() => {
      base.selectedItem = null;
    }, 1000);
  }
}
