import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { News } from '../core/models/class/news';
import { GenericCRUDService } from '../core/services/generic.service';

@Injectable({
  providedIn: 'root',
})
export class NewsService extends GenericCRUDService<News> {
  constructor(http: HttpClient) {
    super(http);
    this.prefix = '/news';
  }
}
