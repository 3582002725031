import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Report } from '../models/class/report';
import { MunisFacade } from '../store';

import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class GenericReadOnlyService<T> extends BaseService {
  constructor(protected http: HttpClient) {
    super();
  }

  protected getUrl(namespace = '') {
    return BaseService.url + namespace + '/municipalities/' + MunisFacade.getMunicipalityId() + this.prefix;
  }

  public get(): Observable<T[]> {
    return (
      this.http.get(this.getUrl() + '?per_page=150' + this.suffix, {
        headers: BaseService.getHeaders(),
      }) as Observable<T[]>
    ).pipe(first());
  }

  public getPages(page: number, perPage: number): Observable<T[]> {
    if (page === 0 && perPage === 0) return this.get();
    return (
      this.http.get(this.getUrl() + '?page=' + page + '&per_page=' + perPage + this.suffix, {
        headers: BaseService.getHeaders(),
      }) as Observable<T[]>
    ).pipe(first());
  }

  public getById(id: string | number): Observable<T> {
    return (
      this.http.get(this.getUrl() + '/' + id + this.suffix, {
        headers: BaseService.getHeaders(),
      }) as Observable<T>
    ).pipe(first());
  }
}

@Injectable({
  providedIn: 'root',
})
export class GenericCRUDService<T> extends GenericReadOnlyService<T> {
  constructor(protected http: HttpClient) {
    super(http);
  }

  public post(body: T): Observable<T> {
    return this.http.post(this.getUrl() + this.suffix, body, {
      headers: BaseService.getHeaders(),
    }) as Observable<T>;
  }

  public put(id: string | number, body: T): Observable<T> {
    return (
      this.http.put(this.getUrl() + '/' + id + this.suffix, body, {
        headers: BaseService.getHeaders(),
      }) as Observable<T>
    ).pipe(first());
  }

  public delete(id: string | number): Observable<T> {
    return (
      this.http.delete(this.getUrl() + '/' + id + this.suffix, {
        headers: BaseService.getHeaders(),
      }) as Observable<T>
    ).pipe(first());
  }

  /**
   *
   * exist only for commitee
   */
  public getReports(): Observable<Report[]> {
    return (
      this.http.get(BaseService.url + '/municipalities/' + MunisFacade.getMunicipalityId() + '/reports', {
        headers: BaseService.getHeaders(),
      }) as Observable<Array<Report>>
    ).pipe(first());
  }
}

@Injectable({
  providedIn: 'root',
})
export class GenericCRUDServiceWithReports<T> extends GenericCRUDService<T> {
  constructor(protected http: HttpClient) {
    super(http);
  }
  public getReports(): Observable<Report[]> {
    return (
      this.http.get(BaseService.url + '/municipalities/' + MunisFacade.getMunicipalityId() + '/reports', {
        headers: BaseService.getHeaders(),
      }) as Observable<Array<Report>>
    ).pipe(first());
  }
}

@Injectable({
  providedIn: 'root',
})
export class GenericCRUDServiceWithFollowItem<T> extends GenericCRUDService<T> {
  private followUrl: string = '/follow';
  private namespace: string = '/notifications';
  constructor(protected http: HttpClient) {
    super(http);
  }

  public getFollowedItems(): Observable<T[]> {
    return this.http.get(this.getUrl(this.namespace) + this.followUrl, {
      headers: BaseService.getHeaders(),
    }) as Observable<T[]>;
  }

  /**
   *
   * @param payload an object with required keys to follow the item
   * depending on the item being followd id can be only the identifier or another key to prove ownership
   */
  public followItem(payload: any): Observable<T> {
    return this.http.post(this.getUrl(this.namespace) + this.followUrl, payload, {
      headers: BaseService.getHeaders(),
    }) as Observable<T>;
  }
}

@Injectable({
  providedIn: 'root',
})
export class GenericReadActiveService<T> extends GenericReadOnlyService<T> {
  constructor(protected http: HttpClient) {
    super(http);
  }
  /**
   *
   * @returns Observable of active records
   */
  public get(): Observable<T[]> {
    return (
      this.http.get(this.getUrl() + this.suffix + '?is_active=True', {
        headers: BaseService.getHeaders(),
      }) as Observable<T[]>
    ).pipe(first());
  }
}
