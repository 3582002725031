import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MunisFacade, UiFacade, UserFacade } from 'src/app/core/store';

import { Comment } from '../../../core/models/class/comment';
import { ForumService } from '../../../services/forum.service';
import { defaultAnimation } from '../../../core';
import { GenericSingleViewFeature } from 'src/app/core/models/class/GenericSingleViewFeature';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';

declare let $: any;

@Component({
  selector: 'app-forum-details',
  templateUrl: './forum-details.component.html',
  styleUrls: ['./forum-details.component.css'],
  ...defaultAnimation,
})
export class ForumDetailsComponent extends GenericSingleViewFeature<Comment> implements OnInit {
  constructor(
    public _forumService: ForumService,
    public route: ActivatedRoute,
    public _munisFacade: MunisFacade,
    public _toastLuncher: ToastLuncherService,
    public _userFacade: UserFacade,
    public router: Router,
    public _uiFacade: UiFacade,
  ) {
    super('id', _forumService, route, _munisFacade, _userFacade, _toastLuncher, router);
  }

  ngOnInit(): void {}

  normalizeAfterGetItem(item: Comment) {
    this._uiFacade.setFeatureLoaded();
    return item;
  }
}
