import { GenericCRUDService, GenericCRUDServiceWithFollowItem } from '../../services/generic.service';
import { IfeatureItem, IOpsfeatureItem } from '../interface/IfeatureItem';
import { MunisFacade, UserFacade } from '../../store';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import Backend from '../../backend';
import { formatMunicipalityName, openLink } from '../../lib/functions';
import { Municipality } from './municipality';
import { UpdateTypes } from '../types';
import { getItem, StorageItem } from '../../lib/local-storage.utils';
import { IonMunicipalityChange } from '../interface/IonMunicipalityChange';

declare let $: any;

export class SubFeature {
  frontUrl = Backend.frontUrl;
  baseUrl = Backend.baseUrl;

  constructor(public _toastLuncher: ToastLuncherService) {}

  redirectToRegister(): void {
    window.location.href = '/register';
  }

  hideModal() {
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  openLoginModal(e?: Event) {
    this.hideModal();
    $('#loginModal').modal('show');
    e?.stopPropagation();
  }

  copySuccess($event, textToCopy: string): void {
    navigator.clipboard.writeText(textToCopy);
    this._toastLuncher
      .success({
        showConfirmButton: false,
        text: 'تم النسخ بنجاح!',
      })
      .then(() => {
        this.hideModal();
      });
    $event.stopPropagation();
  }

  share(url): void {
    window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + formatMunicipalityName(url),
      '_blank',
      'location=yes,height=570,width=520,scrollbars=yes,status=yes',
    );
  }
  openURL = link => openLink(link);
}
export class MainFeature extends SubFeature {
  isAuthenticated = this._userFacade.isAuthenticated();
  isLoading: boolean = false;

  constructor(public _userFacade: UserFacade, public _toastLuncher: ToastLuncherService) {
    super(_toastLuncher);
  }

  trackById(index: string, item: any): string | number {
    return item?.id;
  }
}
export class GenericListFeature<T extends IfeatureItem> extends MainFeature implements IonMunicipalityChange {
  items: T[] = [];
  paging = 1;
  perPage = 14;
  isLoading: boolean = false;
  noMoreData: boolean = false;

  constructor(
    public _service: GenericCRUDService<T>,
    public _userFacade: UserFacade,
    public _munisFacade: MunisFacade,
    public _toastLuncher: ToastLuncherService,
  ) {
    super(_userFacade, _toastLuncher);
    this._munisFacade.selectedMuni$.subscribe(muni => {
      if (muni) {
        this.isLoading = true;
        this.onMunicipalityChange(muni);
      }
    });
  }

  onMunicipalityChange(municipality: Municipality) {
    // reset component to initial state
  }

  preSettingData(data: T[]): T[] {
    return data;
  }

  postSettingData() {}

  getPages(paging = this.paging, perPage = this.perPage) {
    this.isLoading = true;
    this._service?.getPages(paging, perPage).subscribe(
      data => {
        if (!data?.length || data.length < this.perPage) this.noMoreData = true;
        data = this.preSettingData(data);
        this.items = this.items.concat(data);
        this.postSettingData();
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
        this.noMoreData = true;
      },
    );
  }

  fetchMore() {
    if (this.items?.length && !this.noMoreData && !this.isLoading) {
      this.paging++;
      this.getPages();
    }
  }
}

export class SelectedItemFeature<T extends IfeatureItem> extends GenericListFeature<T> {
  selectedItem: T = null;
  filteredItems: T[] = [];

  constructor(
    public _service: GenericCRUDService<T>,
    public _userFacade: UserFacade,
    public _munisFacade: MunisFacade,
    public _toastLuncher: ToastLuncherService,
  ) {
    super(_service, _userFacade, _munisFacade, _toastLuncher);
  }

  selectItem(item: T, idx?) {}

  applyFilter() {}
}

export class OpsUpdateFeature<T extends IOpsfeatureItem> extends SelectedItemFeature<T> {
  selectedItem: T = null;
  status: Set<string>;
  filteredItems: T[] = [];
  mineOnly = false;
  selectedStatus = null;

  constructor(
    public _service: GenericCRUDServiceWithFollowItem<T>,
    public _userFacade: UserFacade,
    public _munisFacade: MunisFacade,
    public _toastLuncher: ToastLuncherService,
  ) {
    super(_service, _userFacade, _munisFacade, _toastLuncher);
  }

  selectItem(item: T, idx?) {}

  filterByStatus(status: UpdateTypes) {
    if (this.status.has(status)) {
      return (this.filteredItems = this.items.filter(item => item.status === status));
    }
    this.filteredItems = this.items;
  }

  filterMine() {
    if (this.mineOnly)
      return (this.filteredItems = this.items.filter(
        item => item.created_by_id.toString() === getItem(StorageItem.connected_user_id),
      ));
    this.filteredItems = this.items;
  }

  followItem(id: number, index: number = undefined) {
    this._service
      .followItem({
        id,
      })
      .subscribe(
        data => {
          this._toastLuncher.success({ text: 'أنت الآن مشترك وتتابع التحديثات' });
          if (typeof index === 'number') this.items[index] = data;
        },
        error => {
          throw new Error('لم نتمكن من المتابعة ، يرجى المحاولة مرة أخرى لاحقًا');
        },
      );
  }

  isFollowed(item: IOpsfeatureItem) {
    const connected_user_id: number = parseInt(getItem(StorageItem.connected_user_id));
    if (typeof connected_user_id === 'number' && item.followers?.length && item?.created_by_id)
      return item.followers?.includes(connected_user_id) || connected_user_id === item?.created_by_id;
    return false;
  }

  setFilters() {
    this.status = new Set([...this.items.map(i => i.status)]);
  }
}
