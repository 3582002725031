import { Component, OnInit, Input } from '@angular/core';
import { StateRow } from 'src/app/core/models/class/municipality';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-signed-munis',
  templateUrl: './signed-munis.component.html',
  styleUrls: ['./signed-munis.component.css'],
})
export class SignedMunisComponent implements OnInit {
  @Input() stateRow: StateRow;
  nbSignedOrActive: number;
  constructor() {}

  ngOnInit(): void {
    this.nbSignedOrActive = this.stateRow.municipalities.filter(min => min.is_signed).length;
  }

  fireAlertSigned(mun: string) {
    Swal.fire({
      title: 'بلدية ' + mun + ' مسجلة في طور التفعيل. ',
      text: 'يمكنك ملئ هذه الاستمارة للتحصل على اشعار حين يتم تفعيل حساب البلدية.',
      icon: 'question',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'استمارة التحصل على اشعار',
      cancelButtonText: 'اغلاق',
    }).then(result => {
      if (result.value) {
        window.location.href = '/preinscription';
      }
    });
  }
}
