import { Pipe, PipeTransform } from '@angular/core';

import { PollStatusTypes } from '../models/types';

@Pipe({
  name: 'pollStatus',
})
export class PollStatusPipe implements PipeTransform {
  transform(status: string): string {
    switch (status) {
      case PollStatusTypes.IN_PROGRESS:
        return ' مفتـوح للتـصويت ';
      case PollStatusTypes.NOT_STARTED:
        return ' لم ينطلق التصـويت بعد ';
      // defaults to ENDED
      default:
        return ' ﺗﻢ ﻧﺸﺮ اﻟﻨﺘـﺎﺋﺞ ';
    }
  }
}
