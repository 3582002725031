import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { OfflineComponent } from './components/offline/offline.component';
import { PwaService } from './services/pwa.service';

export const MUNICIPALITY_ROUTE_PARAM = 'municiaplity';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/general-home/general-home.module').then(m => m.GeneralHomeModule),
  },
  {
    path: 'offline',
    component: OfflineComponent,
  },
  {
    path: `:${MUNICIPALITY_ROUTE_PARAM}`,
    loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabledNonBlocking',
    }),
  ],
  providers: [PwaService],
  exports: [RouterModule],
})
export class AppRoutingModule {}
