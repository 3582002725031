import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-administrative-features',
  templateUrl: './administrative-features.component.html',
  styleUrls: ['./administrative-features.component.css'],
})
export class AdministrativeFeaturesComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
