import { Component, OnInit } from '@angular/core';
import { MunisFacade, UiFacade, UserFacade } from 'src/app/core/store';
import { Comment } from '../../../core/models/class/comment';
import { CommitteeService } from '../../../services/committee.service';
import { ForumService } from '../../../services/forum.service';
import { TopicsService } from '../../../services/topics.service';
import { defaultAnimation } from '../../../core';
import { Committee } from 'src/app/core/models/class/committee';
import { Topic } from 'src/app/core/models/class/topic';
import { CommentTypes } from 'src/app/core/models/types';
import { OpsUpdateFeature } from 'src/app/core/models/class/GenericListFeature';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { Municipality } from 'src/app/core/models/class/municipality';

declare let $: any;

@Component({
  selector: 'app-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.css'],
  ...defaultAnimation,
})
export class ForumComponent extends OpsUpdateFeature<Comment> implements OnInit {
  topics: Topic[] = [];
  committees: Committee[] = [];
  commentToAdd = new Comment();
  typeFilter: string[] = [];
  committeIdFilter: number;
  TopicIdFilter: number;

  selected = -1;
  newComment = false;
  typesList = [
    { nameAr: 'ســؤال', name: CommentTypes.QUESTION },
    { nameAr: 'ملاحـظـة', name: CommentTypes.REMARK },
    { nameAr: 'مقـترح', name: CommentTypes.SUGGESTION },
  ];

  constructor(
    public _munisFacade: MunisFacade,
    public _userFacade: UserFacade,
    public forumService: ForumService,
    public topicsService: TopicsService,
    public committeeService: CommitteeService,
    public _uiFacade: UiFacade,
    public _toastLuncher: ToastLuncherService,
  ) {
    super(forumService, _userFacade, _munisFacade, _toastLuncher);
  }

  ngOnInit() {}

  onMunicipalityChange(municipality: Municipality) {
    this.items = [];
    this.filteredItems = [];
    this.committees = [];
    this.topics = [];
    this.selectedItem = null;
    this.getPages();
  }

  preSettingData(data: Comment[]): Comment[] {
    return data.sort((item1, item2) => (item1.created_at > item2.created_at ? -1 : 1));
  }

  postSettingData() {
    this.filteredItems = this.items;
    this.getCommittees();
    this.getTopics();
    this.applyAllFilters();
    this._uiFacade.setFeatureLoaded();
  }

  getCommittees() {
    this.committeeService?.get().subscribe(data => {
      this.committees = data;
    });
  }

  getTopics() {
    this.topicsService?.get().subscribe(data => {
      this.topics = data;
    });
  }

  filterCommentsByType(filterName: string) {
    // remove filter if it's already included
    if (this.typeFilter.includes(filterName)) {
      const index = this.typeFilter.indexOf(filterName);
      this.typeFilter.splice(index, 1);
      // else add it
    } else {
      this.typeFilter.push(filterName);
    }
    this.applyAllFilters();
  }

  filterCommitteId(id: number) {
    this.committeIdFilter = id;
    this.applyAllFilters();
  }

  filterTopicId(id: number) {
    this.TopicIdFilter = id;
    this.applyAllFilters();
  }

  applyAllFilters() {
    this.filteredItems = this.items;
    if (this.typeFilter.length) this.filteredItems = this.filteredItems.filter(i => this.typeFilter.includes(i.type));
    // filter comments by committe
    if (this.committeIdFilter)
      this.filteredItems = this.filteredItems.filter(i => i.committee_id === this.committeIdFilter);
    // filter comments by topic
    if (this.TopicIdFilter) this.filteredItems = this.filteredItems.filter(i => i.topic.id === this.TopicIdFilter);
  }

  selectItem(item) {
    this.hideModal();
    this.selectedItem = item;
    $('#forumModal').modal('toggle');
  }

  getIcon(type: string, prefix = '') {
    // defaults to remark
    switch (type) {
      case CommentTypes.QUESTION:
        return '../../../assets/images/ico/' + prefix + 'question.svg';
      case CommentTypes.SUGGESTION:
        return '../../../assets/images/ico/' + prefix + 'suggestion.png';
      default:
        return '../../../assets/images/ico/' + prefix + 'remark.svg';
    }
  }
}
