import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MapBrowserEvent, Feature } from 'openlayers';
import { Observable } from 'rxjs';
import { MunisFacade } from 'src/app/core/store';
import Swal from 'sweetalert2';

import Backend from 'src/app/core/backend';
import { MunicipalityService } from '../../../services/municipality.service';
import { mapMunicipalities, getStates, formatMunicipalityName, defaultAnimation } from '../../../core';
import { Municipality } from 'src/app/core/models/class/municipality';
import { State } from 'src/app/core/models/class/state';

declare let $: any;

@Component({
  selector: 'app-tunisia-map',
  templateUrl: './tunisia-map.component.html',
  styleUrls: ['./tunisia-map.component.css', '../general-home.component.css'],
  ...defaultAnimation,
})
export class TunisiaMapComponent implements OnInit {
  mappedMunicipalities: Map<string, Array<any>> = null;
  states = new Set<string>();
  municipalities = new Array<Municipality>();
  munisList$: Observable<Municipality[]> = this.munisFacade.munisList$;

  // starting coords for the map
  zoom = 6.2;
  selectedState: State = {
    name: 'تونس',
    city: 'تونس',
    x: 9.5607653,
    y: 33.7931605,
    is_active: true,
    is_signed: true,
    route_name: 'tunis',
  };
  munsLogos = [];
  activeMunis: State[] = [];
  baseUrl = Backend.baseUrl;
  constructor(private utilsService: MunicipalityService, private munisFacade: MunisFacade, public route: Router) {}

  ngOnInit() {
    this.getMunicipalities();
    const map = document.querySelector('canvas');
    if (map) {
      map.style.borderRadius = '25px';
      map.style.cursor = 'pointer';
    }
  }

  getMunicipalities() {
    this.munisList$.subscribe(data => {
      if (data) {
        data.forEach(item => {
          if (item.is_signed) {
            item.is_signed = true;
            // for the map.
            if (item.latitude && item.longitude && item.longitude != 1.0) {
              this.activeMunis.push({
                name: item.name,
                city: item.city,
                x: item.longitude,
                y: item.latitude,
                is_active: item.is_active,
                is_signed: item.is_signed,
                route_name: item.route_name,
              });
            }
          }
          // for the carousel .
          if (item.logo && item.is_active) {
            this.munsLogos.push({
              name: item.name,
              nameFr: item.name_fr,
              logo: item.logo,
            });
          }
        });

        this.configureLogos();
        this.states = getStates(data);
        this.mappedMunicipalities = mapMunicipalities(data);
        this.setMuniciaplities(this.selectedState);
      }
    });
  }

  getNumMunPerState(Name) {
    return this.mappedMunicipalities.get(Name).filter(item => item.is_signed).length;
  }

  setMuniciaplities(state: State) {
    this.zoom = 6.2;
    this.municipalities = this.mappedMunicipalities.get(state.city);
    this.selectedState = state;
  }

  navigateToHomeMun(munName: string) {
    this.munisList$.subscribe(data => {
      if (data) {
        const name_fr = data.filter(item => item.name === munName)[0].route_name;
        Swal.fire({
          title: ' بلدية ' + munName,
          text: 'هل تريد الذهاب الى صفحتها ؟',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          cancelButtonText: 'لا',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'نعم',
        }).then(result => {
          if (result.value) {
            this.redirectTo(name_fr);
          }
        });
      }
    });
  }

  fireAlert(title: string) {
    Swal.fire({
      title,
      text:
        'يمكنك ملئ استمارة التحصل على اشعار ليتم إعلامك حين يتم تفعيل حساب البلدية. \n' +
        'إذا كنت من ممثلي البلدية يمكنك ملئ إستمارة تسجيل البلديات.',
      icon: 'info',
      html:
        'قم بالتسجيل الآن على المنصة و سنقوم بإعلامك عبر اشعار عند تفعيل هذه البلدية. \n' +
        '<br>' +
        '<button type="button" id="button1" role="button" tabindex="0" class="swal2-confirm swal2-styled">' +
        'انشاء حساب' +
        '</button>' +
        '<button type="button" id="button2" role="button" tabindex="0" class="swal2-confirm swal2-styled">' +
        'تسجيل الدخول' +
        '</button>',
      showCancelButton: false,
      showConfirmButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'تعمير الاستمارة',
      cancelButtonText: 'اغلاق',
    });
    $('#button1').on('click', () => {
      Swal.close();
      window.location.href = '/register';
    });
    $('#button2').on('click', () => {
      Swal.close();
      window.location.href = '/login';
    });
  }
  configureLogos() {
    const logosArray = [];
    // the length of each set in the gallery
    const setLength = 9;
    for (let i = 0; i < this.munsLogos.length; i += setLength) {
      const miniArr = [];
      for (let j = 0; j < setLength; j++) {
        if (this.munsLogos[i + j]) miniArr.push(this.munsLogos[i + j]);
      }
      logosArray.push(miniArr);
    }

    /*
    pop the last element if it isn't divisible by 3 .
    to keep a grid symmetric
    */
    if (logosArray[logosArray.length - 1].length % 3 !== 0) {
      logosArray.pop();
    }
    this.munsLogos = logosArray;
  }

  redirectTo(route_name: string) {
    window.location.href = '/' + formatMunicipalityName(route_name);
  }

  onClick(event: MapBrowserEvent) {
    event.map.forEachFeatureAtPixel(event.pixel, (feature: Feature) => {
      const featureId = feature.getId();
      const mun = this.activeMunis[featureId];
      this.setMuniciaplities(mun);
      mun.is_active
        ? this.navigateToHomeMun(mun.name)
        : this.fireAlert(
            mun.is_signed
              ? 'بلدية ' + mun.name + ' مسجلة في طور التفعيل. '
              : 'بلدية ' + mun.name + ' غير مسجلة بالمنصة',
          );
      this.zoom = 7;
    });
  }
}

/* use this to update activeCities
           this.activeMunisCode[this.getCodeFromMun(item.city)] = '#2980b9'
          */
