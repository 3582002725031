import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';

import { CommitteeService } from 'src/app/services/committee.service';
import { ComplaintsService } from 'src/app/services/complaints.service';
import { DossiersService } from 'src/app/services/dossiers.service';
import { EventsService } from 'src/app/services/events.service';
import { ForumService } from 'src/app/services/forum.service';
import { NewsService } from 'src/app/services/news.service';
import { ProceduresService } from 'src/app/services/procedures.service';
import { SubjectAccessRequestService } from 'src/app/services/subject-access-request.service';
import { OurCommonModule } from '../../core/OurCommon.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { MainComponent } from './main.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../home/home.module').then(m => m.HomeModule),
      },
      {
        path: '',
        loadChildren: () =>
          import('../administrative-features/administrative-features.module').then(m => m.AdministrativeFeaturesModule),
      },
      {
        path: '',
        loadChildren: () => import('../shared-features/shared-features.module').then(m => m.SharedFeaturesModule),
      },
    ],
  },
];

@NgModule({
  declarations: [MainComponent, HeaderComponent, FooterComponent, LoginModalComponent],
  imports: [CommonModule, FormsModule, RouterModule, OurCommonModule, RouterModule.forChild(routes)],
  bootstrap: [MainComponent],
  providers: [
    EventsService,
    NewsService,
    CommitteeService,
    ComplaintsService,
    ProceduresService,
    SubjectAccessRequestService,
    ForumService,
    ClipboardService,
    DossiersService,
  ],
  exports: [HeaderComponent, FooterComponent],
})
export class MainModule {}
