import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Choice, Poll } from '../core/models/class/polls';

import { BaseService } from '../core/services/base.service';

import { GenericCRUDService } from '../core/services/generic.service';

@Injectable({
  providedIn: 'root',
})
export class PollsService extends GenericCRUDService<Poll> {
  constructor(http: HttpClient) {
    super(http);
    this.prefix = '/polls';
    this.suffix = '/';
  }

  public voteSingle(pollId: number, choiceId: number): Observable<Choice> {
    return this.http.post(this.getUrl() + '/' + pollId + '/choices/' + choiceId + '/vote' + this.suffix, {
      headers: BaseService.getHeaders(),
    }) as Observable<Choice>;
  }
  public voteMulti(pollId: number, multiChoicesList: number[] = []): Observable<Choice> {
    const list = multiChoicesList.map(c => 'choices=' + c).join('&');
    return this.http.post(this.getUrl() + '/' + pollId + '/vote/' + '?' + list, {
      headers: BaseService.getHeaders(),
    }) as Observable<Choice>;
  }
}
