import { Pipe, PipeTransform } from '@angular/core';

import { CommentTypes, mode, UpdateTypes } from '../models/types';

@Pipe({
  name: 'historiqueStatus',
})
export class HistoriqueStatusPipe implements PipeTransform {
  transform(value: string, type: string): string {
    switch (type) {
      case mode.MODE_DOSSIER: {
        switch (value) {
          // defaults to type RECEIVED
          case UpdateTypes.NOT_CLEAR:
            return 'المطلب غير واضح';
          case UpdateTypes.INVALID:
            return 'المطلب خارج نطاق عمل البلدية';
          case UpdateTypes.PROCESSING:
            return 'المطلب بصدد المعالجة';
          case UpdateTypes.REJECTED:
            return 'تعذر على البلدية الموافقة على المطلب';
          case UpdateTypes.ACCEPTED:
            return 'تم الموافقة على المطلب';
          default:
            return 'تم  استقبال المطلب';
        }
      }
      case mode.MODE_SUBJECT_ACCESS_REQUEST: {
        switch (value) {
          // defaults to type RECEIVED
          case UpdateTypes.NOT_CLEAR:
            return 'المطلب غير واضح';
          case UpdateTypes.INVALID:
            return 'المطلب خارج مشمولات البلدية';
          case UpdateTypes.PROCESSING:
            return 'المطلب بصدد المعالجة';
          case UpdateTypes.REJECTED:
            return 'تعذر على البلدية الموافقة على المطلب';
          case UpdateTypes.ACCEPTED:
            return 'تم الموافقة على المطلب';
          default:
            return 'تم  استقبال المطلب';
        }
      }
      case CommentTypes.QUESTION: {
        switch (value) {
          // defaults to type RECEIVED
          case UpdateTypes.NOT_CLEAR:
            return 'السؤال غير واضح';
          case UpdateTypes.INVALID:
            return 'السؤال خارج نطاق عمل البلدية';
          case UpdateTypes.PROCESSING:
            return 'تم التسجيل للعرض على المجلس البلدي';
          case UpdateTypes.REJECTED:
            return 'تعذر الإجابة';
          case UpdateTypes.ACCEPTED:
            return 'تم الإجابة على السؤال';
          default:
            return 'السؤال مفتوح للإجابة';
        }
      }
      case CommentTypes.SUGGESTION: {
        switch (value) {
          // defaults to status RECEIVED
          case UpdateTypes.NOT_CLEAR:
            return 'مقترح غير واضح';
          case UpdateTypes.INVALID:
            return 'المقترح خارج نطاق عمل البلدية';
          case UpdateTypes.PROCESSING:
            return 'تم التسجيل للعرض على المجلس البلدي';
          case UpdateTypes.REJECTED:
            return 'تعذر على البلدية تبني المقترح';
          case UpdateTypes.ACCEPTED:
            return 'تم تبني المقترح';
          default:
            return 'مقترح مفتوح للنقاش';
        }
      }
      case CommentTypes.REMARK: {
        switch (value) {
          // defaults to status RECEIVED
          case UpdateTypes.NOT_CLEAR:
            return 'الملاحظة غير واضحة';
          case UpdateTypes.INVALID:
            return 'الملاحظة خارج نطاق عمل البلدية';
          case UpdateTypes.PROCESSING:
            return 'تم التسجيل للعرض على المجلس البلدي';
          case UpdateTypes.REJECTED:
            return 'تم نفي الملاحظة';
          case UpdateTypes.ACCEPTED:
            return 'تم التداول في الملاحظة';
          default:
            return 'الملاحظة مفتوحة للنقاش';
        }
      }
      // defaults to MODE_COMPLAINT
      default: {
        switch (value) {
          // defaults to status RECEIVED
          case UpdateTypes.NOT_CLEAR:
            return 'المشكل غير واضح';
          case UpdateTypes.INVALID:
            return 'المشكل خارج نطاق عمل البلدية';
          case UpdateTypes.PROCESSING:
            return 'المشكل بصدد المعالجة';
          case UpdateTypes.REJECTED:
            return 'تعذر على البلدية معالجة المشكل';
          case UpdateTypes.ACCEPTED:
            return 'تم حل المشكل';
          default:
            return 'تم  استقبال المشكل';
        }
      }
    }
  }
}
