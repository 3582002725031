import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { Association } from '../core/models/class/association';
import { BaseService } from '../core/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class AssociationsService extends BaseService {
  constructor(public http: HttpClient) {
    super();
    this.prefix = '/associations';
  }

  public get(): Observable<Association[]> {
    return (
      this.http.get(BaseService.url + this.prefix, {
        headers: BaseService.getHeaders(),
      }) as Observable<Association[]>
    ).pipe(first());
  }

  public getById(id: string | number): Observable<Association> {
    return (
      this.http.get(BaseService.url + this.prefix + '/' + id + this.suffix, {
        headers: BaseService.getHeaders(),
      }) as Observable<Association>
    ).pipe(first());
  }
}
