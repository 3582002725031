import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserFacade } from 'src/app/core/store';
import { defaultAnimation, getDeviceData, isValidPhoneNumber } from 'src/app/core';
import { BasePushNotifications } from 'src/app/core/models/class/BasePushNotifications';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
declare let $: any;

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.css'],
  ...defaultAnimation,
})
export class LoginModalComponent extends BasePushNotifications implements OnInit {
  phone_number: number;
  password = '';

  constructor(public route: Router, public _userFacade: UserFacade, protected _toastLuncher: ToastLuncherService) {
    super(_toastLuncher);
  }

  ngOnInit() {
    this.requestPermission();
  }

  login() {
    if (!this.phone_number || !this.password || !isValidPhoneNumber(this.phone_number.toString())) {
      throw new Error('بيانات الدخول غير صالحة');
    }
    const fcm_token = this.fcm_token;
    this._userFacade.startLogin({
      phone_number: this.phone_number,
      password: this.password,
      fcm_token,
      ...getDeviceData(),
    });
  }
  closeModal() {
    $('#loginModal').click();
  }
}
