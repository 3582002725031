import { IWizardStep } from 'src/app/core/models/interface/user-interface';

export const stepsData: IWizardStep[] = [
  {
    id: 1,
    text: 'ﻧﻮﻋﻴﺔ اﻟﻤﻄﻠﺐ',
    iconPath: '../../../../assets/images/dossier/new_dossier_step_1.png',
  },
  {
    id: 2,
    text: 'ﻣﻌﻠﻮﻣﺎت ﺷﺨﺼﻴﺔ',
    iconPath: '../../../../assets/images/dossier/new_dossier_step_2.png',
  },
  {
    id: 3,
    text: 'ﻣﻌﻄﻴﺎت اﻟﻤﻄﻠﺐ',
    iconPath: '../../../../assets/images/dossier/new_dossier_step_3.png',
  },
  {
    id: 4,
    text: 'اﻟﻮﺛﺎﺋﻖ اﻟﻤﻄﻮﺑﺔ',
    iconPath: '../../../../assets/images/dossier/new_dossier_step_4.png',
  },
];
