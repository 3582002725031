import { IfeatureItem } from '../interface/IfeatureItem';
import { DossierTypes, UpdateTypes } from '../types';
import { Update } from './update';

export class DossierUpdate extends Update {
  note: string;
  id: number;
  attachment: string;
  department: number;
}
export class Dossier implements IfeatureItem {
  municipality_id: number;
  id: number;
  title: string;
  name: string;
  unique_identifier: string;
  cin_number: number;
  type: DossierTypes | string;
  note: string;
  deposit_date: string;
  created_at: string;
  date: string;
  phone_number: string;
  updates: DossierUpdate[];
  status = UpdateTypes.RECEIVED;
}

export class DossiersAttachment {
  attachment: string | ArrayBuffer;
  image: string | ArrayBuffer;
  name: string;
  file_name: string;
  dossier: number;
}
export class Building {
  image: string | ArrayBuffer;
  address: string;
  longitude: number;
  latitude: number;
  permit_reference: string | ArrayBuffer;
  dossier: number;
}
