import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Dossier } from '../../../core/models/class/dossier';

declare let $: any;

@Component({
  selector: 'app-dossier-detail-home',
  templateUrl: './dossier-detail-home.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./dossier-detail-home.component.css'],
})
export class DossierDetailHomeComponent implements OnInit {
  @Input()
  dossier = new Dossier();
  constructor() {}

  ngOnInit() {}

  hideModal() {
    $('#dossierModal').modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }
}
