import { trigger, style, animate, transition } from '@angular/animations';

import { ComplaintType } from '../models/class/complaint';
import { NotificationModelName, ProcedureTypes } from '../models/types';
import { NotificationDestination } from '../models/class/notification';

const ComplaintTypeFactory = (name: string, displayName: string, subcategories: ComplaintType[] = []) => {
  return {
    name,
    displayName,
    subcategories,
  };
};

export const dossierTypes: ComplaintType[] = [
  ComplaintTypeFactory('BUILDING', 'رخصة بناء'),
  ComplaintTypeFactory('TEMPORARY_WORKS', 'رخصة في الأشغال الوقتية'),
  ComplaintTypeFactory('TAKSIM', 'رخصة المصادقة على تقسيم'),
  ComplaintTypeFactory('STREET', 'رخصة الاستغلال الوقتي للطريق العام'),
  ComplaintTypeFactory('NETWORKS', 'ربط بالشبكات العمومية'),
  ComplaintTypeFactory('SONEDE', 'ربط بشبكة المياه'),
  ComplaintTypeFactory('ONAS', 'ربط بشبكة التطهير'),
  ComplaintTypeFactory('ELECTRICITY', 'ربط بشبكة الكهرباء'),
  ComplaintTypeFactory('GAZ', 'ربط بشبكة الغاز'),
  ComplaintTypeFactory('OTHER', 'أخرى'),
];

export const complaintTypes: ComplaintType[] = [
  ComplaintTypeFactory('HEALTH_AND_HYGIENE', 'النظافة والصحة', [
    ComplaintTypeFactory('TIMING_AND_TRASH_REMOVAL', 'التوقيت و دورية رفع الفضلات'),
    ComplaintTypeFactory('BINS_LOCATION', 'مكان الحاويات'),
    ComplaintTypeFactory('BLACK_POINTS', 'نقط سوداء'),
    ComplaintTypeFactory('OTHER', 'أخرى'),
    ComplaintTypeFactory('UNCLASSIFIED', 'غير مصنف'),
  ]),
  ComplaintTypeFactory('ADMINISTRATION_AND_FINANCE', 'الشؤون الادارية والمالية'),
  ComplaintTypeFactory('BUILDING_PERMITS', 'التراخيص العمرانية'),
  ComplaintTypeFactory('ROADWORKS_AND_LIGHTING', 'أشغال الطريق والإنارة'),
  ComplaintTypeFactory('OTHER', 'أخرى'),
];

export const newsCategories: ComplaintType[] = [
  new ComplaintType('ANNOUNCEMENT', 'بلاغ'),
  new ComplaintType('ACTIVITIES_AND_EVENTS', 'أنشطة وتظاهرات'),
  new ComplaintType('WORKS_AND_MAINTENANCE', 'أشغال و صيانة'),
  new ComplaintType('CALL_FOR_TENDER', 'طلب عروض'),
  new ComplaintType('JOB_OFFERS', 'عروض شغل'),
];

export const proceduresCategories: ComplaintType[] = [
  new ComplaintType('', ProcedureTypes.ADMINISTRATIVE),
  new ComplaintType('', ProcedureTypes.CIVIL_STATUS),
  new ComplaintType('', ProcedureTypes.URBAN),
  new ComplaintType('', ProcedureTypes.REGULATORY),
  new ComplaintType('', ProcedureTypes.FINANCIAL),
  new ComplaintType('', ProcedureTypes.OTHER),
];

export const defaultAnimation = {
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0, transform: 'scale(0.95)' }),
        animate(500, style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition('* => void', [animate(0, style({ opacity: 0 }))]),
    ]),
  ],
};

enum Weekend {
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
}

export const notificationRoutes: NotificationDestination[] = [
  { model: NotificationModelName.SUBJECT_ACCESS_REQUEST, destination: '/accesinfo' },
  { model: NotificationModelName.DOSSIER, destination: '/dossiers' },
  { model: NotificationModelName.COMPLAINT, destination: '/plaintes' },
  { model: NotificationModelName.RESERVATION, destination: '/etickets/v2' },
  { model: NotificationModelName.EVENT, destination: '/calendrier' },
  { model: NotificationModelName.COMMENT, destination: '/forum' },
];
