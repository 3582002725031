import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { getItem, StorageItem } from 'src/app/core';
import Backend from 'src/app/core/backend';
import { Poll } from 'src/app/core/models/class/polls';
declare let $: any;

@Component({
  selector: 'app-poll-card',
  templateUrl: './poll-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./poll-card.component.css'],
})
export class PollCardComponent implements OnInit {
  @Input() poll: Poll;
  @Input() isSelected: boolean;
  frontUrl = Backend.frontUrl;
  munNameFr = getItem(StorageItem.municipality_route_name);
  constructor() {}

  ngOnInit(): void {}
  setCount(arg) {
    return typeof arg === 'string' ? ' غير متاح ' : arg !== 1 ? arg + ' مشاركين ' : 'مشارك واحد';
  }
  share(e) {
    $('#copyModal' + this.poll.id).modal('toggle');
    this.hideModal();
    e.stopPropagation();
  }

  hideModal() {
    $('#copyModal' + this.poll.id).modal('hide');
    $('body').removeClass('modal-open');
  }
}
