import { Component, OnInit } from '@angular/core';

import { StatsService } from '../../../services/stats.service';

declare let $: any;

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.css'],
})
export class CounterComponent implements OnInit {
  constructor(private statsService: StatsService) {}

  partners = 0;
  users = 0;
  muns = 0;
  signed = 0;
  isLoading = false;
  scrolled = false;

  ngOnInit() {
    this.getStats();
  }

  getStats() {
    this.isLoading = true;
    this.statsService.getStatistics().subscribe(data => {
      this.partners = data.associations;
      this.muns = data.active_municipalities;
      this.users = data.registered_citizens;
      this.signed = data.signed_municipalities;

      $.fn.jQuerySimpleCounter = function (options) {
        const settings = $.extend(
          {
            start: 0,
            end: 100,
            easing: 'swing',
            duration: 400,
            complete: '',
          },
          options,
        );

        const thisElement = $(this);

        $({ count: settings.start }).animate(
          { count: settings.end },
          {
            duration: settings.duration,
            easing: settings.easing,
            step: function () {
              const mathCount = Math.ceil(this.count);
              thisElement.text(mathCount);
            },
            complete: settings.complete,
          },
        );
      };
      const base = this;
      $(window).scroll(function () {
        const hT = $('#projectFacts').offset()?.top;
        const hH = $('#projectFacts').outerHeight();
        const wH = $(window).height();
        const wS = $(this).scrollTop() + 150;
        if (wS > hT + hH - wH && !base.scrolled) {
          $('#number1').jQuerySimpleCounter({ end: base.users, duration: 4000 });
          $('#number2').jQuerySimpleCounter({ end: base.muns, duration: 2500 });
          $('#number3').jQuerySimpleCounter({ end: base.signed - base.muns, duration: 3500 });
          $('#number4').jQuerySimpleCounter({ end: base.partners, duration: 2000 });
          base.scrolled = true;
        }
      });

      this.isLoading = false;
    });
  }
}
