import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { environment } from '../../../../environments/environment';
import { Messaging, getMessaging, getToken, onMessage } from 'firebase/messaging';

export class BasePushNotifications {
  messaging: Messaging = getMessaging();
  /**
   * unique for each browser
   */
  fcm_token: string | null = null;
  constructor(protected _toastLuncher: ToastLuncherService) {}

  requestPermission() {
    getToken(this.messaging, { vapidKey: environment.firebase.vapidKey })
      .then(currentToken => {
        if (currentToken) {
          this.fcm_token = currentToken;
          console.log('push notification token for your browser: ', currentToken);
        }
      })
      .catch(err => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }

  listen() {
    onMessage(this.messaging, payload => {
      if (payload?.data?.title) this._toastLuncher.success({ text: payload?.data?.title, showConfirmButton: false });
    });
  }
}
