import { Pipe, PipeTransform } from '@angular/core';

import { PollStatusTypes } from '../models/types';

@Pipe({
  name: 'voteBtnText',
})
export class VoteBtnTextPipe implements PipeTransform {
  transform(status: string, already_voted: boolean): string {
    switch (status) {
      case PollStatusTypes.IN_PROGRESS:
        return already_voted ? ' تـحـيــيـن الـتـصـويـت ' : ' تـصـويـت ';
      case PollStatusTypes.NOT_STARTED:
        return 'إطـلاع عـلـى الاخـتـيـارات';
      default:
        return 'ﻧـﺘـﺎﺋﺞ اﻟـﺘـﺼـﻮﻳـﺖ';
    }
  }
}
