import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { PwaService } from './services/pwa.service';
import { MunisFacade } from './core/store';
declare let gtag: Function;
declare let fbq: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'elBaladiya.tn';

  constructor(private _pwaService: PwaService, private router: Router, private _munisFacade: MunisFacade) {
    router.events.subscribe((y: NavigationEnd) => {
      if (y instanceof NavigationEnd) {
        gtag('config', 'UA-{ID}', { page_path: y.url });
        fbq('track', 'PageView');
      }
    });
    this._pwaService.activateUpdate();
  }
  ngOnInit() {
    this._munisFacade.loadMunis();
  }
}
