import { Component, OnInit } from '@angular/core';
import { FacebookService, InitParams } from 'ngx-facebook';

@Component({
  selector: 'app-home-page-general',
  templateUrl: './home-page-general.component.html',
  styleUrls: ['./home-page-general.component.css'],
})
export class HomePageGeneralComponent implements OnInit {
  constructor(private facebookService: FacebookService) {}

  ngOnInit() {
    this.initFacebookService();
  }
  private initFacebookService(): void {
    const initParams: InitParams = { xfbml: true, version: 'v3.2' };
    this.facebookService.init(initParams);
  }
}
