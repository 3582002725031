import { Component, OnInit } from '@angular/core';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { defaultAnimation, isValidPhoneNumber, setItem, StorageItem } from 'src/app/core';

import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  ...defaultAnimation,
})
export class ResetPasswordComponent implements OnInit {
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  currentStep: number = 1;
  phoneNumber: number;
  code: number;
  password: string;
  repeatedPassword: string;
  intervalId: any;
  seconds = 0;
  token: string = '';

  constructor(private userService: UserService, private toast: ToastLuncherService, private router: Router) {}

  ngOnInit() {}

  sendPhoneNumber() {
    if (!this.phoneNumber || !isValidPhoneNumber(this.phoneNumber.toString())) {
      throw new Error('الرجاء التثبت من أن رقم الهاتف متكون من 8 أرقام');
    }
    this.isLoading$.next(true);
    this.userService.getResetPasswordOTP(this.phoneNumber).subscribe(
      data => {
        this.currentStep++;
        this.isLoading$.next(false);
      },
      error => {
        this.isLoading$.next(false);
        if (error.status === 404) {
          throw new Error('لا يوجد حساب برقم الهاتف الذي أدخلته، الرجاء التثبت من الرقم أو إنشاء حساب جديد');
        } else if (error.status === 500) {
          throw new Error('لا يمكن الاتصال بالمنصة، الرجاء المحاولة لاحقاً');
        }
      },
    );
  }

  verifyCode() {
    this.isLoading$.next(true);
    this.userService.verifyResetPasswordOTP(this.phoneNumber, this.code).subscribe(
      data => {
        this.isLoading$.next(false);
        this.token = data.access;
        this.currentStep++;
      },
      error => {
        this.isLoading$.next(false);
        throw new Error('الرقم السري غير صالح');
      },
    );
  }

  savePassword() {
    if (!this.password || this.password.length < 6) {
      throw new Error('الرجاء التثبت من أن طول كلمة السر 6 أو أكثر');
    }

    if (this.repeatedPassword !== this.password) {
      throw new Error(' كلمتا السر غير متطابقتان');
    }

    this.isLoading$.next(true);
    this.userService.changePassword(this.password, this.token).subscribe(
      data => {
        this.isLoading$.next(false);
        this.toast.success({ text: 'تم تغيير كلمة السر بنجاح', showConfirmButton: false });
        this.router.navigate(['login']);
      },
      error => {
        this.isLoading$.next(false);
        throw new Error('لا يمكن الاتصال بالمنصة، الرجاء المحاولة لاحقاً');
      },
    );
  }

  isCurrentStep(step: number): boolean {
    return step === this.currentStep;
  }
}
