import fetch from 'node-fetch';

const { environment } = require('../../../environments/environment');

const getRessourceJson = async url => {
  try {
    const response = await fetch(url);
    const data = await response.json();
    return data as any;
  } catch (error) {
    console.error(`Error fetching JSON data: ${error}`);
  }
};
const DEFAULT_IMG = 'https://www.elbaladiya.tn/assets/images/login/fb-share.jpg';

/**
 * THIS IS USED TO DETERMINE PAGE NAME on each feature
 * last item should always have default attributes
 */
export const features: Ifeature[] = [
  {
    route: 'profile',
    documentName: 'إعدادات الحساب',
    longDescription:
      'يتضمّن مجموعة البيانات الخاصّة بصاحب الحساب (العنوان، المهنة أو الصّفة، رقم بطاقة التعريف الوطنيّة، البريد الإلكتروني .. ) والضّروريّة لخصخصة البيانات وتحديد هوية المستخدمين للتطبيقة خاصة أثناء التفاعل في فضاء المقترحات.',
  },
  {
    route: 'associations',
    documentName: 'الجمعيات',
    longDescription:
      'يتضمّن قائمة بمجموعة الجمعيات الناشطة والتابعة للمنطقة البلدية مع تقديم عامّ لكلّ جمعيّة، مجال نشاطها وأهمّ إنجازاتها. مستقبلا سيتيح هذا الفضاء للجمعيات المسجلة المساهمة بمحتوى تفاعلي في الإطار الأنشطة و المشاريع الموجهة لسكان بلدية معينة.',
  },
  {
    route: 'calendrier',
    documentName: 'الرزنامة الموحدة',
    longDescription: 'تصفح مواعيد أهم الأنشطة والاجتماعات القادمة للبلدية و شارك فيها',
    hasRessourceById: true,
    getRessourceMetadata: async (municipality_id: number, id: number) => {
      const data = await getRessourceJson(environment.apiUrl + `/municipalities/${municipality_id}/events/${id}`);
      return {
        title: data.title,
        description: data.description,
        image: data?.image ? environment.apiBaseUrl + data.image : DEFAULT_IMG,
        type: 'article',
      };
    },
  },
  {
    route: 'dossiers',
    documentName: 'متابعة ملفات الرخص',
    longDescription:
      'تمكنك هذه الخدمة في كلّ مرّة من إضافة ملفّاتك الخاصّة من مطالب رخص مودعة لدى البلديّة من خلال إدخال المعرّف الوحيد ورقم بطاقة التعريف الوطنيّة،  بهدف تتبّع مدى تقدّم الإجراءات بالنسبة لكل مطلب.  يتمّ إعلام المواطن بكلّ خلل أو نقص يمكن أن يكون موجودا  عبر سجل التغييرات الخاص بكل مطلب.',
  },
  {
    route: ['plaintes', 'nouveau-plainte'],
    documentName: 'التشكيات',
    longDescription:
      'مساحة تفاعليّة تمكن المواطن من التبليغ عن المشاكل اليومية من خلال توثيق الإشكالية بتعليق موجز، بصورة مرفقة، بتحديد المكان وباقتراح الحلول، مع ضرورة تحديد نوع المشكل كلّ مرّة .تتفرّع هذه المساحة إلى جزء للمشاكل الخاصة لصاحب الحساب وجزء آخر لكلّ تشكيات مستعملي التطبيقية وهنا تُتاح للمواطنين الفرصة للتفاعل ودعم أهم المشاكل. ',
    hasRessourceById: true,
    getRessourceMetadata: async (municipality_id: number, id: number) => {
      const data = await getRessourceJson(environment.apiUrl + `/municipalities/${municipality_id}/complaints/${id}`);
      return {
        title: data?.sub_category || data?.category || data?.address || 'التشكيات',
        description: data.problem,
        image: data?.image ? environment.apiBaseUrl + data.image : DEFAULT_IMG,
        type: 'article',
      };
    },
  },
  {
    route: ['accessinfo', 'nouveau-accesinfo'],
    documentName: 'مطالب النفاذ إلى المعلومة',
    longDescription:
      'خاصيّة تكرّس مبدأ الشفافيّة وتضمن حق المواطنين في الإطلاع على الوثائق الخاصة بالهياكل العمومية للدولة حسب القانون الأساسي عــ22ــدد لسنة 2016 المؤرّخ في 24 مارس 2016. للتقدّم بمطلب جديد لا بدّ من ذكر الوثيقة المطلوب النفاذ إليها، تحديد الهيكل الإداري المعني، ذكر المرجع (في حالة توفّره) مع ضرورة اختيار  الطريقة المرجوّة للحصول على الوثيقة الإدارية.',
    hasRessourceById: true,
    getRessourceMetadata: async (municipality_id: number, id: number) => {
      const data = await getRessourceJson(
        environment.apiUrl + `/municipalities/${municipality_id}/subject-access-requests/${id}`,
      );
      return {
        title: data.document,
        description: data.structure,
        type: 'article',
      };
    },
  },
  {
    route: 'procedures',
    documentName: 'دليل الخدمات',
    longDescription:
      'يتضمن مجموعة الخدمات التي تربط المواطن بالبلديّة مرفقة ببعض الملاحظات والتوضيحات مثل المراجع التشريعية والترتيبية وشروط الإنتفاع بالخدمة مع تعديد الوثائق المطلوبة لكل خدمة بغية تسهيل العملية للمواطن والعون المسؤول وربحا للوقت .',
    hasRessourceById: true,
    getRessourceMetadata: async (municipality_id: number, id: number) => {
      const data = await getRessourceJson(environment.apiUrl + `/municipalities/${municipality_id}/procedures/${id}`);
      return {
        title: data.title,
        description: data.body,
        type: 'article',
      };
    },
  },
  {
    route: 'polls',
    documentName: 'ﺳﺒﺮ اﻟﺂرﺍء',
    longDescription:
      'فضاء تشاركي يعزز مساهمة المواطن في تسيير شؤون منطقته باستشارته وإبداء رأيه في بعض المسائل المطروحة من قبل البلدية. تتم العمليّة من خلال اختيار المواطن للاستطلاع وإدخال اختياره الذي ستؤخذ نتائجه في الاعتبار عند حسم القرارات على مستوى البلدية. وتبقى للمواطن الفرصة دائما لمتابعة نتائج استطلاعات الرأي وكيفيّة تجاوب المتساكنين معها. ',
    hasRessourceById: true,
    getRessourceMetadata: async (municipality_id: number, id: number) => {
      const data = await getRessourceJson(environment.apiUrl + `/municipalities/${municipality_id}/polls/${id}`);
      return {
        title: data.title,
        description: data.text,
        image: data?.picture ? environment.apiBaseUrl + data.picture : DEFAULT_IMG,
        type: 'article',
      };
    },
  },
  {
    route: ['forum', 'nouveau-post'],
    documentName: 'فضاء الآراء و التساؤلات',
    longDescription:
      'مساحة تفاعلية بين المواطنين فيما بينهم وأعضاء البلدية، تسمح لهم بطرح مواضيع وأفكار ومطالب يتم التفاعل معها من خلال الدّعم أو الإعراض وكذلك من خلال التعليق أسفل المنشور. يكون كل منشورمدرجا ضمن اللجنة المناسبة ومعرَّفا باسم صاحبه مرفقا بصورته لإضفاء الشفافية والمصداقيّة في التفاعلات. يمكن تمييز أعضاء المجلس البلدي, الإداريين و مشرفي التطبيقة  من خلال لون الدائرة المحيط بالصور الشخصيّة والبيانات المرفقة بها عند الضغط عليها.',
    hasRessourceById: true,
    getRessourceMetadata: async (municipality_id: number, id: number) => {
      const data = await getRessourceJson(
        environment.apiUrl + `/municipalities/${municipality_id}/forum/comments/${id}`,
      );
      return {
        title: data.title,
        description: data.body,
        image: data?.image ? environment.apiBaseUrl + data.image : DEFAULT_IMG,
        type: 'article',
      };
    },
  },
  {
    route: 'news',
    documentName: 'المستجدات البلدية',
    longDescription:
      'يمكّن هذا الفضاء البلديّة من تشريك المواطن  وإطلاعه على أهم أخبارها و نشاط لجانها. يكون ذلك من خلال نصوص وصور ومقالات وفيديوهات مع فسح المجال للتفاعل عبر عمليات الإعجاب.',
    hasRessourceById: true,
    getRessourceMetadata: async (municipality_id: number, id: number) => {
      const data = await getRessourceJson(environment.apiUrl + `/municipalities/${municipality_id}/news/${id}`);
      return {
        title: data.title,
        description: data.body,
        image: data?.images?.length > 0 ? environment.apiBaseUrl + data.images[0] : DEFAULT_IMG,
        type: 'article',
      };
    },
  },
  {
    route: 'reports',
    documentName: 'الاخبار',
    longDescription: 'خليك متابع نشاط أعضاء المجلس البلدي و تصفح آخر تقاريرهم',
    hasRessourceById: true,
    getRessourceMetadata: async (municipality_id: number, id: number) => {
      const data = await getRessourceJson(environment.apiUrl + `/municipalities/${municipality_id}/reports/${id}`);
      return {
        title: data.title,
        description: data.date,
        type: 'article',
      };
    },
  },
  {
    route: 'committees',
    documentName: 'المجلس البلدي ولجانه',
    longDescription:
      'قائمة تتضمّن المجلس البلدي ومختلف اللّجان البلديّة مع تقديم عامّ لكل لجنة، وتقارير جلساتها القابلة للتحميل والحفظ.',
    hasRessourceById: true,
    getRessourceMetadata: async (municipality_id: number, id: number) => {
      const data = await getRessourceJson(environment.apiUrl + `/municipalities/${municipality_id}/committees/${id}`);
      return {
        title: data.title,
        description: data.body,
        type: 'article',
      };
    },
  },
  {
    route: ['etickets', 'etickets/v2'],
    documentName: 'الـتـذكـرة الالـكـتـرونـيـة',
    longDescription: 'احـجـز تـذكـرة إلـكـتـرونــية أو حمــــل تـذكـرة مـاديـة وتـتـبـعـها بـمـرور الـوقـت',
  },
  {
    // LAST ONE is the default
    route: '',
    documentName: 'elBaladiya.tn',
    longDescription: 'تمتع بالخدمات الرقمية لبلديتك على منصة elBaladyia.tn',
    image: DEFAULT_IMG,
  },
];

export interface Ifeature {
  route: string | string[];
  documentName: string;
  longDescription: string;

  image?: string;
  hasRessourceById?: boolean;
  getRessourceMetadata?: (municipality_id: number, id: number) => {};
}
