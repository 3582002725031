import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { defaultAnimation } from '../../lib/const';
import { UiFacade } from '../../store';

@Component({
  selector: 'app-empty-list',
  templateUrl: './empty-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./empty-list.component.css'],
  ...defaultAnimation,
})
export class EmptyListComponent implements OnInit {
  @Input() text: string;
  @Input() src: string = '../../../assets/images/empty-folder.png';

  constructor(public _uiFacade: UiFacade) {}

  ngOnInit(): void {}
}
