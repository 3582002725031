import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arabicTimeAgo',
})
export class ArabicTimeAgoPipe implements PipeTransform {
  transform(date: string): string {
    const seconds = (Date.now() - Date.parse(date)) / 1000;

    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30));
    const years = Math.round(Math.abs(days / 365));
    if (seconds <= 45) {
      return 'الآن';
    } else if (seconds <= 90) {
      return ' منذ دقيقة واحدة';
    } else if (seconds <= 150) {
      return ' منذ دقيقتين';
    } else if (minutes <= 45) {
      return 'منذ ' + minutes + (minutes <= 10 ? ' دقائق' : ' دقيقة');
    } else if (minutes <= 90) {
      return 'منذ ساعة';
    } else if (minutes <= 150) {
      return 'منذ ساعتان';
    } else if (hours <= 22) {
      return 'منذ ' + hours + ' ساعة';
    } else if (hours <= 36) {
      return 'منذ يوم';
    } else if (hours <= 60) {
      return 'منذ يومين';
    } else if (days <= 25) {
      return 'منذ ' + days + (days <= 10 ? ' أيام' : ' يوم');
    } else if (days <= 45) {
      return 'منذ شهر';
    } else if (days <= 75) {
      return 'منذ شهرين';
    } else if (days <= 345) {
      return 'منذ ' + months + (months <= 10 ? ' أشهر' : ' شهر');
    } else if (days <= 545) {
      return 'منذ سنة';
    } else {
      return 'منذ ' + years + ' سنوات';
    }
  }
}
