import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    /**
     *     if (this.muni.partner_associations !== null) {
          this.partners_string = 'بالتعاون مع: ';
          for (let i = 0; i < this.muni.partner_associations.length; i++) {
            this.partners_string += this.muni.partner_associations[i].full_name_arabic + '،';
          }
          this.partners_string = this.partners_string.substr(0, this.partners_string.length - 1);
        }
     */
  }
}
