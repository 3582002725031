import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Municipality, MunicipalitySummary } from '../core/models/class/municipality';

import { BaseService } from '../core/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class MunicipalityService extends BaseService {
  constructor(public http: HttpClient) {
    super();
  }

  public getMunicipalities(activeOnly = false, showDetails = false): Observable<Municipality[]> {
    let params = new HttpParams();
    if (showDetails) {
      params = params.append('show_details', 'True');
    }
    if (activeOnly) {
      params = params.append('is_active', 'True');
    }
    return this.http.get(BaseService.url + '/municipalities', { params }) as Observable<Municipality[]>;
  }

  followMunicipality(id: string, isFollowed: boolean): Observable<any> {
    return this.http.post(
      BaseService.url + '/municipalities/' + id + (isFollowed ? '/unfollow' : '/follow'),
      {},
      { headers: BaseService.getHeaders() },
    ) as Observable<any>;
  }

  getById(id: string): Observable<Municipality> {
    return this.http.get(BaseService.url + '/municipalities/' + id, {
      headers: BaseService.getHeaders(),
    }) as Observable<Municipality>;
  }

  getMetaData(route_name: string): Observable<MunicipalitySummary> {
    return this.http.get(BaseService.url + `/municipalities/meta/${route_name}`, {
      headers: BaseService.getHeaders(),
    }) as Observable<Municipality>;
  }
}
